import React, { Component } from "react";
import { styles } from "src/styles";

export class LandingPage extends Component {
  render() {
    return (
      <div className="row">
        <div style={styles.itemAlign}>
          <div style={{ marginTop: 120, width: 480, marginBottom: 100 }}>
            <div className="card-bg">
              <p style={styles.topParagraph}>
                Success! Please check your inbox to continue the email change
                process.
              </p>
              <p style={styles.bottomParagraph}>
                Didn’t receive the email? Please check your spam folder.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
