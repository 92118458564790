import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";

interface IScreenProps {
  id: string;
  loading: boolean;
  appState: any;
  userState: any;
  children?: any;
  style?: any;
  ignoreLogin?: boolean;
  history?: any;
}

interface IScreenState {
  redirectPath: string;
  shouldRedirectToLogin: boolean;
}

class Screen extends React.Component<IScreenProps, IScreenState> {
  constructor(props: any){
    super(props);
    this.state = {
      redirectPath: "",
      shouldRedirectToLogin: false,
    };
  }

  async componentDidMount(){
    if(!this.props.ignoreLogin && !this.props.userState.loggedIn){
      const path = window.location.pathname;
      const redirectPath = encodeURIComponent(path);
      this.setState({
        redirectPath,
        shouldRedirectToLogin: true,
      });
    }
  }

  public render(){
    if(this.state.shouldRedirectToLogin){
      return <Redirect to={`/login?path=${this.state.redirectPath}`} />
    }
    return (
        <section style={this.props.style !== null ? this.props.style: {}}>
          {this.props.loading ? 
            (<div style={{textAlign: "center"}}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>) : this.props.children
          }
        </section>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Screen);