import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";

const ScopeCard = (props: any) => {
  return (
    <ListGroup style={{marginBottom: 0}}>
      <ListGroupItem>
        <strong>{props.title}</strong>
        <p>{props.description}</p>
      </ListGroupItem>
    </ListGroup>
  );
}

export default ScopeCard;