import { makeCall } from "./client";

export class DevicesAPI {
  public getDevices(userId: number, otherData: any = {}) {
    const data = {
      ...otherData,
    };
    return makeCall("get", `users/${userId}/devices`, data);
  }

  public verifyDevice(verificationData: any, otherData: any = {}) {
    const data = {
      ...verificationData,
      ...otherData,
    };
    return makeCall("post", `devices/verify`, data);
  }

  public getDevicesMetadata() {
    return makeCall("get", `devices`);
  }

  public getPlans(productFamily: number, otherData: any = {}) {
    const data = {
      ...otherData,
      productFamily
    };
    return makeCall("get", `plans`, data);
  }

  public getPlan(planId: number, otherData: any = {}) {
    const data = {
      ...otherData,
    };
    return makeCall("get", `plans/${planId}`, data);
  }

  public activateDevice(userId: number, productId: string, planId: number, nickname?: string, otherData: any = {}) {
    const data = {
      ...otherData,
      productId,
      planId,
      nickname,
    };
    return makeCall("post", `users/${userId}/devices/preregister`, data);
  }
}