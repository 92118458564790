export const styles: any = {
  topParagraph: {
    fontSize: 32,
    color: "#545454",
    fontFamily: "Sans-serif",
    textAlign: "center",
  },
  bottomParagraph: {
    marginTop: 15,
    fontSize: 20,
    color: "#545454",
    fontFamily: "Sans-serif",
    textAlign: "center",
  },
  bottomParagraphWarning: {
    marginTop: 10,
    fontSize: 15,
    color: "#000000",
    fontFamily: "Sans-serif",
    textAlign: "left",
  },
  topParagraphBox: {
    backgroundColor: "#545454",
    opacity: 0.7,
    width: 360,
    borderRadius: 10,
  },
  inputHeader: {
    fontSize: 16,
    fontFamily: "Sans-serif",
    color: "#545454",
  },
  forgot: {
    fontSize: 15,
    fontFamily: "SF Pro Text",
    color: "#009BDE",
    fontWeight: 600,
    marginTop: 10,
    marginBottom: -10,
    letterSpacing: "-0.24px",
    lineHeight: "20px",
  },
  hideShow: {
    fontSize: "14px",
    fontFamily: "SF Pro Text",
    fontWeight: 500,
    color: "#000000",
    marginLeft: 12,
    textAlign: "center",
  },
  marketingSub: {
    fontSize: 18,
    fontFamily: "Sans-serif",
    color: "#545454",
    marginLeft: 2,
    textAlign: "center",
  },
  setupGuide: {
    marginTop: 15,
    fontSize: 14,
    fontFamily: "Sans-serif",
    color: "#545454",
    textAlign: "center",
  },
  setupGuideHeader: {
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Sans-serif",
    color: "#545454",
    marginLeft: 15,
    marginTop: 20,
  },
  setupGuideUnderline: {
    marginTop: 15,
    fontSize: 14,
    fontFamily: "Sans-serif",
    color: "#545454",
    textAlign: "center",
    textDecoration: "underline",
  },
  guideLink: {
    fontSize: 16,
    fontFamily: "Sans-serif",
    color: "#545454",
  },
  guideLinkOnCard: {
    fontSize: 14,
    fontFamily: "Sans-serif",
    color: "#545454",
    marginBottom: 15
  },
  setupGuideUnderlineOnCard: {
    marginTop: 15,
    fontSize: 14,
    fontFamily: "Sans-serif",
    color: "blue",
    textAlign: "center",
    textDecoration: "underline",
  },
  sameAddress: {
    fontSize: 16,
    fontFamily: "Sans-serif",
    color: "#000000",
  },
  sameAddressNew: {
    fontSize: 14,
    fontFamily: "Sans-serif",
    color: "#000000",
  },
  emailComponent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  },
  inputField: {
    width: "100%",
    height: 55,
    borderRadius: 44,
    opacity: 0.7,
  },
  inputFieldNew: {
    width: "100%",
    height: 55,
    background: "#FFFFFF",
    border: "1.10907px solid #BBBBBB",
    boxSizing: "border-box",
    borderRadius: "6.65439px",
  },
  inputFieldCoupon: {
    height: 48,
    background: "#FFFFFF",
    border: "1.10907px solid #BBBBBB",
    boxSizing: "border-box",
    borderRadius: "6.65439px",
  },
  inputFieldDisabled: {
    width: "100%",
    height: 55,
    background: "##F2F2F2",
    border: "1.10907px solid #BBBBBB",
    boxSizing: "border-box",
    borderRadius: "6.65439px",
  },
  profileDiv: {
    height: 120,
    width: 120,
    paddingLeft: 20,
    marginBottom: 20,
  },
  inputFieldAdj: {
    width: "100%",
    height: 55,
    borderRadius: 44,
    backgroundColor: "#ECE7E6",
  },
  button: {
    width: "100%",
    height: 55,
    borderRadius: 44,
    borderColor: "transparent",
  },
  buttonNew: {
    background: "#009BDE",
    borderRadius: "4.43626px",
    width: "100%",
    height: 55,
    borderColor: "transparent",
    fontFamily: "SF Pro Text",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16.636px",
    lineHeight: "22px",
    /* or 133% */
    
    textAlign: "center",
    
    /* 07 On Error */
    
    color: "#FFFFFF",
  },
  buttonNewSecondary: {
    background: "#FFFFFF",
    borderRadius: "4.43626px",
    width: "100%",
    height: 55,
    borderColor: "#0e9ed7",
    fontFamily: "SF Pro Text",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16.636px",
    lineHeight: "22px",
    /* or 133% */
    
    textAlign: "center",
    
    /* 07 On Error */
    
    color: "#0e9ed7",
  },
  buttonNewSecondaryRow: {
    background: "#FFFFFF",
    borderRadius: "4.43626px",
    height: 55,
    borderColor: "#0e9ed7",
    fontFamily: "SF Pro Text",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16.636px",
    lineHeight: "22px",
    /* or 133% */
    
    textAlign: "center",
    
    /* 07 On Error */
    
    color: "#0e9ed7",
  },
  buttonNewCoupon: {
    background: "#009BDE",
    borderRadius: "4.43626px",
    height: 48,
    borderColor: "transparent",
    fontFamily: "SF Pro Text",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16.636px",
    lineHeight: "22px",
    /* or 133% */
    
    textAlign: "center",
    
    /* 07 On Error */
    
    color: "#FFFFFF",
  },
  newToWagz: {
    fontFamily: 'SF Pro Display',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    /* identical to box height, or 175% */

    letterSpacing: "0.16px",

    color: "#000000",
  },
  // setupCard: {
  //   marginTop: 
  // },
  editButton: {
    width: "40%",
    height: 55,
    borderRadius: 44,
    borderColor: "transparent",
    marginTop: 10,
    marginLeft: 40,
  },
  centerText: {
    textAlign: "center",
  },
  itemAlign: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkAlign: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 4
  },
  container: {
    width: "100%",
    height: "80%",
    position: "fixed",
    left: 0,
  },
  deviceRow: {
    backgroundColor: "white",
    borderRadius: 12,
    border: "1px solid #cfd1d2",
    margin: 15,
    opacity: 1,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    boxShadow: "0px 2px 4px 4px #cfd1d2",
  },
  credit: {
    backgroundColor: "#67657E",
    height: 220,
    border: "1px solid #cfd1d2",
    borderRadius: 12,
  },
  cardNumber: {
    fontFamily: "Sans-serif",
    color: "#ffffff",
    marginLeft: 20,
    fontSize: 14,
    marginTop: 30,
  },
  cardName: {
    fontFamily: "Sans-serif",
    color: "#ffffff",
    fontSize: 16,
    marginLeft: 60,
    marginTop: -20,
  },
  cardEx: {
    fontFamily: "Sans-serif",
    color: "#ffffff",
    fontSize: 16,
    marginLeft: 120,
    marginTop: -20,
  },
  cardBrand: {
    fontFamily: "Sans-serif",
    color: "#ffffff",
    fontSize: 42,
    marginLeft: 60,
    marginTop: 30,
  },
  topRow: {
    display: "flex",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 18,
    fontFamily: "Sans-serif",
    borderBottom: "3px solid rgba(1,40,102,.5)",
  },
  bottomRow: {
    display: "flex",
    paddingBottom: 5,
    paddingTop: 5,
    fontSize: 14,
    fontFamily: "Sans-serif",
  },
  valid: {
    fontSize: 10,
    textAlign: "right",
    marginRight: 78,
    paddingBottom: 5,
  },
  staticReview: {
    color: "#0e9ed7",
    marginLeft: 20,
    marginRight: 5,
  },
  staticReviewNew: {
    color: "#666666",
    fontFamily: "SF Pro Text",
    marginLeft: 20,
    marginRight: 5,
  },
  staticReviewResponse: {
    color: "#333333",
    fontFamily: "SF Pro Text",
    marginLeft: 5,
    marginRight: 5,
  },
  goBack: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Sans-serif",
    color: "#0e9ed7",
    marginTop: 10,
    textDecoration: "underline",
  },
  bottomBorder: {
    textAlign: "center",
    borderBottom: "1px solid #dee2e6",
  },
  borderBottom: {
    textAlign: "center",
    borderBottom: "1px solid #CCCCCC",
    marginBottom: 10,
  },
  mobileBackground: {
    backgroundColor: "white",
    width: "10000px",
    height: "10000px",
  },
  completedRegistration: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
  },
  completedRegistrationSubheader: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "22px",
  },
  signupHeader: {
    fontFamily: 'SF Pro Display',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "28px",
    marginLeft: 7,
    /* identical to box height, or 127% */

    letterSpacing: "0.16px",

    color: "#000000",
  }
};
