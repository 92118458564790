import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table } from "react-bootstrap";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";

import { formatCurrency } from "../../../utils/currency";
import { BillingAPI } from "../../../API/index";

interface ITransactionsProps {
  loading: boolean;
  user: any;
}

interface ITransactionsState {
  loading: boolean;
  transactions: any;
}

export class Transactions extends Component<
  ITransactionsProps,
  ITransactionsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      transactions: [],
    };

    this.updateField = this.updateField.bind(this);
    this.fetchTransactions = this.fetchTransactions.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.fetchTransactions();
  }

  fetchTransactions() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await BillingAPI.getUserTransactions(
          this.props.user,
          {}
        );
        this.setState({
          transactions: result.body.data,
          loading: false,
        });
      } catch (err) {
        this.setState({
          transactions: [],
        });
      }
    });
  }

  render() {
    return (
      <div className="col-sm-12" style={{ marginTop: 25 }}>
        {this.state.transactions.length !== 0 ? (
          <div>
            <h6 className="header-6" style={{ marginTop: 2, marginBottom: 8 }}>
              Recent Subscription Transactions
            </h6>
            <Table striped bordered hover>
              <thead style={{ textAlign: "center" }}>
                <th>Date</th>
                <th>Amount</th>
                <th>Product</th>
              </thead>
              <tbody>
                {this.state.transactions.map((e: any) => {
                  return (
                    <tr>
                      <td>{e.created.substring(0, 10)}</td>
                      <td>{formatCurrency(e.amount)}</td>
                      <td>{e.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
