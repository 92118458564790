import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import warning_Gold_24dp from "src/img/warning_Gold_24dp.svg";
import { DevicesAPI, UserAPI } from "src/API/index";
import { handlePhone } from "src/utils/utilities";

import Stepper from "src/components/structure/Stepper";
import { styles } from "src/styles";

interface IReviewStepNoBillingProps {
  userActions: any;
  userState: any;
  review: any;
  user: any;
  device: any;
}

interface IReviewStepNoBillingState {
  loading: boolean;
  planDisplay: string;
  amount: number;
  buttonActive: boolean;
}

export class ReviewStepNoBilling extends Component<IReviewStepNoBillingProps, IReviewStepNoBillingState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      planDisplay: "",
      amount: 0.0,
      buttonActive: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckboxToggle = this.handleCheckboxToggle.bind(this);
  }

  handleCheckboxToggle() {
    this.setState({
      buttonActive: !this.state.buttonActive,
    });
  }

  handleSubmit() {
    this.setState({ loading: true }, async () => {
      try {
        await DevicesAPI.activateDevice(
          this.props.userState.user.id,
          this.props.device.productId.toUpperCase(),
          this.props.device.selectedPlanId,
          this.props.device.nickname
        );
        await this.props.review(true);
        this.setState({ loading: false });
      } catch (err) {
        const errorString = err ? err : "unknown";
        const event = {
          domain: 'user_setup_error',
          additionalData: {
              "error": "Error activating device",
              "type": "device",
              "more": {
                  "message": errorString,
              }
          }
        };
        await UserAPI.logEvent(
          this.props.userState.user.id,
          event
        );
        await this.props.review(false);
        this.setState({ loading: false });
      }
    });
  }

  goBack() {
    window.location.reload();
  }

  render() {
    return (
      <div className="row" style={{ marginBottom: 100 }}>
        <div style={styles.itemAlign}>
          <div style={{ marginTop: 20, width: 380 }}>
            <p style={styles.signupHeader}>Review & confirm</p>
            <div className="card-warning">
              <div className="row">
                <div
                  style={{
                    width: "15%",
                    textAlign: "center",
                    marginTop: 15,
                  }}
                >
                  <img src={warning_Gold_24dp} alt="..." />
                </div>
                <div style={{ width: "80%" }}>
                  <p style={styles.bottomParagraphWarning}>
                    Please do not click the Back button on your browser or
                    exit this process prior to completing setup.
                  </p>
                </div>
              </div>
            </div>
              <div className="card-new">
            <Stepper currentStep={5} />
            <div>
              <header
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
              >
              <div style={{ marginTop: 2, marginBottom: 12, justifyContent: "center" }}>
                <div className="row" style={{ marginBottom: 16, flexWrap: "nowrap" }}>
                <div style={{ marginTop: 12 }}>
                <input
                  id="buttonActive"
                  type="checkbox"
                  aria-label="Hide or Show Password"
                  onChange={this.handleCheckboxToggle}
                />
                </div>
                <div>
                  <span style={styles.bottomParagraphWarning}>I confirm that the information below is accurate.</span>
                </div>
                </div>
                <div style={{ marginBottom: 16 }}>
                <button
                  style={styles.buttonNew}
                  className="btn btn-block btn-primary"
                  onClick={this.handleSubmit}
                  disabled={!this.state.buttonActive}
                >
                  COMPLETE REGISTRATION
                </button>
                </div>
              </div>
              </header>
              <p style={{ fontWeight: "bold", fontSize: 16 }}>1. Profile</p>
              <div>
                <p style={{ fontWeight: "bold", fontSize: 14 }}>Personal Info</p>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>First Name </span>
                  <p style={styles.staticReviewResponse}>{this.props.user.firstName}</p>
                </div>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>Last Name </span>
                  <p style={styles.staticReviewResponse}> {this.props.user.lastName}</p>
                </div>

                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>Email </span>{" "}
                  <p style={styles.staticReviewResponse}>{this.props.user.email}</p>
                </div>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>Phone Number</span>
                  <p style={styles.staticReviewResponse}>{handlePhone(this.props.user.phoneNumber)}</p>
                </div>
                <p style={{ fontWeight: "bold", fontSize: 14 }}>Home address</p>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>Street address </span>{" "}
                  <p style={styles.staticReviewResponse}>{this.props.user.addressLine1}{" "}
                    {this.props.user.addressLine2}</p>
                </div>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>City </span>{" "}
                  <p style={styles.staticReviewResponse}>{this.props.user.addressCity}</p>
                </div>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>State </span>{" "}
                  <p style={styles.staticReviewResponse}>{this.props.user.addressState}</p>
                </div>
                <div className="row" style={{ flex: 1 }}>
                  <span style={styles.staticReviewNew}>Zip </span>{" "}
                  <p style={styles.staticReviewResponse}>{this.props.user.addressZip}</p>
                </div>
              </div>
              <p style={{ fontWeight: "bold", fontSize: 16 }}>2. Product Information</p>
              <div className="row" style={{ flex: 1 }}>
                <span style={styles.staticReviewNew}>Serial Number</span>
                <p style={styles.staticReviewResponse}> {this.props.device.productId}</p>
              </div>
              <div className="row" style={{ flex: 1 }}>
                <span style={styles.staticReviewNew}>Nickname</span>
                <p style={styles.staticReviewResponse}> {this.props.device.nickname}</p>
              </div>
              <div style={styles.centerText}>
                <span onClick={this.goBack}>
                  <label style={styles.goBack}>CHANGE</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStepNoBilling);
