import React from "react";
import { Link } from "react-router-dom";

const Mailto = (
    { label, mailto }: { label: string, mailto: string }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

export default Mailto;