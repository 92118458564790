import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";

// import Card from "../../structure/Card";
import PaymentCard from "react-payment-card-component";

interface IHavePaymentProps {
  loading: boolean;
  userActions: any;
  brand: string;
  last4: string;
  expMonth: string;
  expYear: string;
  firstName: string;
  lastName: string;
}

interface IHavePaymentState {
  loading: boolean;
}

export class CurrentPaymentMethod extends Component<
  IHavePaymentProps,
  IHavePaymentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.getMethodDisplay = this.getMethodDisplay.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  getMethodDisplay() {
    const fullName = `${this.props.firstName} ${this.props.lastName}`;
    const fullNumber = `************${this.props.last4}`;
    if (this.props.brand) {
      return (
        <div
        className="col-sm-12"
        style={{
          display: "flex",
          width: "100%",
          marginLeft: -20
        }}
      >
          <PaymentCard
            className="col-sm-12"
            type="black"
            model="normal"
            bank={this.props.brand}
            brand={this.props.brand.toLowerCase()}
            number={fullNumber}
            cvv="202"
            holderName={fullName}
            expiration={this.props.expMonth + "/" + this.props.expYear}
            flipped={false}
          />
        </div>
      );
    } else {
      return (
        <strong className="danger">
          You do not have a payment method on file!
        </strong>
      );
    }
  }

  render() {
    return (
      <div className="col-sm-12">
        <div className="card-new-no-border">
          {this.getMethodDisplay()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentPaymentMethod);
