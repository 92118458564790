import React from "react";
import StepperDot from "src/components/structure/comp/StepperDot"
import StepperDash from "src/components/structure/comp/StepperDash"

const Stepper = (
  { currentStep }: { currentStep: number }
) => {
  return (
    <div className="row" style={{ width: "100%", height: 40, justifyContent: "center", marginBottom: 30, flexWrap: 'nowrap', marginLeft: 4 }}>
      <StepperDot step={1} currentStep={currentStep} />
      <StepperDash />
      <StepperDot step={2} currentStep={currentStep} />
      <StepperDash />
      <StepperDot step={3} currentStep={currentStep} />
      <StepperDash />
      <StepperDot step={4} currentStep={currentStep} />
      <StepperDash />
      <StepperDot step={5} currentStep={currentStep} />
    </div>
  );
};

export default Stepper;
