import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";

import CurrentPaymentMethod from "./CurrentPaymentMethod";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import TransactionHistory from "./TransactionHistory";
import { BillingAPI } from "../../../API/index";
import { verifySetup, jwtCheck } from "../../../utils/utilities";
import { Row } from "react-bootstrap";

interface BillingScreenProps {
  history: any;
  location: any;
  paymentUpdated: any;
  userActions: any;
  userState: any;
}

interface BillingScreenState {
  billing: string;
  loading: boolean;
  brand: string;
  last4: string;
  expMonth: string;
  expYear: string;
  firstName: string;
  lastName: string;
  coupons: any;
  stripeCustomerToken: string;
  setupCompleted: boolean | undefined;
}

export class Billing extends Component<BillingScreenProps, BillingScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      billing: "",
      loading: false,
      brand: "",
      last4: "",
      expMonth: "",
      expYear: "",
      firstName: "",
      lastName: "",
      coupons: [],
      stripeCustomerToken: "",
      setupCompleted: true,
    };

    this.updateField = this.updateField.bind(this);
    this.navigateAway = this.navigateAway.bind(this);
    this.fetchPaymentMethod = this.fetchPaymentMethod.bind(this);
  }

  componentDidMount() {
    // TODO: this should be in the setState to avoid racing
    this.fetchPaymentMethod();
    this.setState({ loading: true }, async () => {
      try {
        const check = await verifySetup(this.props.userState.user.id);
        this.setState({ setupCompleted: check, loading: false });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    });
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  navigateAway(shouldNavigate: boolean, extra: any = {}) {
    if (!shouldNavigate) {
      return;
    }
    this.setState({ loading: true }, async () => {
      await this.props.history.push("/devices");
      this.setState({ loading: false });
    });
  }

  fetchPaymentMethod() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await BillingAPI.getPaymentMethod(
          this.props.userState.user.id,
          {}
        );
        const meth = {
          brand: result.body.data.paymentMethod.brand,
          last4: result.body.data.paymentMethod.lastFour,
          expMonth: result.body.data.paymentMethod.expMonth,
          expYear: result.body.data.paymentMethod.expYear,
          firstName: this.props.userState.user.firstName,
          lastName: this.props.userState.user.lastName,
        };
        this.setState({
          brand: meth.brand,
          last4: meth.last4,
          expMonth: meth.expMonth,
          expYear: meth.expYear,
          firstName: meth.firstName,
          lastName: meth.lastName,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if (jwtCheck() && this.state.setupCompleted) {
      return (
        <Row style={{ display: "flex", paddingTop: 10, marginBottom: 40 }}>
          <div className="col-lg-6 col-sm-12">
            <div>
              <h5 className="header-5" style={{ marginTop: 20 }}>
                Your Payments
              </h5>
              <div className="card-new-no-border">
                <h6
                  className="header-6"
                  style={{ marginTop: 2, marginBottom: 8 }}
                >
                  Current Payment
                </h6>
                <CurrentPaymentMethod
                  loading={this.state.loading}
                  brand={this.state.brand}
                  last4={this.state.last4}
                  expMonth={this.state.expMonth}
                  expYear={this.state.expYear}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                />
              </div>
            </div>
            <div>
              <TransactionHistory
              loading={this.state.loading}
              user={this.props.userState.user.id}
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <UpdatePaymentMethod
              loading={this.state.loading}
              paymentUpdated={this.navigateAway}
              address={null}
              setup={false}
              cardTitle="Change Payment Information"
            />
          </div>
        </Row>
      );
    } else if (jwtCheck() && !this.state.setupCompleted) {
      return <Redirect to="/setup" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
