import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";
import * as Alert from "../../structure/Alert";
import { UserAPI } from "../../../API";
import { styles } from "../../../styles";
import LoadingButton from "src/components/structure/LoadingButton";

interface ChangeEmailProps {
  userId: number;
  email: string;
  onUpdated: any;
}

interface ChangeEmailState {
  loading: boolean;
  newEmail: string;
  newEmailConfirm: string;
  buttonActive: boolean;
  showModal: boolean;
}

export class ChangeEmail extends Component<ChangeEmailProps, ChangeEmailState> {
  constructor(props: any) {
    super(props);
    this.state = {
      newEmail: "",
      newEmailConfirm: "",
      buttonActive: false,
      showModal: false,
      loading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
  }

  render() {
    return (
      <div>
        <div className="card-new-no-border">
          <div className="form-group">
            <h6 className="header-6">Update email</h6>
            <p className="main-text">Current email: {this.props.email}</p>
          </div>
          <div className="form-group">
            <input
              id="newEmail"
              type="text"
              className="form-control"
              placeholder="New Email Address"
              style={styles.inputFieldNew}
              onChange={this.updateField}
              value={this.state.newEmail}
              aria-label="Desired new email address"
            />
          </div>
          <div className="form-group">
            <input
              id="newEmailConfirm"
              type="text"
              className="form-control"
              placeholder="Confirm New Email Address"
              style={styles.inputFieldNew}
              onChange={this.updateField}
              value={this.state.newEmailConfirm}
              aria-label="Re-enter new email address"
            />
          </div>
            <div className="form-group">
              <LoadingButton
                aria-label="Update email address"
                loading={this.state.loading}
                className="btn btn-block btn-primary"
                style={styles.buttonNew}
                onClick={this.showConfirmation}
                disabled={this.compareEmails()}
              >
                UPDATE EMAIL ADDRESS
              </LoadingButton>
            </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          onEntered={(e: any) => {
            window.location.hash = "warning-modal";
            window.onhashchange = () => {
              if (!window.location.hash) {
                this.setState({ showModal: false });
              }
            };
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm email change</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>
                Are you sure you wish to make this change? After confirming, you
                will be logged out of all sessions and will need to confirm the
                new email before being allowed to continue.
              </p>
              <div style={styles.emailComponent}>
                <button
                  className="btn btn-block btn-primary"
                  style={styles.button}
                  aria-label="Confirm email change"
                  onClick={() => {
                    this.setState({ showModal: false }, () => {
                      this.submitChanges();
                    });
                  }}
                >
                  Confirm email change
                </button>
              </div>
              <div style={styles.emailComponent}>
                <button
                  className="btn btn-block btn-secondary"
                  style={styles.button}
                  aria-label="Take me back"
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  Take me back
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  submitChanges() {
    if (this.state.newEmail === this.props.email) {
      return Alert.error("New email can not be the same as current email");
    }
    this.setState({ loading: true }, async () => {
      try {
        const body = {
          email: this.state.newEmail,
        };
        await UserAPI.updateProfile(this.props.userId, body);
        this.setState({ loading: false });
        Alert.success("Email updated!");
        this.props.onUpdated();
      } catch (err) {
        this.setState({ loading: false });
        Alert.error(
          "Requested email changes could not be saved. Please contact support."
        );
      }
    });
  }

  private showConfirmation() {
    this.setState({ showModal: !this.state.showModal });
  }

  private compareEmails() {
    if (
      this.state.newEmail === this.state.newEmailConfirm &&
      this.state.newEmail !== ""
    ) {
      return false;
    }
    return true;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
