import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserView } from "react-device-detect";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { UserAPI } from "src/API";
import { styles } from "src/styles";

interface IRecoveryCodesProps {
  userId: number;
  userState: any;
  history: any;
  location: any;
}

interface IRecoveryCodesState {
  loading: boolean;
  showCodes: boolean;
  codesA: string[];
  codesB: string[];
  pdfData: string;
  pdfBase64: string;
  hasOTP: boolean;
}

const pdfTitle = "wagz_recovery_codes";

export class RecoveryCodes extends Component<
  IRecoveryCodesProps,
  IRecoveryCodesState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showCodes: false,
      codesA: [],
      codesB: [],
      pdfData: "",
      pdfBase64: "",
      hasOTP: false,
    };

    this.getUserRecoveryCodes = this.getUserRecoveryCodes.bind(this);
    this.updateField = this.updateField.bind(this);
    this.cancel = this.cancel.bind(this);
    this.show = this.show.bind(this);
  }

  componentDidMount() {
    this.getUserRecoveryCodes();
  }

  render() {
    return (
      <div>
      {this.state.loading && (
        <div style={{ textAlign: "center", marginTop: 100 }}>
          <div className="spinner-border" role="status" />
        </div>
      )}
        {!this.state.hasOTP && !this.state.loading && (
          <div className="row">
            <div className="col-lg-4 col-sm-12"></div>
              <div className="col-lg-4 col-sm-12">
                <div className="card-new-no-border">
                  <div className="row" style={{ marginTop: 21 }}>
                    <div className="col-1">
                      <Link
                        to="/account"
                        id="nav-account"
                        className="nav-link-new"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="text" />
                      </Link>
                    </div>
                    <div className="col-9">
                      <label className="codes">Backup recovery codes</label>
                    </div>
                  </div>
                    <div className="container">
                      <p className="otp-sub">
                      Recovery codes are provided when you enable Multi-factor Authentication on your account.
                      </p>
                    </div>
                </div>
              </div>
            <div className="col-lg-4 col-sm-12"></div>
          </div>
        )}
        {this.state.hasOTP && !this.state.loading && (
            <div className="row">
              <div className="col-lg-4 col-sm-12"></div>
              <div className="col-lg-4 col-sm-12">
                <div className="card-new-no-border">
                  <div className="row" style={{ marginTop: 21 }}>
                    <div className="col-1">
                      <Link
                        to="/account"
                        id="nav-account"
                        className="nav-link-new"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="text" />
                      </Link>
                    </div>
                    <div className="col-9">
                      <label className="codes">Backup recovery codes</label>
                    </div>
                  </div>
                  {!this.state.showCodes && (
                    <div className="container">
                      <p className="otp-sub">
                        Use recovery codes to sign-in if you lose your phone or
                        can’t receive verification codes by text message or
                        email. Keep these codes somewhere safe and easy to
                        access.
                      </p>
                      <div className="row">
                        <div className="col">
                          <button
                            style={styles.buttonNewSecondary}
                            aria-label="Cancel Deletion"
                            className="btn btn-block btn-secondary"
                            onClick={this.cancel}
                          >
                            CANCEL
                          </button>
                        </div>
                        <div className="col">
                          <button
                            style={styles.buttonNew}
                            aria-label="Process Deletion"
                            className="btn btn-block btn-primary"
                            onClick={this.show}
                          >
                            GET CODES
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.showCodes && (
                    <div className="container">
                      <p className="otp-sub">
                        Use recovery codes to sign-in if you lose your phone or
                        can’t receive verification codes by email. Keep these
                        codes somewhere safe and easy to access.
                      </p>
                      <div className="row">
                        <div className="col">
                          {this.state.codesA.map((e: any) => {
                            return <p>{e}</p>;
                          })}
                        </div>
                      </div>
                      <BrowserView>
                        <div className="row">
                          <a
                            download={pdfTitle}
                            href={this.state.pdfData}
                            title="Download pdf document"
                            style={{ width: "100%" }}
                          >
                            <button
                              style={styles.buttonNewSecondary}
                              aria-label="Download Codes (PDF)"
                              className="btn btn-block btn-secondary"
                            >
                              DOWNLOAD CODES (PDF)
                            </button>
                          </a>
                        </div>
                      </BrowserView>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-sm-12"></div>
            </div>
          )}
      </div>
    );
  }

  getUserRecoveryCodes() {
    this.setState({ loading: true }, async () => {
      try {
        let codesA: any[] = [];
        let codesB: any[] = [];
        let hasOTP = false
        const result = await UserAPI.getUserCodes(this.props.userState.user.id, { format: "pdf" });
        result.body.data.recoveryCodes.forEach((value: any, index: any) => {
          if (index >= 0) {
            codesA.push(value);
          } else {
            codesB.push(value);
          }
        });
        const pdfData = "data:application/pdf;base64," + result.body.data.pdf;

        if (this.props.location.state.authenticator) {
          hasOTP = true
        }
        if (this.props.location.state.email) {
          hasOTP = true
        }

        this.setState({ codesA, codesB, pdfData, pdfBase64: result.body.data.pdf, loading: false, hasOTP });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  show() {
    this.setState({ showCodes: true });
  }
  cancel() {
    this.props.history.goBack();
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryCodes);
