import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Modal } from "react-bootstrap";

import * as AppActions from "../../../../reducers/appReducer";
import * as UserActions from "../../../../reducers/userReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as Alert from "src/components/structure/Alert";
// import LoadingButton from "src/components/structure/LoadingButton";
import { UserAPI } from "src/API";
import { styles } from "src/styles";

interface IAuthenticatorOTPProps {
  userId: number;
  location: any;
  userState: any;
  history: any;
}

interface IAuthenticatorOTPState {
  loading: boolean;
  enabled: boolean;
  verified: boolean;
  showDeleteModal: boolean;
  showQREnableModal: boolean;
  showSecretEnableModal: boolean;
  qrCode: string;
  secret: string;
  code: string;
}

export class AuthenticatorOTP extends Component<
  IAuthenticatorOTPProps,
  IAuthenticatorOTPState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      enabled: this.props.location.state.enabled,
      verified: this.props.location.state.verified,
      showDeleteModal: false,
      showQREnableModal: false,
      showSecretEnableModal: false,
      qrCode: "",
      secret: "",
      code: "",
    };

    this.updateField = this.updateField.bind(this);
    this.cancel = this.cancel.bind(this);
    this.continue = this.continue.bind(this);
    this.delete = this.delete.bind(this);
    this.secretSetup = this.secretSetup.bind(this);
    this.startAuthenticatorSetup = this.startAuthenticatorSetup.bind(this);
    this.submitDeletion = this.submitDeletion.bind(this);
    this.verifyAuth = this.verifyAuth.bind(this);
  }

  componentDidMount() {
    console.log("props", this.props.location.state)
    if (
      !this.props.location.state.enabled &&
      !this.props.location.state.verified
    ) {
      console.log("startAuthenticatorSetup")
      this.startAuthenticatorSetup();
    } else if (
      this.props.location.state.enabled &&
      !this.props.location.state.verified
    ) {
      console.log("showQREnableModal")
      this.setState({ showQREnableModal: true });
    } else {
      console.log("nada")
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-4 col-sm-12"></div>
        <div className="col-lg-4 col-sm-12">
          <div className="card-new-no-border">
            <div className="row" style={{ marginTop: 21 }}>
              <div className="col-1">
                <Link to="/account" id="nav-account" className="nav-link-new">
                  <FontAwesomeIcon icon={faArrowLeft} className="text" />
                </Link>
              </div>
              <div className="col-10">
                <label className="codes">
                  Set up a third party authenticator
                </label>
              </div>
            </div>
            {!this.state.verified && !this.state.enabled && (
              <div>
                <div>
                  <p className="otp-sub">
                    Please use your authentication app (Google Authenticator,
                    Microsoft Authenticator, Authy, etc.) to scan this QR code.
                  </p>
                </div>
                <Container>
                  <Row>
                    <div
                      style={{
                        marginBottom: 8,
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {this.state.qrCode ? (
                        <img
                          src={`data:image/png;base64,${this.state.qrCode}`}
                          alt="unable to load QR code"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                  <Row>
                    {/*  */}
                    <div className="col-12" style={{ marginTop: 10, marginBottom: 20 }}>
                      <div style={styles.centerText}>
                        <Link
                          to="/account/security/authenticator"
                          onClick={this.secretSetup}
                          id="nav-login"
                          className="nav-link-new"
                          style={styles.forgot}
                        >
                            Set up on same device
                        </Link>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <button
                        style={styles.buttonNewSecondary}
                        aria-label="Cancel Deletion"
                        className="btn btn-block btn-secondary"
                        onClick={this.cancel}
                      >
                        CANCEL
                      </button>
                    </Col>
                    <Col>
                      <button
                        style={styles.buttonNew}
                        aria-label="Process Deletion"
                        className="btn btn-block btn-primary"
                        onClick={this.continue}
                      >
                        CONTINUE
                      </button>
                    </Col>
                  </Row>
                </Container>
                <div style={{ marginBottom: 100 }}/>
              </div>
            )}
            {this.state.verified && this.state.enabled && (
              <div>
                <div>
                  <p className="otp-sub">
                    Get verification codes using an authenticator app instead of
                    waiting for a text message or Email.
                  </p>
                </div>
                <div className="row">
                  <div className="col-6">
                    <strong>Your Authenticator</strong>
                  </div>
                  <div className="col-1 offset-2">
                    <Link
                      to="/account/security/authenticator"
                      onClick={this.delete}
                    >
                      REMOVE
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-4 col-sm-12"></div>

        <Modal
          show={this.state.showDeleteModal}
          onHide={() => {
            this.setState({ showDeleteModal: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>Remove authenticator app?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "102%", marginLeft: -10 }}>
              <Container>
              <p className="otp-modal-sub">
                Are you sure you want to remove this authenticator app as a
                verification option?
              </p>
                <Row>
                  <Col>
                    <button
                      style={styles.buttonNewSecondary}
                      aria-label="Cancel Deletion"
                      className="btn btn-block btn-secondary"
                      onClick={this.cancel}
                    >
                      CANCEL
                    </button>
                  </Col>
                  <Col>
                    <button
                      style={styles.buttonNew}
                      aria-label="Process Deletion"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState({ showDeleteModal: false }, () => {
                          this.submitDeletion();
                        });
                      }}
                    >
                      REMOVE
                    </button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showQREnableModal}
          onHide={() => {
            this.setState({ showQREnableModal: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter confirmation code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Container>
              <p className="otp-modal-sub">Enter the 6-digit code you see on your authentication app.</p>
                <Row>
                    <input
                      id="code"
                      type="text"
                      className="form-control"
                      aria-label="The code from the auth app"
                      aria-labelledby="codeLabel"
                      placeholder="Enter Code"
                      style={styles.inputFieldNew}
                      value={this.state.code}
                      onChange={this.updateField}
                    />
                </Row>
                <div className="row" style={{ marginTop: 10 }}>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNewSecondary}
                      aria-label="Cancel Deletion"
                      className="btn btn-block btn-secondary"
                      onClick={() => {
                        this.setState({ showQREnableModal: false });
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNew}
                      aria-label="Process Deletion"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState({ showDeleteModal: false }, () => {
                          this.verifyAuth();
                        });
                      }}
                    >
                      VERIFY
                    </button>
                  </div>
                </div>
              </Container>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showSecretEnableModal}
          onHide={() => {
            this.setState({ showSecretEnableModal: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Set up without a QR code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Container>
              <p className="otp-modal-sub">
                Copy and paste this registration code/key into your
                authenticator app
              </p>
                <Row>
                    <input
                      id="secret"
                      type="text"
                      className="form-control"
                      aria-label="The registration code to paste into an auth app"
                      aria-labelledby="secretLabel"
                      style={styles.inputFieldNew}
                      value={this.state.secret}
                      disabled
                    />
                </Row>
                <div className="row" style={{ marginTop: 10 }}>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNewSecondary}
                      aria-label="Cancel Deletion"
                      className="btn btn-block btn-secondary"
                      onClick={() => {
                        this.setState({ showSecretEnableModal: false });
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNew}
                      aria-label="Process Deletion"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState(
                          {
                            showSecretEnableModal: false,
                            showQREnableModal: true,
                          },
                          () => {
                            navigator.clipboard.writeText(this.state.secret);
                          }
                        );
                      }}
                    >
                      COPY
                    </button>
                  </div>
                </div>
              </Container>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  startAuthenticatorSetup() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.startOTPEnrollment(
          this.props.userState.user.id,
          {
            method: "authenticator",
          }
        );
        console.log("auth start", result);
        this.setState({
          loading: false,
          qrCode: result.body.data.image64,
          secret: result.body.data.secret,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  verifyAuth() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.verifyOTP(
          this.props.userState.user.id,
          this.state.code,
          {
            otpMethod: "authenticator",
          }
        );
        console.log("auth verify", result);
        this.setState({
          loading: false,
          enabled: true,
          verified: true,
        });
        Alert.success("Authenticator added to your account.");
        await this.props.history.push("/account");
      } catch (err) {
        Alert.success("Unable to verify the authenticator.");
        this.setState({ loading: false });
      }
    });
  }

  private cancel() {
    this.props.history.goBack();
  }
  private continue() {
    this.setState({ showQREnableModal: !this.state.showQREnableModal });
  }
  private delete() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  private secretSetup() {
    this.setState({ showSecretEnableModal: !this.state.showSecretEnableModal });
  }

  submitDeletion() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.removeOTP(this.props.userState.user.id, "authenticator");
        this.setState({ enabled: false, verified: false, loading: false });
        Alert.success("Authenticator removed from your account.");
        this.props.history.goBack();
      } catch (err) {
        Alert.error("Could not remove that method.");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatorOTP);
