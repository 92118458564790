import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "url-search-params-polyfill";
import * as EmailValidator from "email-validator";

import Card from "../../structure/Card";
import ScopeCard from "./ScopeCard";
import * as Alert from "../../structure/Alert";
import * as UserActions from "../../../reducers/userReducer";

import { UserAPI } from "../../../API"
import { bindActionCreators } from "redux";
import { styles } from "../../../styles";

interface ILoginProps {
  location: any;
  userActions: any;
  userState: any;
}

interface ILoginState {
  email: string;
  password: string;
  scopes: any[];
  clientId: string;
  state: string | null;
  redirectURI: string;
  isLoggedIn: boolean;
  loading: boolean;
}

class Login extends Component<ILoginProps, ILoginState> {

  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      scopes: [],
      clientId: "",
      state: "",
      redirectURI: "",
      isLoggedIn: false,
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window.URLSearchParams) {
      const query = new URLSearchParams(this.props.location.search);
      const state = query.get("state");
      const clientId = query.get("client_id");
      const redirectURI = query.get("redirect_uri");
      const scope = query.get("scope");
      if (scope && clientId && redirectURI) {
        let scopes = scope.split(" ");
        let newScopes = [];
        for (let s of scopes) {
          switch (s) {
            case "devices":
              newScopes.push({
                title: "Devices",
                description: "View and manage information related to devices on your account"
              });
              break;
            case "pets":
              newScopes.push({
                title: "Pets",
                description: "View and manage information related to your pets"
              });
              break;
            case "profile":
              newScopes.push({
                title: "Profile",
                description: "View and manage information related to your profile"
              });
              break;
            default:
              break;
          }
        }
        this.setState({
          scopes: newScopes,
          clientId,
          redirectURI,
          state
        });
      }

    }
  }

  handleChange(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleSubmit() {
    //check validation
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    if (!EmailValidator.validate(email)) {
      return Alert.error("Invalid email address entered");
    }
    if (email === "" || password === "") {
      return Alert.error("Email and password are required");
    }

    this.setState({ loading: true }, async () => {
      try {
        let result = await UserAPI.attemptOAuthLogin(this.state.email, this.state.password, this.state.clientId, this.state.scopes);

        // redirect with the returned values
        const url = encodeURI(`${this.state.redirectURI}?state=${this.state.state}&code=${result.body.data.code}&vendorId=M1I8N8MA9X98T5`);
        window.location.replace(url);

      } catch (error) {
        Alert.error("Your email and/or password are not valid");
        this.setState({ loading: false });
      }
    })
  }

  render() {
    // TODO: If they are logged in already, we probably don't need to ask for
    // their email and password. However, that will require a new endpoint on the API a the current one
    // requires those new credentials
    return (
      <div className="row">
        <div style={styles.itemAlign}>
          <div style={{ marginTop: 120, width: 380, marginBottom: 260 }} className="card-bg">
            <Card title="Login" loading={this.state.loading}>
              {this.state.scopes.map((scope: any, index: number) => {
                return <ScopeCard key={index} title={scope.title} description={scope.description} />
              })}

              <div className="form-group" style={{ marginTop: 10 }}>
                <label style={styles.inputHeader}>Email Address</label>
                <input id="email" type="email" className="form-control" value={this.state.email} onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <label style={styles.inputHeader}>Password</label>
                <input id="password" type="password" className="form-control" value={this.state.password} onChange={this.handleChange} />
              </div>

              <div className="form-group">
                <button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Login</button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login) as any);
