import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import DeviceActivatedOnSetupMessage from "src/components/screens/Setup/DeviceActivatedOnSetupMessage";
import { styles } from "src/styles";
import { getMobileOperatingSystem } from "src/utils/utilities"

interface ISuccessStepProps {
  userActions: any;
  userState: any;
  success: boolean;
  isMobile: boolean;
}

interface ISuccessStepState {
  loading: boolean;
  isMobile: boolean;
  os: string;
}

export class SuccessStep extends Component<
  ISuccessStepProps,
  ISuccessStepState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      isMobile: false,
      os: ""
    };

    this.getMobile = this.getMobile.bind(this);
  }

  componentDidMount() {
    this.getMobile();
  }

  getMobile() {
    const mobileOS = getMobileOperatingSystem()
    
    if (mobileOS !== "unknown") {
      this.setState({ isMobile: true, os: mobileOS });
      return
    }
    this.setState({
      os: mobileOS,
    });
  }

  get returnValue() {
    if (this.props.success) {
      return (
      <div>
        <DeviceActivatedOnSetupMessage os={this.state.os} mobile={this.state.isMobile}/>
      </div>);
    }
    return (
      <div style={{ marginTop: 20, width: 380 }}>
        <p style={styles.signupHeader}>Woof! Something went wrong!</p>
        <div className="card-warning">
          <p style={styles.bottomParagraphWarning}>
            Sorry, but there was an issue during your setup. Please refresh this
            page to try again, or contact 844.252.3140 for support.
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row" style={{ marginBottom: 100 }}>
        <div style={styles.itemAlign}>{this.returnValue}</div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessStep);
