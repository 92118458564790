import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";

interface IWarningModalState {}

interface IWarningModalProps {
  modalHeader: string;
  productName: string;
  loading: boolean;
  appState: any;
  children?: any;
}

class WarningModal extends React.Component<
  IWarningModalProps,
  IWarningModalState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <section
        className="text"
        aria-label="Important Information about your device"
      >
        <div style={{ textAlign: "center" }}>
          <strong>{this.props.modalHeader}</strong>
        </div>

        <div style={{ textAlign: "center" }}>
          <strong>
            WARNING! READ ALL SAFETY WARNINGS AND INSTRUCTIONS. FAILURE TO
            FOLLOW THE WARNINGS AND INSTRUCTIONS MAY RESULT IN INJURY.
          </strong>
        </div>
        <br />

        <p>
          WARNING! Do not dismantle or recreate the {this.props.productName}, as
          it will void your warranty.
        </p>
        <p>
          WARNING! The {this.props.productName} should only be charged with the
          charger provided in the box.
        </p>
        <p>
          WARNING! The {this.props.productName} is not rated to act as a primary
          pet restraint device. Make sure your pet is properly secured and safe
          at all times.
        </p>
        <p>
          WARNING! The {this.props.productName} has electronics that are
          sensitive to extreme temperatures. Do not leave the collar in high
          temperatures for extended periods of time.
        </p>
        <p>
          CAUTION: The {this.props.productName} is intended for pets only and
          should not be used with humans as a toy.
        </p>
        <p>
          CAUTION: The {this.props.productName} is not a medical device and it
          is not intended to diagnose, treat, cure, or prevent any disease. The{" "}
          {this.props.productName} is not a replacement for routine care by a
          licensed veterinarian.
        </p>
        <p>
          CAUTION: The {this.props.productName} GPS data and accuracy may vary
          based upon location and is not guaranteed for indoor use.
        </p>
        <p>
          CAUTION: The {this.props.productName} is IP67 level dust and water
          resistant. No harmful dust or dirt will seep into the unit after being
          in direct contact with the matter for eight hours. The
          {this.props.productName} can handle spills and splashes of water,
          sweat and rain. It can be fully submerged in a body of water up to a
          meter deep (approximately 3 feet) for half an hour.
        </p>
        <p>
          NOTICE: To clean the {this.props.productName} use a damp cloth and
          mild soap.
        </p>
        <p>
          NOTICE: Operation of the {this.props.productName} is dependent on use
          of the mobile app, as well as the collar having adequate cellular
          network signal. The performance of the {this.props.productName} may
          vary depending on the cellular network signal strength in a given
          location.
        </p>
        <p>
          NOTICE: Not every pet can be trained to avoid the established
          boundary. Sometimes, even a properly trained pet may cross the
          boundary. Therefore, neither Wagz, Inc., nor its distributors and
          dealers can guarantee the system will, in all cases, keep a customer’s
          pet within the established boundary.
        </p>
        <p>
          Accordingly, if a customer has reason to believe that their pet may
          pose a danger to others, or harm itself, the customer should not rely
          solely upon the Wagz pet containment system to keep the pet from
          crossing the boundary.
        </p>
      </section>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(WarningModal);
