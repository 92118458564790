import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";

import * as Alert from "../../structure/Alert";
import moment from "moment";
import Activate from "./Activate";
import { Container, Row, Col } from "react-bootstrap";
import { DevicesAPI } from "../../../API/index";
import { jwtCheck, verifySetup, capitalizeFirstLetter } from "../../../utils/utilities";
import { formatCurrency } from "../../../utils/currency";
import DeviceActivatedMessage from "./DeviceActivatedMessage";
import { styles } from "../../../styles";
interface DeviceScreenProps {
  history: any;
  location: any;
  loading: boolean;
  userActions: any;
  userState: any;
}

interface DeviceScreenState {
  devices: any[];
  count: number;
  loading: boolean;
  multiPlanEligible: boolean;
  showSuccess: boolean;
  setupCompleted: boolean | undefined;
  guides: any;
  metadata: any;
}

export class Devices extends Component<DeviceScreenProps, DeviceScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      devices: [],
      count: 0,
      loading: false,
      multiPlanEligible: false,
      showSuccess: false,
      setupCompleted: true,
      guides: (
        <ul>
          <li style={styles.guideLinkOnCard}>
            <a
              href="https://cdn.wagz.com/pdf/Wagz_Onboarding_and_Setup_Guide.pdf"
              style={styles.setupGuideUnderlineOnCard}
            >
              Freedom Dog Collar Setup Guide
            </a>{" "}
            (PDF)
          </li>
        </ul>
      ),
      metadata: null,
    };
    this.activateDevice = this.activateDevice.bind(this);
    this.fetchDevices = this.fetchDevices.bind(this);
    this.checkDeviceFamilies = this.checkDeviceFamilies.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  /**
   *
   */

  componentDidMount() {
    this.fetchDevices();
    this.setState({ loading: true }, async () => {
      try {
        const check = await verifySetup(this.props.userState.user.id);
        this.setState({ setupCompleted: check, loading: false });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    });
  }

  /**
   * Retrieves a current list of devices linked to the account of the logged in user.
   */
  fetchDevices() {
    this.setState({ loading: true }, async () => {
      try {
        const meta = await DevicesAPI.getDevicesMetadata();
        const result = await DevicesAPI.getDevices(
          this.props.userState.user.id,
          { status: "all" }
        );
        const devices: any = [];
        for (const d of result.body.data) {
          if (d.status !== "deactivated") {
            devices.push(d);
          }
        }
        let hasPlan = false;
        for (const p of result.body.data) {
          if (
            p.planInfo.currentPlanId === 33 ||
            p.planInfo.currentPlanId === 44
          ) {
            hasPlan = true;
          }
        }
        this.setState({
          devices,
          multiPlanEligible: hasPlan,
          count: this.state.count + 1,
          metadata: meta.body.data,
          loading: false,
        });
        this.checkDeviceFamilies(devices);
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  checkDeviceFamilies(d: any[]) {
    for (const device of d) {
      if (device.productFamilyId === "24") {
        this.setState({
          guides: (
            <ul>
              <li style={styles.guideLinkOnCard}>
                <a
                  href="https://cdn.wagz.com/pdf/Wagz_Onboarding_and_Setup_Guide.pdf"
                  style={styles.setupGuideUnderlineOnCard}
                >
                  Freedom Collar Onboarding and Setup Guide
                </a>{" "}
                (PDF)
              </li>
              <li style={styles.guideLinkOnCard}>
                <a
                  href="https://cdn.wagz.com/pdf/Cat_Collar_User_Guide.pdf"
                  style={styles.setupGuideUnderlineOnCard}
                >
                  Cat Collar Pre-Launch Instructions For Use
                </a>{" "}
                (PDF)
              </li>
            </ul>
          ),
        });
      }
    }
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  /**
   * Takes in the device and talks to the DevicesAPI to either set the device for preregistration or throw an error.
   * @param {*} device Passes in current device
   */
  async activateDevice(device: any) {
    this.setState({ loading: true }, async () => {
      try {
        let actRes = await DevicesAPI.activateDevice(
          this.props.userState.user.id,
          device.productId.toUpperCase(),
          device.selectedPlanId,
          device.nickname,
          { couponCode: device.validCode }
        );
        if (actRes.status === 202) {
          //we need to try again later
          Alert.error(
            "This is taking longer than expected but we are still trying. Please continue to wait!",
            { title: "Still Waiting..." }
          );
          return setTimeout(() => {
            this.activateDevice(device);
          }, 2000);
        }
        Alert.success("Preregistration successful!");
        return this.setState({ loading: false, showSuccess: true }, () =>
          this.fetchDevices()
        );
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not preregister that device", {});
        });
      }
    });
  }

  getDevicesList() {
    const devices = [];
    let term = "";
    let amount = "";
    let productName = "";
    if (this.state.devices.length === 0) {
      devices.push(<strong key={1}>No products registered</strong>);
    } else {
      for (let device of this.state.devices) {
        if (device.planStatus !== "unknown") {
          term = device.planInfo.plan.term;
          amount = device.planInfo.plan.amount;
        }
        const parsedId = device.productId.substr(4, 2);
        for (const d of this.state.metadata) {
          if (parseInt(parsedId) === d.id) {
            productName = d.consumerBrandName;
          }
        }
        devices.push(
          <div style={{ backgroundColor: "transparent" }} key={device.id}>
            <div className="device-row-mobile">
              <div style={{ marginBottom: 15 }}>
                <h6 className="header-6" style={{ marginLeft: 15 }}>{device.nickname}</h6>
              </div>
              <Container style={{ marginLeft: 15 }}>
                <Row>
                  <Col sm={12} md={6}>
                    <div className="row" style={{ marginBottom: -10 }}>
                      <p className="device-p-mobile-field">Product: </p>
                      <p className="device-p-mobile-info" style={{ marginLeft: 5 }}>
                        {productName}
                      </p>
                    </div>
                    <div className="row" style={{ marginBottom: -10 }}>
                      <p className="device-p-mobile-field">Serial Number: </p>
                      <p className="device-p-mobile-info" style={{ marginLeft: 5 }}>
                        {device.productId}
                      </p>
                    </div>
                    <div className="row" style={{ marginBottom: -10 }}>
                      <p className="device-p-mobile-field">Status: </p>
                      <p
                        style={{
                          marginLeft: 8,
                          fontSize: 16,
                          fontFamily: "SF Pro Text",
                          marginTop: -1,
                        }}
                      >
                        {capitalizeFirstLetter(device.status)}
                      </p>
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="row" style={{ marginBottom: -10 }}>
                      <p className="device-p-mobile-field">Setup Date: </p>
                      <p className="device-p-mobile-info" style={{ marginLeft: 5 }}>
                        {moment(device.registeredOn).format("MM/DD/YYYY")}
                      </p>
                    </div>
                    <div className="row" style={{ marginBottom: -10 }}>
                      <p className="device-p-mobile-field">Plan Type: </p>
                      <p className="device-p-mobile-info" style={{ marginLeft: 5 }}>
                        {capitalizeFirstLetter(term)}
                      </p>
                    </div>
                    <div className="row">
                      <p className="device-p-mobile-field">Plan Cost: </p>
                      <p className="device-p-mobile-info" style={{ marginLeft: 5 }}>
                        {formatCurrency(amount)}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        );
      }
    }
    return devices;
  }

  render() {
    if (jwtCheck() && this.state.setupCompleted) {
      return (
        <div>
          {this.state.showSuccess && <DeviceActivatedMessage />}
          <Row>
            <div className="col-sm-12 col-lg-6">
              <h5 className="header-5" style={{ marginTop: 20 }}>
                Your Products
              </h5>
              <Activate
                onActivateDevice={this.activateDevice}
                loading={this.state.loading}
                multiPlanEligible={this.state.multiPlanEligible}
              />
            </div>
            <div className="col-sm-12 col-lg-6">
              <h5 className="header-5" style={{ marginTop: 20 }}>
                Registered Products
              </h5>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  fontFamily: "Helvetica Neue",
                }}
              >
                To manage your plan, please contact support.
              </p>
              <div className="card-new-no-border">{this.getDevicesList()}</div>
              <h5 className="header-5" style={{ marginTop: 20 }}>
                Setup Guides
              </h5>
              <div className="card-new-no-border">{this.state.guides}</div>
            </div>
          </Row>
        </div>
      );
    } else if (jwtCheck() && !this.state.setupCompleted) {
      return <Redirect to="/setup" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
