import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { UserAPI } from "src/API";
import { styles } from "src/styles";

interface ISecuritySettingsProps {
  userId: number;
  userState: any;
}

interface ISecuritySettingsState {
  loading: boolean;
  authenticator: boolean;
  authenticatorVerified: boolean;
  email: boolean;
  emailVerified: boolean;
}

export class SecuritySettings extends Component<
  ISecuritySettingsProps,
  ISecuritySettingsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      authenticator: false,
      authenticatorVerified: false,
      email: false,
      emailVerified: false,
    };

    this.getUserOTPStatus = this.getUserOTPStatus.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  componentDidMount() {
    this.getUserOTPStatus();
  }

  render() {
    return (
      <div className="card-new-no-border">
        <label className="security" style={{ marginTop: 5 }}>
          Security Settings
        </label>
        <div>
          <label className="header-6">
            Set up or update Multi-factor Authentication
          </label>
          <p>
            Helps prevent unauthorized access to your account by requiring
            authentication codes while signing in. We’ll ask for a verification
            code from your security method during sign in.
          </p>
        </div>
        <div style={styles.borderBottom}></div>
        <div>
          <div className="row">
            <div className="col-9">
              <label className="header-6">Email verification</label>
              <p className="security-text">
                Receive sign-in codes at this email address.
              </p>
            </div>
            <div className="col-1" />
            <div
              className="col-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/account/security/email",
                  state: {
                    enabled: this.state.email,
                    verified: this.state.emailVerified,
                    emailAddress: this.props.userState.user.email,
                  },
                }}
                id="nav-email"
                className="nav-link-new"
              >
                <FontAwesomeIcon icon={faGreaterThan} className="text" />
              </Link>
            </div>
          </div>
          {this.state.emailVerified && (
            <div>
              <p className="security-text" style={{ marginBottom: -10 }}>
                {this.props.userState.user.email}
              </p>
              <p className="verified">Verified</p>
            </div>
          )}
        </div>
        <div style={styles.borderBottom}></div>
        <div>
          <div className="row">
            <div className="col-9">
              <label className="header-6">Backup recovery codes</label>
              <p className="security-text">
                Retrieve printable one-time codes to allow you to sign in when
                you’re away from your phone. Keep these in a safe place.
              </p>
            </div>
            <div className="col-1" />
            <div
              className="col-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/account/security/codes",
                  state: {
                    authenticator: this.state.authenticatorVerified,
                    email: this.state.emailVerified,
                  },
                }}
                id="nav-codes"
                className="nav-link-new"
              >
                <FontAwesomeIcon icon={faGreaterThan} className="text" />
              </Link>
            </div>
          </div>
        </div>
        <div style={styles.borderBottom}></div>
        <div>
          <div className="row">
            <div className="col-9">
              <label className="header-6">
                Authenticator app verification{" "}
              </label>
              <p className="security-text">
                Use a free authenticator app to get verification codes.
                Available for iPhone and Android.
              </p>
            </div>
            <div className="col-1" />
            <div
              className="col-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: "/account/security/authenticator",
                  state: {
                    enabled: this.state.authenticator,
                    verified: this.state.authenticatorVerified,
                  },
                }}
                id="nav-authenticator"
                className="nav-link-new"
              >
                <FontAwesomeIcon icon={faGreaterThan} className="text" />
              </Link>
            </div>
          </div>
          {this.state.authenticator && !this.state.authenticatorVerified && (
            <div>
              <p className="pending">Pending</p>
            </div>
          )}
          {this.state.authenticator && this.state.authenticatorVerified && (
            <div>
              <p className="verified">Verified</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  getUserOTPStatus() {
    this.setState({ loading: true }, async () => {
      try {
        let authenticator = false;
        let authenticatorVerified = false;
        let email = false;
        let emailVerified = false;
        const result = await UserAPI.getUserOTP(this.props.userId);

        if (result.body.data.length === 0) {
          this.setState({ loading: false });
          return;
        }

        for (let v of result.body.data) {
          if (v.method === "email" && v.status === "verified") {
            email = true;
            emailVerified = true;
          } else if (v.method === "authenticator" && v.status === "verified") {
            authenticator = true;
            authenticatorVerified = true;
          } else if (v.method === "email" && v.status === "pending") {
            email = true;
          } else if (v.method === "authenticator" && v.status === "pending") {
            authenticator = true;
          }
        }
        this.setState({
          authenticator,
          authenticatorVerified,
          email,
          emailVerified,
          loading: false,
        });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecuritySettings);
