import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { QrReader } from "react-qr-reader";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import * as Alert from "src/components/structure/Alert";
import { BillingAPI, DevicesAPI, UserAPI } from "src/API/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

import { styles } from "src/styles";
import LoadingButton from "src/components/structure/LoadingButton";
import WarningModal from "src/components/structure/WarningModal";
import HCaptcha from "@hcaptcha/react-hcaptcha";

interface IActivateDeviceProps {
  loading: boolean;
  userActions: any;
  userState: any;
  onActivateDevice: any;
  multiPlanEligible: boolean;
}

interface IActivateDeviceState {
  productId: string;
  nickname: string;
  loading: boolean;
  activateLoading: boolean;
  activateModalShown: boolean;
  planSelectModalShown: boolean;
  selectedPlanId: number;
  plans: any[];
  modalHeader: string;
  productName: string;
  couponCode: string;
  validCode: string;
  validationMessage: string;
  couponColor: string;
  showQRModal: boolean;
  captchaValid: boolean;
}

const siteKey = process.env.REACT_APP_CAPTCHA_SITEKEY || "";

class activateDeviceScreen extends Component<
  IActivateDeviceProps,
  IActivateDeviceState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      productId: "",
      nickname: "",
      loading: false,
      activateLoading: false,
      activateModalShown: false,
      planSelectModalShown: false,
      selectedPlanId: 43,
      plans: [],
      modalHeader: "",
      productName: "",
      couponCode: "",
      validCode: "",
      validationMessage: "",
      couponColor: "#C70039",
      showQRModal: false,
      captchaValid: false,
    };

    this.updateField = this.updateField.bind(this);
    this.activate = this.activate.bind(this);
    this.activateEnter = this.activateEnter.bind(this);
    this.showActivateModal = this.showActivateModal.bind(this);
    this.planBody = this.planBody.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.showQRModal = this.showQRModal.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  showQRModal() {
    this.setState({
      showQRModal: true,
    });
  }

  compare(a: any, b: any) {
    // converting to uppercase to ignore character casing
    const planA = a.display.toUpperCase();
    const planB = b.display.toUpperCase();

    let comparison = 0;
    if (planA > planB) {
      comparison = 1;
    } else if (planA < planB) {
      comparison = -1;
    }
    return comparison;
  }

  getPlans() {
    this.setState({ loading: true }, async () => {
      try {
        const productFamily = this.state.productId.substring(4, 6);
        if (productFamily === "24") {
          this.setState({
            modalHeader: "Small Pet Tracking and Wellness Collar",
            productName: "Wagz Small Pet Collar",
          });
        } else if (productFamily === "23") {
          this.setState({
            modalHeader: "Wagz Freedom™ Safety and Wellness Dog Collar",
            productName: "Wagz Freedom™ Collar",
          });
        } else if (productFamily === "25") {
          this.setState({
            modalHeader: "Tracking Collar",
            productName: "Tracking Collar",
          });
        } else if (productFamily === "26") {
          this.setState({
            modalHeader: "Health Box",
            productName: "Health Box",
          });
        } else if (productFamily === "30") {
          this.setState({
            modalHeader: "Wagz Connect™ Smart Dog Collar",
            productName: "Wagz Connect™ Collar",
          });
        } else if (productFamily === "31") {
          this.setState({
            modalHeader: "Wagz Bowl",
            productName: "Wagz Bowl",
          });
        }
        const plans = await DevicesAPI.getPlans(parseInt(productFamily), {
          showPlan: true,
          productId: this.state.productId,
        });
        let filteredPlans = [];
        for (const p of plans.body.data) {
          // if (p.showPlan && this.props.multiPlanEligible) {
          //   if (p.id === 40 || p.id === 45) filteredPlans.push(p);
          //   if (productFamily !== "23") {
          //     filteredPlans.push(p);
          //   }
          // } else if (
          //   p.showPlan &&
          //   !this.props.multiPlanEligible &&
          //   p.id !== 40 &&
          //   p.id !== 45
          // ) {
          //   filteredPlans.push(p);
          // }
          if (p.showPlan) {
            filteredPlans.push(p);
          }
        }
        this.setState({
          plans: filteredPlans.sort(this.compare),
          selectedPlanId: filteredPlans[0].id,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  activate() {
    const lead = parseInt(this.state.productId.substring(0, 2));
    if (lead < 19 || this.state.productId.length !== 10) {
      return Alert.error("You must provide a valid 10-digit Serial Number!");
    }
    this.setState({ loading: true }, () => {
      this.props.onActivateDevice(this.state);
      this.setState({
        nickname: "",
        productId: "",
        couponCode: "",
        couponColor: "#C70039",
        validCode: "",
        validationMessage: "",
        loading: false,
      });
    });
  }

  showActivateModal() {
    this.getPlans();
    const lead = parseInt(this.state.productId.substring(0, 2));
    const family = parseInt(this.state.productId.substring(4, 6));
    if (lead < 19 || this.state.productId.length !== 10) {
      return Alert.error("You must provide a valid 10-digit Serial Number!");
    }

    if (family === 26) {
      this.setState({ activateLoading: true }, async () => {
        try {
          await DevicesAPI.verifyDevice({
            productId: this.state.productId.toUpperCase(),
          });
          this.activate();
          this.setState({ activateLoading: false });
        } catch (err) {
          Alert.error(
            "We could not verify that Serial Number. Please check to make sure it matches the Serial Number on the box exactly."
          );
          this.setState({ activateLoading: false });
        }
      });
    } else if (family === 31) {
      this.setState({ activateLoading: true, selectedPlanId: 46 }, async () => {
        try {
          await DevicesAPI.verifyDevice({
            productId: this.state.productId.toUpperCase(),
          });
          this.activate();
          this.setState({ activateLoading: false });
        } catch (err) {
          Alert.error(
            "We could not verify that Serial Number. Please check to make sure it matches the Serial Number on the box exactly."
          );
          this.setState({ activateLoading: false });
        }
      });
    } else {
      this.setState({ activateLoading: true }, async () => {
        try {
          await DevicesAPI.verifyDevice({
            productId: this.state.productId.toUpperCase(),
          });
          this.setState({ activateModalShown: true, activateLoading: false });
        } catch (err) {
          Alert.error(
            "We could not verify that Serial Number. Please check to make sure it matches the Serial Number on the box exactly."
          );
          this.setState({ activateLoading: false });
        }
      });
    }
  }

  /**
   * Allows user to submit the device activation through use of the enter key.
   * @param {*} e for the event
   */
  activateEnter(e: any) {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      this.activate();
    }
  }

  applyCoupon() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.validateCoupon(
          this.state.couponCode,
          this.state.selectedPlanId
        );
        this.setState({
          loading: false,
          validationMessage:
            "Success! Coupon has been applied and will be reflected upon device activation.",
          validCode: this.state.couponCode,
          couponColor: "#44B74F",
        });
      } catch (err) {
        this.setState({
          loading: false,
          validationMessage:
            "Sorry, this coupon code is not eligible to be used with the selected plan.",
          validCode: "",
        });
      }
    });
  }

  planBody() {
    let plan = null;
    const sId = this.state.selectedPlanId;
    for (let i = 0; i < this.state.plans.length; i++) {
      const tId = parseInt(this.state.plans[i].id, 10);
      if (tId === sId) {
        plan = this.state.plans[i];
      }
    }
    if (plan === null || !plan.id) {
      return null;
    }

    //build the body
    const freeDisplay =
      plan.freeDays === 0 ? null : (
        <li>Includes {plan.freeDays} free days to start!</li>
      );
    return (
      <div>
        <label>Details</label>
        <div
          dangerouslySetInnerHTML={{
            __html: plan.description,
          }}
        ></div>
        {freeDisplay}
      </div>
    );
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <div className="card-new">
          <h6 className="header-6" style={{ marginTop: 2 }}>
            Activate a new product
          </h6>
          <p
            style={{
              fontWeight: 400,
              fontSize: 16,
              fontFamily: "Helvetica Neue",
            }}
          >
            Enter the product data below and proceed to activate your product in
            the Wagz mobile app.
          </p>
          <div className="form-group" style={{ marginTop: 10 }}>
            <input
              id="productId"
              type="text"
              className="form-control"
              style={styles.inputFieldNew}
              placeholder="Serial Number"
              aria-label="The Serial Number of the device"
              aria-labelledby="productIdLabel"
              onChange={this.updateField}
              value={this.state.productId}
            />
            <FontAwesomeIcon
              icon={faQrcode}
              size={"2x"}
              onClick={() => this.showQRModal()}
              className={"text-secondary"}
              style={{ float: "right", marginTop: -45, marginRight: 10 }}
            />
            <label
              id="productIdLabel"
              htmlFor="productId"
              style={{ fontSize: 12, marginLeft: 5, fontFamily: "Helvetica Neue" }}
            >
              This 10-digit number can be found on the side of the box as well
              as on the product itself.
            </label>
          </div>
          <div className="form-group">
            <input
              id="nickname"
              type="text"
              className="form-control"
              placeholder="Nickname"
              aria-label="The nickname you would like to give this device"
              aria-labelledby="nicknameLabel"
              style={styles.inputFieldNew}
              onChange={this.updateField}
              value={this.state.nickname}
              onKeyPress={this.activateEnter}
            />
            <label
              id="nicknameLabel"
              htmlFor="nickname"
              style={{ fontSize: 12, marginLeft: 5, fontFamily: "Helvetica Neue" }}
            >
              This will be used to identify your product on your account.
            </label>
          </div>
          <div className="form-group">
            <HCaptcha
              sitekey={siteKey}
              onVerify={this.verifyCaptcha} />
          </div>
          {this.state.captchaValid ? (
            <div className="form-group">
              <LoadingButton
                aria-label="Begin the set up process"
                loading={this.state.activateLoading}
                className="btn btn-block btn-primary"
                style={styles.buttonNew}
                onClick={this.showActivateModal}
              >
                BEGIN SETUP
              </LoadingButton>
            </div>
          ) : (
            <div className="form-group">
              <button
                aria-label="Begin the set up process"
                className="btn btn-block btn-primary"
                style={styles.buttonNew}
                disabled={true}
              >
                BEGIN SETUP
              </button>
            </div>
          )}

          <Modal
            show={this.state.showQRModal}
            onHide={() => {
              this.setState({ showQRModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Scan QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <QrReader
                  constraints={{
                    facingMode: "environment",
                  }}
                  onResult={(result, _error) => {
                    if (!!result) {
                      const readId = result;
                      this.setState({
                        productId: readId.toString(),
                        showQRModal: false,
                      });
                    }
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <Modal
          dialogClassName="modal-75"
          show={this.state.activateModalShown}
          onHide={() => {
            this.setState({ activateModalShown: false });
          }}
          onEntered={(e: any) => {
            window.location.hash = "warning-modal";
            window.onhashchange = () => {
              if (!window.location.hash) {
                this.setState({ activateModalShown: false });
              }
            };
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>IMPORTANT INFORMATION</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WarningModal modalHeader={this.state.modalHeader} productName={this.state.productName} />
            <div className="text">
              <button
                className="btn btn-block btn-primary"
                style={styles.buttonNew}
                onClick={() => {
                  this.setState({
                    activateModalShown: false,
                    planSelectModalShown: true,
                  });
                }}
              >
                Yes, I agree!
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={this.state.planSelectModalShown}
          onHide={() => {
            this.setState({ planSelectModalShown: false });
          }}
          onEntered={(e: any) => {
            window.location.hash = "warning-modal";
            window.onhashchange = () => {
              if (!window.location.hash) {
                this.setState({ planSelectModalShown: false });
              }
            };
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Set up your subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Selected Payment Plan</label>
              <select
                id="selectedPlanId"
                className="form-control"
                value={this.state.selectedPlanId}
                aria-label="Select your payment plan"
                onChange={(e) => {
                  this.setState({
                    selectedPlanId: parseInt(e.target.value, 10),
                    validCode: "",
                    validationMessage: "",
                    couponColor: "#C70039",
                  });
                }}
              >
                {this.state.plans.map((p: any) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.display}
                    </option>
                  );
                })}
              </select>
            </div>
            <section aria-label="Plan Information">{this.planBody()}</section>
            <section aria-label="Coupon Entry">
              <div className="row" style={{ marginLeft: 10 }}>
                <div className="form-group">
                  <input
                    id="couponCode"
                    type="text"
                    className="form-control"
                    placeholder="Coupon Code"
                    aria-label="The coupon code you want to apply to this subscription"
                    aria-labelledby="couponLabel"
                    style={styles.inputFieldNew}
                    onChange={this.updateField}
                    value={this.state.couponCode}
                  />
                  <label
                    id="couponLabel"
                    htmlFor="coupon"
                    style={{ fontSize: 12, marginLeft: 5 }}
                  >
                    If you have a coupon code, enter it here and click "Apply"
                  </label>
                </div>
                <div style={{ marginLeft: 30, width: 100 }}>
                  <button
                    className="btn btn-block btn-secondary"
                    style={styles.buttonNew}
                    aria-label="Apply this coupon"
                    onClick={() => this.applyCoupon()}
                  >
                    Apply
                  </button>
                </div>
                <div style={{ marginRight: 10 }}>
                  <p style={{ color: this.state.couponColor }}>
                    {this.state.validationMessage}
                  </p>
                </div>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-block btn-primary"
              style={styles.buttonNew}
              aria-label="Select this plan"
              onClick={() => {
                this.setState(
                  { activateModalShown: false, planSelectModalShown: false },
                  () => {
                    this.activate();
                  }
                );
              }}
            >
              Select This Plan
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private verifyCaptcha(token: string, key: string){
    this.setState({ loading: true }, async () => {
      try{
        await UserAPI.checkCaptcha(token);
        this.setState({ loading: false, captchaValid: true });
      }catch(err){
        Alert.error("Invalid captcha response");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(activateDeviceScreen);
