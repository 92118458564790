import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";

import { BillingAPI, UserAPI, AnalyticsAPI } from "../../../API/index";
import * as Alert from "../../structure/Alert";
import moment from "moment";

import LoadingButton from "src/components/structure/LoadingButton";
import Stepper from "src/components/structure/Stepper";
import { styles } from "../../../styles";

interface IUpdatePaymentProps {
  loading: boolean;
  paymentUpdated: any;
  cardTitle: string;
  userActions: any;
  userState: any;
  address: any;
  setup: boolean;
}

interface IUpdatePaymentState {
  loading: boolean;
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cardVerificationCode: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  sameAddress: boolean;
  value: string;
}

class UpdatePaymentMethod extends Component<
  IUpdatePaymentProps,
  IUpdatePaymentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      nameOnCard: "",
      cardNumber: "",
      expirationMonth: moment().format("M"),
      expirationYear: moment().add(1, "year").format("YY"),
      cardVerificationCode: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressState: "",
      addressZip: "",
      sameAddress: false,
      value: "",
    };

    this.updateField = this.updateField.bind(this);
    this.payment = this.payment.bind(this);
    this.handleSameAddress = this.handleSameAddress.bind(this);
  }

  componentDidMount() {
    this.fetchAddress();
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleSameAddress() {
    this.setState({
      sameAddress: !this.state.sameAddress,
      addressCity: !this.state.sameAddress
        ? this.props.address.addressCity
        : "",
      addressLine1: !this.state.sameAddress
        ? this.props.address.addressLine1
        : "",
      addressLine2: !this.state.sameAddress
        ? this.props.address.addressLine2
        : "",
      addressState: !this.state.sameAddress
        ? this.props.address.addressState
        : "",
      addressZip: !this.state.sameAddress ? this.props.address.addressZip : "",
    });
  }

  public async fetchAddress() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.getAddress(
          this.props.userState.user.id,
          {}
        );
        this.setState({
          addressLine1: result.body.data[0].street1,
          addressLine2: result.body.data[0].street2,
          addressCity: result.body.data[0].city,
          addressState: result.body.data[0].state,
          addressZip: result.body.data[0].zip,
          loading: false,
        });
      } catch (err) {
        const errorString = err ? err : "unknown";
        const event = {
          domain: "user_setup_error",
          additionalData: {
              "error": "Error fetching address",
              "type": "payment",
              "more": {
                  "message": errorString,
              }
          }
        };
        await UserAPI.logEvent(this.props.userState.user.id, event);
        this.setState({ loading: false });
      }
    });
  }

  /**
   * Allows user to change their payment method.  Contacts PaymentAPI to update and store payment information with Stripe.
   */
  payment() {
    if (
      this.state.nameOnCard === "" ||
      this.state.cardNumber === "" ||
      this.state.cardVerificationCode === "" ||
      this.state.addressLine1 === "" ||
      this.state.addressCity === "" ||
      this.state.addressState === "" ||
      this.state.addressZip === ""
    ) {
      return Alert.error("You must provide values for all fields!");
    }
    this.setState({ loading: true }, () => {
      (window as any).Stripe.card.createToken(
        {
          number: this.state.cardNumber,
          cvc: this.state.cardVerificationCode,
          exp_month: this.state.expirationMonth,
          exp_year: this.state.expirationYear,
          address_line1: this.state.addressLine1,
          address_line2: this.state.addressLine2,
          address_city: this.state.addressCity,
          address_state: this.state.addressState,
          address_zip: this.state.addressZip,
        },
        async (status: any, response: any) => {
          if (status !== 200) {
            return this.setState({ loading: false }, () => {
              Alert.error(response.error.message);
              return console.log(response.error.message, {});
            });
          } else {
            const extra = {
              paymentToken: response.id,
              name: this.state.nameOnCard,
              expMonth: response.card.exp_month,
              expYear: response.card.exp_year,
              type: response.card.brand,
              lastFour: response.card.last4,
              email: this.props.userState.user.email,
              addressLine1: this.state.addressLine1,
              addressLine2: this.state.addressLine2,
              addressCity: this.state.addressCity,
              addressState: this.state.addressState,
              addressZip: this.state.addressZip,
            };
            const address = {
              street1: this.state.addressLine1,
              street2: this.state.addressLine2,
              city: this.state.addressCity,
              state: this.state.addressState,
              zip: this.state.addressZip,
              addressType: "billing",
            };
            try {
              await UserAPI.createAddress(
                this.props.userState.user.id,
                address
              );
              await BillingAPI.changePaymentMethod(
                this.props.userState.user.id,
                extra.paymentToken,
                extra
              );
              Alert.success("Your payment method has been validated!");
              this.setState({
                ...this.state,
                loading: false,
              });

              await AnalyticsAPI.logEvent("payment_method_changed", {
                userId: this.props.userState.user.id,
                params: {
                  source: "setup.wagz.com",
                  paymentType: extra.type,
                },
              });
              return this.props.paymentUpdated(true, extra);
            } catch (err) {
              return this.setState({ loading: false }, () => {
                Alert.error(
                  "The payment information you just entered was either incorrect or declined by your card issuer. Feel free to contact us if you require more details."
                );
              });
            }
          }
        }
      );
    });
  }

  /**
   * Populates dropdown menu for the month of expiration in the payment form.
   */
  getMonths() {
    let months = [];
    for (let month = 1; month <= 12; month++) {
      if (month < 10) {
        months.push(
          <option value={`${month}`} key={`${month}`}>
            {`0${month}`}
          </option>
        );
      } else {
        months.push(
          <option value={`${month}`} key={`${month}`}>
            {`${month}`}
          </option>
        );
      }
    }
    return months;
  }

  /**
   * Populates dropdown menu for the year of expiration in the payment form.
   */
  getYears() {
    let years = [];
    for (let year = 19; year <= 30; year++) {
      years.push(
        <option value={year} key={year}>
          {`20${year}`}
        </option>
      );
    }
    return years;
  }

  render() {
    return (
      <div>
        <h5 className="header-5" style={{ marginTop: 20 }}>
          Update Payment Information
        </h5>
        <div className="card-new">
          {this.props.setup && <Stepper currentStep={4} />}
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <h6 className="header-6">Credit card number</h6>
                <input
                  id="cardNumber"
                  type="number"
                  className="form-control"
                  placeholder="Enter card number"
                  aria-label="Card Number"
                  style={styles.inputFieldNew}
                  onChange={this.updateField}
                  value={this.state.cardNumber}
                />
              </div>
              <div className="form-group">
                <div
                  className="row"
                  style={{ marginBottom: 15, marginLeft: 1 }}
                >
                  <div style={{ width: "40%" }}>
                    <h6 className="header-6">Expiration</h6>
                    <select
                      className="form-control"
                      style={styles.inputFieldNew}
                      value={this.state.expirationMonth}
                      onChange={this.updateField}
                      id="expirationMonth"
                      aria-label="Expiration Month"
                      aria-labelledby="expirationMonthLabel"
                    >
                      {this.getMonths()}
                    </select>
                  </div>
                  <div style={{ marginLeft: "15%", width: "40%" }}>
                    <label
                      htmlFor="expiration"
                      id="expirationLabel"
                      style={{
                        fontFamily: "Helvetica Neue",
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#ffffff",
                      }}
                    >
                      Expiration
                    </label>
                    <select
                      className="form-control"
                      style={styles.inputFieldNew}
                      value={this.state.expirationYear}
                      onChange={this.updateField}
                      id="expirationYear"
                      aria-label="Expiration Year"
                      aria-labelledby="expirationYearLabel"
                    >
                      {this.getYears()}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    id="nameOnCard"
                    type="text"
                    className="form-control"
                    placeholder="Name on card"
                    aria-label="Name on Card"
                    style={styles.inputFieldNew}
                    onChange={this.updateField}
                    value={this.state.nameOnCard}
                  />
                </div>
                <div className="form-group" style={{ width: "40%" }}>
                  <input
                    id="cardVerificationCode"
                    type="int"
                    className="form-control"
                    style={styles.inputFieldNew}
                    placeholder="Enter CVC"
                    onChange={this.updateField}
                    value={this.state.cardVerificationCode}
                    aria-label="The CVC of the Card"
                    aria-labelledby="cardVerificationCodeLabel"
                  />
                </div>
                <div className="form-group" style={{ marginTop: 10 }}>
                  <h6 className="header-6">Billing Address</h6>
                  <div>
                    {this.props.setup && (
                      <div
                        className="row"
                        style={{ marginTop: 12, marginLeft: 12 }}
                      >
                        <input
                          id="sameAddress"
                          type="checkbox"
                          checked={this.state.sameAddress}
                          onChange={this.handleSameAddress}
                          size={12}
                          aria-label="Is your billing address the same as your home address?"
                          aria-labelledby="sameAddressLabel"
                        />
                        <label
                          style={styles.sameAddress}
                          htmlFor="sameAddress"
                          id="sameAddressLabel"
                        >
                          Same as Home Address
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <input
                    id="addressLine1"
                    type="text"
                    className="form-control"
                    placeholder="Street Address"
                    style={styles.inputFieldNew}
                    onChange={this.updateField}
                    value={this.state.addressLine1}
                    aria-label="Line 1 of your address"
                  />
                </div>
                <div className="form-group">
                  <input
                    id="addressLine2"
                    type="text"
                    className="form-control"
                    placeholder="Street Address (cont.)"
                    style={styles.inputFieldNew}
                    onChange={this.updateField}
                    value={this.state.addressLine2}
                    aria-label="An optional line 2 of your address"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  id="addressCity"
                  type="text"
                  className="form-control"
                  style={styles.inputFieldNew}
                  onChange={this.updateField}
                  placeholder="City"
                  value={this.state.addressCity}
                  aria-label="Your city"
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  style={styles.inputFieldNew}
                  onChange={this.updateField}
                  id="addressState"
                  type="text"
                  maxLength={2}
                  autoCapitalize="yes"
                  placeholder="State"
                  value={this.state.addressState}
                  aria-label="Your state"
                />
              </div>
              <div className="form-group">
                <input
                  id="addressZip"
                  type="text"
                  className="form-control"
                  style={styles.inputFieldNew}
                  placeholder="Zip Code"
                  onChange={this.updateField}
                  value={this.state.addressZip}
                  aria-label="Your zipcode"
                />
              </div>
            <div className="form-group">
              <LoadingButton
                aria-label="Update payment method"
                loading={this.state.loading}
                className="btn btn-block btn-primary"
                style={styles.buttonNew}
                onClick={this.payment}
              >
                    UPDATE PAYMENT INFO
                  </LoadingButton>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePaymentMethod);
