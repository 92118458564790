import { makeCall } from "./client";

export class AnalyticsAPI {

  /**
   * Log a new event
   * @param event 
   * @param eventInfo 
   * @returns 
   */
  public logEvent = (event: string, eventInfo: IAnalyticEvent) => {
    const data = {
      ...eventInfo,
      event
    };
    return makeCall('post', `an/events`, data);
  }

  /**
   * Log a device's information for a user
   * @param userId 
   * @param deviceInfo 
   * @returns 
   */
  public logDevice = (userId: number, deviceInfo: IAnalyticDevice) => {
    const data = {
      ...deviceInfo,
      userId
    };
    return makeCall('post', `an/devices`, data);
  }

  /**
   * Log a location for a user
   * @param userId 
   * @param locationInfo 
   * @returns 
   */
  public logLocation = (userId: number, locationInfo: IAnalyticLocation) => {
    const data = {
      ...locationInfo,
      userId
    };

    return makeCall('post', `an/locations`, data);
  }
  
}


export interface IAnalyticEvent {
  deviceId?: number;
  event?: string;
  id?: number;
  params?: any;
  petId?: number;
  platform?: "wagz2" | "chd";
  posted?: string;
  userId: number;
}

export interface IAnalyticDevice {
  browser?: string;
  browserVersion?: string;
  deviceType?: string;
  manufacturer?: string;
  model?: string;
  os?: string;
  osVersion?: string;
  params?: any;
  platform?: "wagz2" | "chd";
  posted?: string;
  screenHeight?: number;
  screenWidth?: number;
  userId?: number;
}

export interface IAnalyticLocation {
  city: string;
  country: string;
  id: number;
  lat: number;
  lng: number;
  params: any;
  platform: "wagz2" | "chd";
  posted: string;
  state: string;
  userId: number;
  zip: string;
}