/**
 * Format takes in a number (such as pennies) and converts it to a displayable currency in USD
 * @param input
 */
export const formatCurrency = (
  input: number | string,
  hideSymbol: boolean = false
): string => {
  if (typeof input === "string") {
    input = parseInt(input, 10);

    if (isNaN(input)) {
      return "";
    }
  }
  input = input / 100;
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(input);
  if (hideSymbol) {
    return formatted.substr(1);
  }
  return formatted;
};
