import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import Stepper from "src/components/structure/Stepper";
import { UserAPI } from "src/API/index";
import * as Alert from "src/components/structure/Alert";
import { handlePhone } from "src/utils/utilities";
import warning_Gold_24dp from "src/img/warning_Gold_24dp.svg";
import { styles } from "src/styles";

interface IUserInfoStepProps {
  userActions: any;
  userState: any;
  user: any;
  isMobile: boolean;
}

interface IUserInfoStepState {
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
}

export class UserInfoStep extends Component<
  IUserInfoStepProps,
  IUserInfoStepState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressState: "",
      addressZip: "",
    };

    this.navigateAway = this.navigateAway.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  componentDidMount() {
    this.fetchInfo();
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  navigateAway() {
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.email === "" ||
      this.state.phoneNumber === "" ||
      this.state.addressLine1 === "" ||
      this.state.addressCity === "" ||
      this.state.addressState === "" ||
      this.state.addressZip === ""
    ) {
      return Alert.error("You must provide values for all fields!");
    } else {
      const address = {
        street1: this.state.addressLine1,
        street2: this.state.addressLine2,
        city: this.state.addressCity,
        state: this.state.addressState,
        zip: this.state.addressZip,
        addressType: "mailing",
      };
      this.setState({ loading: true }, async () => {
        try {
          await UserAPI.createAddress(this.props.userState.user.id, address);
          const phone = {
            mobilePhone: this.state.phoneNumber,
          };
          await UserAPI.updateProfile(this.props.userState.user.id, phone);
          await this.props.user(2, this.state);
          this.setState({ loading: false });
        } catch (err) {
          const errorString = err ? err : "unknown";
          const event = {
            domain: "user_setup_error",
            additionalData: {
                "error": "Error creating profile",
                "type": "user",
                "more": {
                    "message": errorString,
                }
            }
          };
          await UserAPI.logEvent(this.props.userState.user.id, event);
          this.setState({ loading: false });
          return Alert.error("You must provide valid values for all fields!");
        }
      });
    }
  }

  public async fetchInfo() {
    try {
      const result = await UserAPI.getProfile(this.props.userState.user.id, {});
      const profile = result.body.data;
      let phone = "";
      if (profile.mobilePhone && profile.mobilePhone !== "") {
        phone = profile.mobilePhone;
      }
      // fall back to home phone
      if (phone === "" && profile.homePhone && profile.homePhone !== "") {
        phone = profile.homePhone;
      }
      const addresses = await UserAPI.getAddress(result.body.data.id);
      let address: any = {};
      for (const a of addresses.body.data) {
        if (a.addressType === "mailing") {
          address = a;
        }
      }
      this.setState({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phoneNumber: phone,
        addressLine1: address.street1 ? address.street1 : "",
        addressLine2: address.street2 ? address.street2 : "",
        addressCity: address.city ? address.city : "",
        addressState: address.state ? address.state : "",
        addressZip: address.zip ? address.zip : "",
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div style={styles.itemAlign}>
            <div style={{ marginTop: 20, width: 380 }}>
            { this.props.isMobile && (
              <p style={styles.signupHeader}>Enter profile info</p>
            )}
              <div className="card-warning">
                <div className="row">
                  <div
                    style={{ width: "15%", textAlign: "center", marginTop: 15 }}
                  >
                    <img src={warning_Gold_24dp} alt="..." />
                  </div>
                  <div style={{ width: "80%" }}>
                    <p style={styles.bottomParagraphWarning}>
                      Please do not click the Back button on your browser or
                      exit this process prior to completing setup.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-new">
            <Stepper currentStep={1} />
                <p style={styles.bottomParagraphWarning}>
                  As part of the Wagz family, you and your pet now may enjoy
                  off-leash freedom with on-leash security
                </p>
                <p style={styles.newToWagz}>
                  <strong>Personal Info</strong>
                </p>
                <div className="form-group">
                  <input
                    id="firstName"
                    type="text"
                    placeholder="First name"
                    aria-label="First Name"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.firstName}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="lastName"
                    type="text"
                    placeholder="Last name"
                    aria-label="Last Name"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.lastName}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    aria-label="Email Address"
                    aria-required={true}
                    disabled={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.email}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="phoneNumber"
                    type="int"
                    placeholder="Phone number"
                    aria-label="Phone Number"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={handlePhone(this.state.phoneNumber)}
                    onChange={this.updateField}
                  />
                </div>
                <p style={styles.newToWagz}>
                  <strong>Home Address</strong>
                </p>
                <div className="form-group">
                  <input
                    id="addressLine1"
                    type="text"
                    placeholder="Street address"
                    aria-label="Street Address"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.addressLine1}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="addressLine2"
                    type="text"
                    placeholder="Street address (cont.)"
                    aria-label="Street Address Continued"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.addressLine2}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="addressCity"
                    type="text"
                    placeholder="City"
                    aria-label="City"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.addressCity}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="addressState"
                    type="text"
                    placeholder="State"
                    aria-label="State"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    maxLength={2}
                    autoCapitalize="yes"
                    className="form-control"
                    value={this.state.addressState}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="addressZip"
                    type="text"
                    placeholder="Zip code"
                    aria-label="Zip Code"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.addressZip}
                    onChange={this.updateField}
                  />
                </div>
                <div style={{ marginTop: 30 }}>
                  <button
                    style={styles.buttonNew}
                    aria-label="Continue"
                    className="btn btn-block btn-primary"
                    onClick={this.navigateAway}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoStep);
