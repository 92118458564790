import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "url-search-params-polyfill";
import { Container, Row, Col } from "react-bootstrap";
import * as Alert from "../../structure/Alert";
import * as UserActions from "../../../reducers/userReducer";
import Mailto from "src/components/structure/comp/MailTo";
import { UserAPI } from "../../../API";
import { bindActionCreators } from "redux";
import { styles } from "../../../styles";
import Screen from "src/components/structure/Screen";

interface IAccountDeletionProps {
  location: any;
  userActions: any;
  userState: any;
  history: any;
  header?: any;
}

interface IAccountDeletionState {
  canDelete: boolean;
  loading: boolean;
}

class AccountDeletion extends Component<
  IAccountDeletionProps,
  IAccountDeletionState
> {
  constructor(props: IAccountDeletionProps) {
    super(props);

    this.state = {
      canDelete: false,
      loading: false
    };

    this.accountCheck = this.accountCheck.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.accountCheck();
  }

  public logout() {
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/signup");
  }

  private accountCheck() {
    this.setState({ loading: true }, async () => {
      try {
        let result = await UserAPI.userChecks(this.props.userState.user.id);
        this.setState({ canDelete: result.body.data.canDelete, loading: false });
      } catch (error) {
        console.log(error);
        this.setState({ loading: false });
      }
    });
  }

  private handleDelete() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.deleteAccount(this.props.userState.user.id);
        Alert.success("Deleted. Goodbye!");
        this.setState({ loading: false });
        this.logout();
      } catch (error) {
        Alert.error("Error with deletion. Please contact support");
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <Screen>
        <div>
          {!this.state.canDelete && !this.state.loading && (
            <div style={styles.itemAlign}>
              <div className="col-sm-12 col-lg-5">
                <div className="card-new-no-border">
                  <p className="header-delete">
                    Manage Subscription or Delete Account
                  </p>
                  <p className="text-delete">
                    Please contact support to update your subscription or delete
                    your account. You can reach us at 1-844-252-3140 or by
                    emailing{" "}
                    <Mailto
                      label="support@wagz.com"
                      mailto="mailto:support@wagz.com"
                    />
                    .
                  </p>
                  <p className="text-delete">
                    Our support hours are Monday through Friday 9AM - 8PM(EST)
                    and Saturday and Sunday 9AM – 3PM (EST).
                  </p>
                </div>
              </div>
            </div>
          )}
          {this.state.canDelete && !this.state.loading && (
            <div style={styles.itemAlign}>
              <div className="col-sm-12 col-lg-5">
                <div className="card-new-no-border">
                  <h5 className="header-delete">Delete Account</h5>
                  <p className="text-delete">
                    Are you sure you want to delete your Wagz account?
                  </p>
                  <Container>
                    <Row>
                      <Col className="column-delete-right">
                        <button
                          style={styles.buttonNew}
                          aria-label="Process Deletion"
                          className="btn btn-block btn-primary"
                          onClick={this.handleDelete}
                        >
                          DELETE
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          )}
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountDeletion) as any
);
