import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";
import * as Alert from "../../structure/Alert";
import LoadingButton from "src/components/structure/LoadingButton";
import { UserAPI } from "../../../API";
import { styles } from "../../../styles";

interface EmailPreferencesProps {
  userId: number;
}

interface EmailPreferencesState {
  loading: boolean;
  receiveAccountUpdates: "yes" | "no";
  receiveMarketing: "yes" | "no";
  marketingChecked: boolean;
  receiveOther: "yes" | "no";
  receiveTips: "yes" | "no";
  receiveTransactional: "yes" | "no";
}

export class EmailPreferences extends Component<
  EmailPreferencesProps,
  EmailPreferencesState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      receiveAccountUpdates: "yes",
      receiveMarketing: "yes",
      marketingChecked: false,
      receiveOther: "yes",
      receiveTips: "yes",
      receiveTransactional: "yes",
      loading: false,
    };

    this.getPreferences = this.getPreferences.bind(this);
    this.updateField = this.updateField.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
  }

  componentDidMount() {
    this.getPreferences();
  }

  render() {
    return (
      <div>
        <h5 className="header-5" style={{ marginTop: 20 }}>
          Email preferences
        </h5>
        <div className="card-new">
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            <div>
              <label
                id="receiveAccountUpdatesLabel"
                htmlFor="receiveAccountUpdates"
                className="main-text"
              >
                1. Receive Wagz account info and updates
              </label>
            </div>
            <div onChange={() => this.updateField}>
              <input type="radio" value="yes" name="receiveAccountUpdates" checked={this.state.receiveAccountUpdates === 'yes'} onChange={() => this.handleAccountUpdatesChange("yes")}/>
              <span className="radio-text">Yes</span>
            </div>
            <div style={{ marginTop: 10 }} onChange={() => this.updateField}>
              <input type="radio" value="no" name="receiveAccountUpdates" checked={this.state.receiveAccountUpdates === 'no'} onChange={() => this.handleAccountUpdatesChange("no")}/>
              <span className="radio-text">No</span>
            </div>
          </div>

          <div style={{ marginTop: 10, marginBottom: 20 }}>
            <div>
              <label
                id="receiveTipsLabel"
                htmlFor="receiveTips"
                className="main-text"
              >
                2. Receive product tips and tricks
              </label>
            </div>
            <div>
              <div>
                <input type="radio" value="yes" name="receiveTips" checked={this.state.receiveTips === 'yes'} onChange={() => this.handleTipsChange("yes")}/>
                <span className="radio-text">Yes</span>
              </div>
              <div style={{ marginTop: 10 }}>
                <input type="radio" value="no" name="receiveTips" checked={this.state.receiveTips === 'no'} onChange={() => this.handleTipsChange("no")}/>
                <span className="radio-text">No</span>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 10, marginBottom: 20 }}>
            <div>
              <label
                id="receiveAccountUpdatesLabel"
                htmlFor="receiveAccountUpdates"
                className="main-text"
              >
                3. Receive marketing, promotions, and new product info
              </label>
            </div>
            <div>
              <div >
                <input type="radio" value="yes" name="receiveMarketing" checked={this.state.receiveMarketing === 'yes'} onChange={() => this.handleMarketingChange("yes")}/>
                <span className="radio-text">Yes</span>
              </div>
              <div style={{ marginTop: 10 }}>
                <input type="radio" value="no" name="receiveMarketing" checked={this.state.receiveMarketing === 'no'} onChange={() => this.handleMarketingChange("no")} />
                <span className="radio-text">No</span>
              </div>
            </div>
          </div>
            <div className="form-group">
              <LoadingButton
                aria-label="Update Email Preferences"
                loading={this.state.loading}
                className="btn btn-block btn-primary"
                style={styles.buttonNew}
                onClick={this.submitChanges}
              >
                UPDATE EMAIL PREFERENCES
              </LoadingButton>
            </div>
        </div>
      </div>
    );
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  handleAccountUpdatesChange(input: any) {
    this.setState({
      receiveAccountUpdates: input,
    });
  }

  handleTipsChange(input: any) {
    this.setState({
      receiveTips: input,
    });
  }

  handleMarketingChange(input: any) {
    this.setState({
      receiveMarketing: input,
    });
  }

  /**
   * Called through ComponentDidMount, displaying the email of the logged in user.
   */
  getPreferences() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.getEmailPreferences(this.props.userId);

        this.setState({ ...result.body.data, loading: false });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  submitChanges() {
    this.setState({ loading: true }, async () => {
      try {
        const body = {
          ...this.state,
        };
        await UserAPI.saveEmailPreferences(this.props.userId, body);
        this.setState({ loading: false });
        Alert.success("Email preferences saved!");
      } catch (err) {
        this.setState({ loading: false });
        Alert.error(
          "Email preferences could not be saved. Please contact support."
        );
      }
    });
  }

  toggleHelpPopup(source: string) {}
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreferences);
