import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as UserActions from "src/reducers/userReducer";
import * as AppActions from "src/reducers/appReducer";

interface IStepperDotState {
  bgColor: string;
  textColor: string;
  url: string;
}

interface IStepperDotProps {
  id: number;
  appState: any;
  currentStep: number;
  step: number;
}

class StepperDot extends React.Component<IStepperDotProps, IStepperDotState> {
  constructor(props: any) {
    super(props);
    this.state = {
      bgColor: "#2d9ad7",
      textColor: "#fff",
      url: ""
    };
  }

  componentDidMount() {
    this.decideColor();
  }

  decideColor() {
    if (this.props.step !== this.props.currentStep) {
      this.setState({ bgColor: "#e7e7e7", textColor: "#2d9ad7" });
    }
  }

  public render() {
    return (
      <div style={{ borderRadius: "50%", width: "34px", height: "34px", background: this.state.bgColor, border: `3px solid ${this.state.bgColor}`, color: this.state.textColor, textAlign: "center", font: "25px Arial, sans-serif" }}>{this.props.step}</div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(StepperDot);
