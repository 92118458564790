import { makeCall } from "./client";

export interface IEmailPreferences {
  userId: number
  email: string
  receiveAccountUpdates: "yes" | "no"
  receiveMarketing: "yes" | "no"
  receiveOther: "yes" | "no"
  receiveTips: "yes" | "no"
  receiveTransactional: "yes" | "no"
}

export class UserAPI {

  /**
   * Attempts to login a user using the OAuth path as a third party client, such as with Amazon Alexa
   * @param email 
   * @param password 
   * @param clientId 
   * @param scopes 
   */
  public attemptOAuthLogin = (email: string, password: string, clientId: string, scopes: any) => {
    return makeCall("POST", "users/login", {email, password, clientId, scopes}, {});
  }
  
  public login = (email: string, password: string, options: any = {}) => {
    const data = {
      email,
      password,
      ...options
    };
    return makeCall('POST', 'users/login', data);
  }
  
  public register = (email: string, password: string, options: any = {}) => {
    const data = {
      email,
      password,
      ...options
    };
    return makeCall('post', 'users/signup', data);
  }
  
  public verifyToken = (email: string, token: string, options: any = {}) => {
    const data = {
      email,
      token,
      ...options
    };
    return makeCall('post', 'users/verify', data);
  }

  public validatePassword = (password: string, options: any = {}) => {
    const data = {
      password,
      ...options
    };
    return makeCall('post', 'users/passwords/validate', data);
  }
  
  public startPasswordReset = (email: string, options: any = {}) => {
    const data = {
      ...options,
      email
    };
    return makeCall('post', 'users/reset', data);
  }
  
  public finishPasswordReset = (email: string, password: string, token: string, options: any = {}) => {
    const data = {
      ...options,
      email,
      password,
      token
    };
    return makeCall('post', '/users/reset/verify', data);
  }
  
  public getProfile = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('get', `users/${id}`, data);
  }
  
  public updateProfile = (id: number, updateData: any = {}) => {
    const data = {
      ...updateData
    };
    return makeCall('patch', `users/${id}`, data);
  }
  
  public getProfileImage = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('get', `users/${id}/images/profile`, data);
  }
  
  public getAddress = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('get', `users/${id}/addresses`, data);
  }
  
  public createAddress = (id: number, updateData: any = {}) => {
    const data = {
      ...updateData
    };
    return makeCall('post', `users/${id}/addresses`, data);
  }
  
  public userChecks = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('get', `users/${id}/checks`, data);
  }
  
  public deleteAccount = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('delete', `users/${id}`, data);
  }
  
  /**
   * Gets the email preferences
   * @param id 
   * @param queryData 
   * @returns 
   */
  public getEmailPreferences = (id: number, queryData: any = {}) => {
    return makeCall('get', `users/${id}/email/preferences`, queryData);
  }
  
  /**
   * Saves the email preferences
   * @param id 
   * @param preferences 
   * @returns 
   */
  public saveEmailPreferences = (id: number, preferences: any = {}) => {
    return makeCall('post', `users/${id}/email/preferences`, preferences);
  }

  /**
   * Unsubscribes from email. The data should contain at least an email, preferably a userId
   * @param data 
   * @returns 
   */
  public unsubscribeEmail = (data: any = {}) => {
    return makeCall('delete', `email/unsubscribe`, data);
  }
  
  public logEvent = (id: number, eventInfo: any = {}) => {
    const data = {
      ...eventInfo
    };
    return makeCall('post', `users/${id}/events`, data);
  }

  public getUserOTP = (id: number,options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('get', `users/${id}/otp`, data);
  }

  public getUserCodes = (id: number,options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('get', `users/${id}/codes`, data);
  }

  public startOTPEnrollment = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('post', `users/${id}/otp/enroll`, data);
  }

  public verifyOTP = (id: number, passcode: string, options: any = {}) => {
    const data = {
      ...options,
      passcode
    };
    return makeCall('post', `users/${id}/otp/verify`, data);
  }

  public removeOTP = (id: number, method: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('delete', `users/${id}/otp/${method}`, data);
  }
  
  public checkCaptcha = (token: string, ip: string = "") => {
    const data: any = {
      token,
    };
    if(ip && ip !== ""){
      data.ip = ip;
    }
    return makeCall('post', `users/captcha/verify`, data);
  }
  
}
