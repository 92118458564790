import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";

interface ILoadingButtonState {
}

interface ILoadingButtonProps {
  loading: boolean;
  children?: any;
  style?: any;
  disabled?: any;
  className: string;
  onClick: any;
}


class LoadingButton extends React.Component<ILoadingButtonProps, ILoadingButtonState> {
  constructor(props: any){
    super(props);
    this.state = {};
  }


  public render(){
    return (
      <button 
        style={this.props.style !== null ? this.props.style: {}} 
        disabled={this.props.disabled !== null ? this.props.disabled: {}} 
        className={this.props.className ? this.props.className : ""} 
        onClick={this.props.onClick ? this.props.onClick : () => {}}>
          {this.props.loading ? 
            (<div style={{textAlign: "center"}}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>) : (this.props.children)
          }
      </button>
    )
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(LoadingButton);