import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";
import { styles } from "../../../styles";

interface SafetyScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface SafetyScreenState {
  exit: boolean;
}

export class Safety extends Component<SafetyScreenProps, SafetyScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      exit: false,
    };
    this.handleExit = this.handleExit.bind(this);
  }

  handleExit() {
    this.setState({ exit: true });
  }

  render() {
    if (!this.state.exit) {
      return (
        <div style={{ marginBottom: 50 }}>
          <Card title="Safety Warnings">
            <div className="text">
              <div style={{ textAlign: "center", textDecoration: "underline", fontSize: 22, fontWeight: 300, paddingBottom: 20 }}>
                <strong>
                Wagz Freedom Safety and Wellness Dog Collar Safety Warnings
                </strong>
              </div>
              <div style={{ textAlign: "center" }}>
                <strong style={{ color: "red" }}>
                  WARNING! READ ALL SAFETY WARNINGS AND INSTRUCTIONS. FAILURE TO
                  FOLLOW THE WARNINGS AND INSTRUCTIONS MAY RESULT IN INJURY.
                </strong>
              </div>
              <br />

              <p>
                WARNING! Do not dismantle or recreate the Wagz Freedom™ collar
                as it will void your warranty.
              </p>
              <p>
                WARNING! The Wagz Freedom collar should only be charged with the
                charger provided in the box.
              </p>
              <p>
                WARNING! The Wagz Freedom collar is not rated to act as a
                primary pet restraint device. Make sure your pet is properly
                secured and safe at all times.
              </p>
              <p>
                WARNING! The Wagz Freedom collar has electronics that are
                sensitive to extreme temperatures. Do not leave the collar in
                high temperatures for extended periods of time.
              </p>
              <p>
                CAUTION: The Wagz Freedom collar is intended for pets only and
                should not be used with humans as a toy.
              </p>
              <p>
                CAUTION: The Wagz Freedom collar is not a medical device and it
                is not intended to diagnose, treat, cure, or prevent any
                disease. The Wagz Freedom collar is not a replacement for
                routine care by a licensed veterinarian.
              </p>
              <p>
                CAUTION: The Wagz Freedom collar GPS data and accuracy may vary
                based upon location and is not guaranteed for indoor use.
              </p>
              <p>
                CAUTION: The Wagz Freedom collar is IP67 level dust and water
                resistant. No harmful dust or dirt will seep into the unit after
                being in direct contact with the matter for eight hours. The
                Wagz Freedom collar can handle spills and splashes of water,
                sweat and rain. It can be fully submerged in a body of water up
                to a meter deep (approximately 3 feet) for half an hour.
              </p>
              <p>
                NOTICE: To clean the Wagz Freedom collar use a damp cloth and
                mild soap.
              </p>
              <p>
                NOTICE: Operation of the Wagz Freedom collar is dependent on use
                of the mobile app, as well as the collar having adequate
                cellular network signal. The performance of the Wagz Freedom
                collar may vary depending on the cellular network signal
                strength in a given location.
              </p>
              <p>
                NOTICE: Not every pet can be trained to avoid the established
                boundary. Sometimes, even a properly trained pet may cross the
                boundary. Therefore, neither Wagz, Inc., nor its distributors
                and dealers can guarantee the system will, in all cases, keep a
                customer’s pet within the established boundary.
              </p>
              <p>
                Accordingly, if a customer has reason to believe that their pet
                may pose a danger to others, or harm itself, the customer should
                not rely solely upon the Wagz Freedom pet containment system to
                keep the pet from crossing the boundary.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="text" style={{ width: "25%" }}>
                <button
                  className="btn btn-block btn-primary"
                  style={styles.button}
                  onClick={this.handleExit}
                >
                  Exit
                </button>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    return <Redirect to="/" />;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Safety);
