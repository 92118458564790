import React, { Component } from "react";
import { QrReader } from "react-qr-reader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import HCaptcha from "@hcaptcha/react-hcaptcha";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import { DevicesAPI, BillingAPI, UserAPI } from "src/API/index";

import Stepper from "src/components/structure/Stepper";
import * as Alert from "src/components/structure/Alert";
import warning_Gold_24dp from "src/img/warning_Gold_24dp.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { styles } from "src/styles";
import StepperDot from "src/components/structure/comp/StepperDot";
import StepperDash from "src/components/structure/comp/StepperDash";

interface IDeviceInfoStepProps {
  userActions: any;
  userState: any;
  device: any;
  isMobile: boolean;
}

interface IDeviceInfoStepState {
  loading: boolean;
  productId: string;
  nickname: string;
  step: number;
  plans: any[];
  selectedPlanId: number;
  couponCode: string;
  validCode: string;
  validationMessage: string;
  couponColor: string;
  showQRModal: boolean;
  captchaValid: boolean;
}

const siteKey = process.env.REACT_APP_CAPTCHA_SITEKEY || "";

export class DeviceInfoStep extends Component<
  IDeviceInfoStepProps,
  IDeviceInfoStepState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      productId: "",
      nickname: "",
      step: 1,
      plans: [],
      selectedPlanId: 0,
      couponCode: "",
      validCode: "",
      validationMessage: "",
      couponColor: "#C70039",
      showQRModal: false,
      captchaValid: false,
    };

    this.navigateAway = this.navigateAway.bind(this);
    this.noBillingNeeded = this.noBillingNeeded.bind(this);
    this.updateField = this.updateField.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.goToPlans = this.goToPlans.bind(this);
    this.showQRModal = this.showQRModal.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  componentWillMount() {
    this.getPlans();
  }

  getPlans() {
    this.setState({ loading: true }, async () => {
      try {
        const productFamily = this.state.productId.substring(4, 6);
        const plans = await DevicesAPI.getPlans(parseInt(productFamily), {
          showPlan: true,
        });
        let filteredPlans = [];
        for (const p of plans.body.data) {
          // if (p.showPlan && p.id !== 40 && p.id !== 45) {
          //   filteredPlans.push(p);
          // }
          if (p.showPlan) {
            filteredPlans.push(p);
          }
        }
        this.setState({
          plans: filteredPlans.reverse(),
          selectedPlanId: filteredPlans[0].id,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  navigateAway() {
    this.setState({ loading: true }, async () => {
      await this.props.device(4, this.state);
      this.setState({ loading: false });
    });
  }

  noBillingNeeded() {
    this.setState({ loading: true }, async () => {
      await this.props.device(7, this.state);
      this.setState({ loading: false });
    });
  }

  goToPlans() {
    this.getPlans();
    const lead = parseInt(this.state.productId.substring(0, 2));
    const family = parseInt(this.state.productId.substring(4, 6));
    if (lead < 19 || this.state.productId.length !== 10) {
      return Alert.error("You must provide a valid 10-digit Serial Number!");
    }
    if (this.state.nickname === "") {
      return Alert.error("You must provide a value for the 'Nickname' field!");
    }

    if (family === 26 || family === 31) {
      // check the verification
      this.setState({ loading: true }, async () => {
        try {
          await DevicesAPI.verifyDevice({
            productId: this.state.productId.toUpperCase(),
          });
          this.noBillingNeeded();
          this.setState({ loading: false });
        } catch (err) {
          const errorString = err ? err : "unknown";
          const event = {
            domain: "user_setup_error",
            additionalData: {
                "error": `Error verifying device (family ${family})`,
                "type": "device",
                "more": {
                    "message": errorString,
                }
            }
          };
          await UserAPI.logEvent(this.props.userState.user.id, event);
          Alert.error(
            "We could not verify that Serial Number. Please check to make sure it matches the Serial Number on the box exactly."
          );
          this.setState({ loading: false });
        }
      });
    } else {
      // check the verification
      this.setState({ loading: true }, async () => {
        try {
          await DevicesAPI.verifyDevice({
            productId: this.state.productId.toUpperCase(),
          });
          this.setState({ step: 2, loading: false });
        } catch (err) {
          const errorString = err ? err : "unknown";
          const event = {
            domain: "user_setup_error",
            additionalData: {
                "error": "Error verifying device",
                "type": "device",
                "more": {
                    "message": errorString,
                }
            }
          };
          await UserAPI.logEvent(this.props.userState.user.id, event);
          Alert.error(
            "We could not verify that Serial Number. Please check to make sure it matches the Serial Number on the box exactly."
          );
          this.setState({ loading: false });
        }
      });
    }
  }

  planBody() {
    let plan = null;
    const sId = this.state.selectedPlanId;
    for (let i = 0; i < this.state.plans.length; i++) {
      const tId = parseInt(this.state.plans[i].id, 10);
      if (tId === sId) {
        plan = this.state.plans[i];
      }
    }
    if (plan === null || !plan.id) {
      return null;
    }

    //build the body
    const freeDisplay =
      plan.freeDays === 0 ? null : (
        <li>Includes {plan.freeDays} free days to start!</li>
      );
    return (
      <div>
        <label>Details</label>
        <div
          dangerouslySetInnerHTML={{
            __html: plan.description,
          }}
        ></div>
        {freeDisplay}
      </div>
    );
  }

  applyCoupon() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.validateCoupon(
          this.state.couponCode,
          this.state.selectedPlanId
        );
        this.setState({
          loading: false,
          validationMessage:
            "Success! Coupon has been applied and will be reflected upon device activation.",
          validCode: this.state.couponCode,
          couponColor: "#44B74F",
        });
      } catch (err) {
        this.setState({
          loading: false,
          validationMessage:
            "Sorry, this coupon code is not eligible to be used with the selected plan.",
          validCode: "",
        });
      }
    });
  }

  get step() {
    if (this.state.step === 1) {
      return (
        <div className="row">
          <div style={styles.itemAlign}>
            <div style={{ marginTop: 20, marginBottom: 20, width: 380 }}>
              {this.props.isMobile && (
                <p style={styles.signupHeader}>Enter product serial number</p>
              )}
              <div className="card-warning">
                <div className="row">
                  <div
                    style={{
                      width: "15%",
                      textAlign: "center",
                      marginTop: 15,
                    }}
                  >
                    <img src={warning_Gold_24dp} alt="..." />
                  </div>
                  <div style={{ width: "80%" }}>
                    <p style={styles.bottomParagraphWarning}>
                      Please do not click the Back button on your browser or
                      exit this process prior to completing setup.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-new">
                <Stepper currentStep={2} />
                <p style={styles.newToWagz}>
                  <strong>Enter 10 digit serial number</strong>
                </p>
                <p style={styles.bottomParagraphWarning}>
                  The 10-digit number can be found on the side of the box as
                  well as on the product itself.
                </p>
                <p style={styles.bottomParagraphWarning}>
                  You may enter the number manually or by tapping the icon to
                  scan it in.
                </p>
                <div className="form-group">
                  <input
                    id="productId"
                    type="text"
                    placeholder="Enter serial number"
                    aria-label="Enter Serial Number"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.productId}
                    onChange={this.updateField}
                  />
                  <FontAwesomeIcon
                    icon={faQrcode}
                    size={"2x"}
                    onClick={() => this.showQRModal()}
                    className={"text-secondary"}
                    style={{ float: "right", marginTop: -45, marginRight: 10 }}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="nickname"
                    type="text"
                    placeholder="Nickname for device"
                    aria-label="Nickname For Device"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.nickname}
                    onChange={this.updateField}
                  />
                </div>
                
                <div className="form-group">
                  <HCaptcha
                    sitekey={siteKey}
                    onVerify={this.verifyCaptcha} />
                </div>

                {this.state.captchaValid ? (
                  <div style={{ marginTop: 30 }}>
                    <button
                      style={styles.buttonNew}
                      aria-label="Continue"
                      className="btn btn-block btn-primary"
                      onClick={this.goToPlans}
                    >
                      CONTINUE
                    </button>
                  </div>
                ) : (
                  <div style={{ marginTop: 30 }}>
                    <button
                      style={styles.buttonNew}
                      aria-label="Continue"
                      className="btn btn-block btn-primary"
                      disabled={true}
                    >
                      CONTINUE
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={this.state.showQRModal}
            onHide={() => {
              this.setState({ showQRModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Scan QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <QrReader
                  constraints={{
                    facingMode: "environment",
                  }}
                  onResult={(result, _error) => {
                    if (!!result) {
                      const readId = result;
                      this.setState({
                        productId: readId.toString(),
                        showQRModal: false,
                      });
                    }
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div className="row">
          <div style={styles.itemAlign}>
            <div style={{ marginTop: 20, width: 380 }}>
              {this.props.isMobile && (
                <p style={styles.signupHeader}>Choose subscription plan</p>
              )}
              <div className="card-warning">
                <div className="row">
                  <div
                    style={{
                      width: "15%",
                      textAlign: "center",
                      marginTop: 15,
                    }}
                  >
                    <img src={warning_Gold_24dp} alt="..." />
                  </div>
                  <div style={{ width: "80%" }}>
                    <p style={styles.bottomParagraphWarning}>
                      Please do not click the Back button on your browser or
                      exit this process prior to completing setup.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-new">
                <div
                  className="row"
                  style={{
                    width: "100%",
                    height: 40,
                    justifyContent: "center",
                    marginBottom: 30,
                    flexWrap: "nowrap",
                    marginLeft: 4,
                  }}
                >
                  <StepperDot step={1} currentStep={3} />
                  <StepperDash />
                  <StepperDot step={2} currentStep={3} />
                  <StepperDash />
                  <StepperDot step={3} currentStep={3} />
                  <StepperDash />
                  <StepperDot step={4} currentStep={3} />
                  <StepperDash />
                  <StepperDot step={5} currentStep={3} />
                </div>
                <p style={styles.bottomParagraphWarning}>
                  A subscription plan is needed for the Freedom Collar as it
                  uses cellular data to update the Wagz App.
                </p>
                <div className="form-group">
                  <label>Select Payment Plan</label>
                  <select
                    id="selectedPlanId"
                    className="form-control"
                    value={this.state.selectedPlanId}
                    onChange={(e) => {
                      this.setState({
                        selectedPlanId: parseInt(e.target.value, 10),
                        validCode: "",
                        validationMessage: "",
                        couponColor: "#C70039",
                      });
                    }}
                  >
                    {this.state.plans.map((p: any) => {
                      return (
                        <option key={p.id} value={p.id}>
                          {p.display}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {this.planBody()}
                <div>
                  <div>
                    <p style={styles.newToWagz}>
                      <strong>Optional coupon code</strong>
                    </p>
                    <div className="row" style={{ marginTop: -10 }}>
                      <div className="col-9">
                        <input
                          id="couponCode"
                          type="text"
                          className="form-control"
                          placeholder="Enter coupon code"
                          aria-label="The coupon code you want to apply to this subscription"
                          aria-labelledby="couponLabel"
                          style={styles.inputFieldCoupon}
                          onChange={this.updateField}
                          value={this.state.couponCode}
                        />
                      </div>
                      <div className="col-3" style={{ marginLeft: -24 }}>
                        <button
                          style={styles.buttonNewCoupon}
                          aria-label="Apply coupon code"
                          onClick={() => this.applyCoupon()}
                        >
                          APPLY
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginRight: 10 }}>
                  <p style={{ color: this.state.couponColor }}>
                    {this.state.validationMessage}
                  </p>
                </div>

                <div style={{ marginTop: 10 }}>
                  <button
                    style={styles.buttonNew}
                    aria-label="Continue"
                    className="btn btn-block btn-primary"
                    onClick={this.navigateAway}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return <div className="col-sm-12">{this.step}</div>;
  }

  showQRModal() {
    this.setState({
      showQRModal: true,
    });
  }



  private verifyCaptcha(token: string, key: string){
    this.setState({ loading: true }, async () => {
      try{
        await UserAPI.checkCaptcha(token);
        this.setState({ loading: false, captchaValid: true });
      }catch(err){
        Alert.error("Invalid captcha response");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoStep);
