import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { getMobileOperatingSystem } from "src/utils/utilities";

import UserInfoStep from "./UserInfoStep";
import BillingInfoStep from "./BillingInfoStep";
import DeviceInfoStep from "./DeviceInfoStep";
import ReviewStep from "./ReviewStep";
import ReviewStepNoBilling from "./ReviewStepNoBilling";
import SuccessStep from "./SuccessStep";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
// import { styles } from "src/styles";

interface ISetupScreenProps {
  userActions: any;
  userState: any;
}

interface ISetupScreenState {
  loading: boolean;
  step: number;
  totalSteps: number;
  success: boolean;
  nextDisabled: boolean;
  userInfo: any;
  deviceInfo: any;
  billingInfo: any;
  isMobile: boolean;
}

export class Setup extends Component<ISetupScreenProps, ISetupScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      totalSteps: 7,
      success: false,
      nextDisabled: false,
      userInfo: {},
      deviceInfo: {},
      billingInfo: {},
      isMobile: false,
    };

    this.handleNext = this.handleNext.bind(this);
    this.userHandler = this.userHandler.bind(this);
    this.deviceHandler = this.deviceHandler.bind(this);
    this.billingHandler = this.billingHandler.bind(this);
    this.reviewHandler = this.reviewHandler.bind(this);
  }

  componentDidMount() {
    const mobileOS = getMobileOperatingSystem();
    if (mobileOS !== "unknown") {
      this.setState({ isMobile: true });
    }
  }

  userHandler(goToStep: any, info: any) {
    this.setState({ step: goToStep, userInfo: info });
  }

  deviceHandler(goToStep: any, info: any) {
    this.setState({ step: goToStep, deviceInfo: info });
  }

  billingHandler(value: any, info: any) {
    this.setState({ step: 5, success: value, billingInfo: info });
  }

  reviewHandler(value: any) {
    this.setState({ step: 6, success: value });
  }

  async handleNext() {
    await this.setState({ step: this.state.step + 1 });
  }

  get step() {
    if (this.state.step === 1) {
      return <UserInfoStep user={this.userHandler} isMobile={this.state.isMobile} />;
    } else if (this.state.step === 2) {
      return <DeviceInfoStep device={this.deviceHandler} isMobile={this.state.isMobile} />;
    } else if (this.state.step === 4) {
      return (
        <BillingInfoStep
          billing={this.billingHandler}
          address={this.state.userInfo}
          isMobile={this.state.isMobile}
        />
      );
    } else if (this.state.step === 5) {
      return (
        <ReviewStep
          review={this.reviewHandler}
          user={this.state.userInfo}
          device={this.state.deviceInfo}
          billing={this.state.billingInfo}
          isMobile={this.state.isMobile}
        />
      );
    } else if (this.state.step === 6) {
      return <SuccessStep success={this.state.success} isMobile={this.state.isMobile} />;
    } else if (this.state.step === 7) {
      return (
        <ReviewStepNoBilling
          review={this.reviewHandler}
          user={this.state.userInfo}
          device={this.state.deviceInfo}
        />
      );
    } else if (this.state.step === this.state.totalSteps + 1) {
      return <Redirect to="/account" />;
    }
    return null;
  }

  render() {
    return (
        <div className="setup-container">{this.step}</div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
