import * as React from "react";
import { Row } from "react-bootstrap";
import Card from "src/components/structure/Card";

interface DeviceActivatedMessageProps {
  deviceType?: string;
}

const DeviceActivatedMessage: React.SFC<DeviceActivatedMessageProps> = (
  props
) => {
  return (
    <Row style={{ marginBottom: 10 }}>
      <div className="col-8 offset-2">
        <Card title="Success!">
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Congratulations!</strong> Your{" "}
              {props.deviceType ? props.deviceType : "product"} is now ready to
              be activated in the Wagz Mobile App!
            </p>
          </div>
          <div>
            <div style={{ marginBottom: 8 }}>
              <strong>1. View or Download the Setup Guide</strong>
            </div>
            <div style={{ fontSize: 15, marginLeft: 5 }}>
              <a
                href={
                  "https://cdn.wagz.com/pdf/Wagz_Onboarding_and_Setup_Guide.pdf"
                }
              >
                Tap here
              </a>{" "}
              to view or download the Setup Guide for the Wagz Freedom Collar
              (PDF).
            </div>
            <div style={{ marginBottom: 8, marginTop: 20 }}>
              <strong>2. Download the Wagz App</strong>
            </div>
            <div style={{ fontSize: 15, marginLeft: 5 }}>
              Download the Wagz App from the{" "}
              <a href="wagz.com" target="_new">
                Apple App Store
              </a>{" "}
              or the{" "}
              <a href="wagz.com" target="_new">
                Google Play Store.
              </a>
            </div>
          </div>
        </Card>
      </div>
    </Row>
  );
};

export default DeviceActivatedMessage;
