import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import store from "./store";
import Nav from "src/components/structure/Nav";

// SignUp
import SignUp from "./components/screens/Login/SignUp";
import { LandingPage } from "./components/screens/Login/Landing";
// Account
import Account from "./components/screens/Account/Account";
import AccountDeletion from "./components/screens/Account/AccountDeletion";
import AuthenticatorOTP from "./components/screens/Account/Security/AuthenticatorOTP";
import EmailOTP from "./components/screens/Account/Security/EmailOTP";
import RecoveryCodes from "./components/screens/Account/Security/RecoveryCodes";
import Billing from "./components/screens/Billing/Billing";
import Devices from "./components/screens/Devices/Devices";
import Setup from "./components/screens/Setup/Setup";

// Login and Password
import Login from "./components/screens/Login/Login";
import OAuthLogin from "./components/screens/OAuthLogin/Login";
import ForgotPassword from "./components/screens/Login/ForgotPassword";
import VerifyScreen from "./components/screens/Login/Verify";
import Safety from "./components/screens/Policy/Safety";
import Privacy from "./components/screens/Policy/Privacy";
import Terms from "./components/screens/Policy/Terms";

// unsubscribe
import UnsubscribeScreen from "./components/screens/Unsubscribe/Unsubscribe";
import { logDeviceInfo } from "./utils/utilities";

class App extends Component {
  public componentDidMount() {
    (window as any).Stripe.setPublishableKey(
      process.env.REACT_APP_STRIPE_KEY || "pk_test_XZTbTNqBORPxHsbsiFRmoawW"
    );
    let userId = 0;
    if(window && window.localStorage){
      let found = window.localStorage.getItem("user");
      if(found && found !== ""){
        userId = JSON.parse(found).id;
      }
    }
    logDeviceInfo(userId);
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div id="page-container">
            <div className="container-fluid" id="content">
              <Nav />

              <div className="row">
                <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                  <Switch>
                    <Route exact={true} path="/" component={Login} />
                    <Route
                      exact={true}
                      path="/unsubscribe"
                      component={UnsubscribeScreen}
                    />
                    <Route exact={true} path="/forgot" component={ForgotPassword} />
                    <Route exact={true} path="/verify" component={VerifyScreen} />
                    <Route exact={true} path="/login" component={Login} />
                    <Route
                      exact={true}
                      path="/oauth2/login"
                      component={OAuthLogin}
                    />
                    <Route exact={true} path="/signup" component={SignUp} />
                    <Route exact={true} path="/landing" component={LandingPage} />
                    <Route exact={true} path="/devices" component={Devices} />
                    <Route exact={true} path="/billing" component={Billing} />
                    <Route exact={true} path="/account" component={Account} />
                    <Route exact={true} path="/account/delete" component={AccountDeletion} />
                    <Route exact={true} path="/account/security/authenticator" component={AuthenticatorOTP} />
                    <Route exact={true} path="/account/security/email" component={EmailOTP} />
                    <Route exact={true} path="/account/security/codes" component={RecoveryCodes} />
                    <Route exact={true} path="/setup" component={Setup} />
                    <Route exact={true} path="/safety" component={Safety} />
                    <Route exact={true} path="/privacy" component={Privacy} />
                    <Route exact={true} path="/terms" component={Terms} />
                    <Route
                      path="/faq"
                      component={() => {
                        window.location.href = "https://support.wagz.com/hc/en-us/sections/360012062791-FAQ-s";
                        return null;
                      }}
                    />
                    <Route
                      path="/guides/freedom/qsg"
                      component={() => {
                        window.location.href = "https://cdn.wagz.com/pdf/Freedom_Quick_Start_Guide.pdf";
                        return null;
                      }}
                    />
                    <Route
                      path="/guides/freedom/instructions"
                      component={() => {
                        window.location.href = "https://cdn.wagz.com/pdf/Wagz_Onboarding_and_Setup_Guide.pdf";
                        return null;
                      }}
                    />
                    <Route
                      path="/guides/freedom/led"
                      component={() => {
                        window.location.href = "https://cdn.wagz.com/pdf/Freedom_Light_Guide.pdf";
                        return null;
                      }}
                    />
                    <Route
                      path="/guides/freedom/geofences"
                      component={() => {
                        window.location.href = "https://cdn.wagz.com/pdf/Freedom_Geofence_Training_Guide.pdf";
                        return null;
                      }}
                    />
                  </Switch>
                </div>
              </div>

              <ToastContainer />
            </div>
            <footer style={{ backgroundColor: "#ffffff" }}>
              <div className="row" style={{ width: "100%" }}>
                <div
                  className="col-sm-12 col-lg-12"
                  style={{ textAlign: "center" }}
                >
                  <small>
                    Copyright{" "}
                    <a href="https://wagz.com/" target="_new">
                      Wagz, Inc
                    </a>
                    . All rights reserved.
                    <br />
                    Use of this application constitutes agreement with our{" "}
                    <a
                      href="http://www.wagz.com/pages/terms-and-conditions"
                      target="_new"
                    >
                      {" "}
                      Terms of Service
                    </a>
                    ,
                    <a
                      href="http://www.wagz.com/pages/privacy-policy"
                      target="_new"
                    >
                      {" "}
                      Privacy Policy
                    </a>
                    , and
                    <a
                      href="http://www.wagz.com/pages/end-user-license-agreement"
                      target="_new"
                    >
                      {" "}
                      End User License Agreement
                    </a>
                  </small>
                </div>
              </div>
            </footer>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
