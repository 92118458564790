import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import "url-search-params-polyfill";

import { UserAPI } from "../../../API";
import * as Alert from "../../structure/Alert";

import { styles } from "../../../styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PasswordRules from "src/components/screens/Login/PasswordRulesBox";

interface IForgotPasswordScreenProps {
  location: any;
}

interface IForgotPasswordScreenState {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
  step: number;
  loading: boolean;
}

export class ForgotPasswordScreen extends Component<
  IForgotPasswordScreenProps,
  IForgotPasswordScreenState
> {
  constructor(props: IForgotPasswordScreenProps) {
    super(props);
    this.state = {
      email: "",
      token: "",
      password: "",
      confirmPassword: "",
      step: 1,
      loading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.startReset = this.startReset.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetEnter = this.resetEnter.bind(this);
  }

  componentDidMount() {
    if (window.URLSearchParams) {
      const query = new URLSearchParams(this.props.location.search);
      const email = query.get("email");
      const token = query.get("token");
      if (email && token) {
        this.setState({
          email,
          token,
          step: 3,
        });
      }
    } 
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  async startReset() {
    if (this.state.email === "") {
      return Alert.error("You must provide an email address!");
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.startPasswordReset(this.state.email);
        this.setState({ step: 2 });
      } catch (error) {
        Alert.error("Your email is not valid");
        this.setState({ loading: false });
      }
    });
  }

  async resetPassword() {
    const { email, password, confirmPassword, token } = this.state;
    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      token === ""
    ) {
      return Alert.error("Fields cannot be blank!");
    }
    if (password !== confirmPassword) {
      return Alert.error("Ensure your passwords match!");
    }
    this.setState({ loading: true }, async () => {
      // first check the password validity
      try {
        await UserAPI.validatePassword(password);
      } catch (err) {
        Alert.error(
          "That password does not meet our password requirements. Please choose something more secure."
        );
        return this.setState({ loading: false });
      }

      try {
        await UserAPI.finishPasswordReset(email, password, token, {});
        Alert.success("Your password has been reset!");
        return this.setState({ step: 4 });
      } catch (error) {
        Alert.error("Your email and/or password are not valid");
        this.setState({ loading: false });
      }
    });
  }

  /**
   * Allows user to submit the resetting of their password through use of the enter key.
   * @param {*} e for the enter key
   */
  resetEnter = (e: any) => {
    var keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      this.startReset();
    }
  };

  /**
   * Provides certain pages based on which step of resetting the password the user is on.
   *
   * Step 1
   * @returns {form} Reset Password Form.
   * Step 2
   * @returns {string} Prompts user to check email to continue.
   *
   * @returns {form} Form allowing user to complete password reset and continue to login.
   */
  get step() {
    if (this.state.step === 1) {
      return (
        <div>
            <div className="card-new">
              <div className="form-group">
                <p style={styles.newToWagz}>
                  Don't worry, happens to the best of us.
                  <br />
                  Enter your email address below.
                </p>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  arai-label="Your Email Address"
                  style={styles.inputFieldNew}
                  autoComplete="off"
                  onChange={this.updateField}
                  value={this.state.email}
                  onKeyPress={this.resetEnter}
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <button
                  style={styles.buttonNew}
                  className="btn btn-block btn-primary"
                  aria-label="Start Reset Process"
                  onClick={this.startReset}
                >
                  EMAIL ME A RECOVERY LINK
                </button>
              </div>
            </div>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div>
            <div className="card-new">
              <p style={styles.newToWagz}>
                Please check your email to continue the reset password process.
              </p>
            </div>
        </div>
      );
    } else if (this.state.step === 3) {
      return (
        <div>
            <div className="card-new">
              <p style={styles.newToWagz}>Finish resetting your password:</p>
              <div className="form-group">
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  style={styles.inputFieldNew}
                  placeholder="Email Address"
                  aria-label="Email Address"
                  autoComplete="off"
                  onChange={this.updateField}
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                  id="token"
                  type="text"
                  className="form-control"
                  style={styles.inputFieldNew}
                  placeholder="Verification Token"
                  aria-label="Verification Token"
                  autoComplete="off"
                  onChange={this.updateField}
                  value={this.state.token}
                />
              </div>
              <div className="form-group">
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  style={styles.inputFieldNew}
                  placeholder="New Password"
                  aria-label="New Password"
                  autoComplete="off"
                  onChange={this.updateField}
                  value={this.state.password}
                />
              </div>
              <PasswordRules/>
              <div className="form-group">
                <input
                  id="confirmPassword"
                  type="password"
                  className="form-control"
                  style={styles.inputFieldNew}
                  placeholder="Confirm New Password"
                  aria-label="Confirm New Password"
                  autoComplete="off"
                  onChange={this.updateField}
                  value={this.state.confirmPassword}
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <button
                  style={styles.buttonNew}
                  className="btn btn-block btn-primary"
                  onClick={this.resetPassword}
                  aria-label="Reset Your Password"
                >
                  Reset Password
                </button>
              </div>
            </div>
        </div>
      );
    } else if (this.state.step === 4) {
      return <Redirect to="/login" />;
    }
    return null;
  }

  render() {
    return (
      <div>
          <div className="row">
            <div style={styles.itemAlign}>
              <div style={{ marginTop: 20, width: 480 }}>
                <p style={styles.signupHeader}>Recover Password</p>
                {this.step}
              </div>
            </div>
            <div style={styles.linkAlign}>
              <p className="nav-item">
                <Link
                  to="/login"
                  id="nav-login"
                  className="nav-link-new"
                >
                  Already a customer? Sign In.
                </Link>
              </p>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen) as any
);
