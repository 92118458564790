import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";
import wagzLogoNew from "src/img/wagz_logo_new.svg";

interface INavProps {
  appActions: any;
  userActions: any;

  appState: any;
  userState: any;
  history: any;
  location: any;
  
}

interface INavState {
  open: boolean;
  users: any[];
}

class NavBar extends React.Component<INavProps, INavState> {

  constructor(props: any) {
    super(props);
    this.state = {
      open: true,
      users: [],
    };

    this.logout = this.logout.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
  }

  public logout() {
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
  }


  public toggleHelp(e: any) {
    e.preventDefault();
    this.props.appActions.toggleHelp();
  }


  get mobileLinks() {
    if (this.props.userState.loggedIn) {
      const elements = [];
      elements.push(
        <ul className="navbar-nav mr-auto" key="nav-left">
          <li className="nav-item"><Link to={`/account`} className="nav-link">Account</Link></li>
          <li className="nav-item"><Link to={`/billing`} className="nav-link">Payments</Link></li>
          <li className="nav-item"><Link to={`/devices`} className="nav-link">Products</Link></li>
        </ul>);
      elements.push(
        <ul className="navbar-nav" key="nav-right">
          <li className="nav-item"><button className="btn btn-link nav-link" onClick={this.logout}>Logout</button></li>
        </ul>);
      return (
        <div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button><div className="navbar-collapse collapse" id="navbarSupportedContent">{elements}</div>
        </div>
        )
    } else {
      const elements: never[] = [];
      return elements;
    }
  }  
  
  get links() {

    if (this.props.userState.loggedIn) {
      const elements = [];
      elements.push(
        <ul className="navbar-nav mr-auto" key="nav-left">
          <li className="nav-item" style={{ paddingRight: 57 }}><Link to={`/devices`} className="nav-link">My Products</Link></li>
          <li className="nav-item" style={{ paddingRight: 57 }}><Link to={`/billing`} className="nav-link">My Payments</Link></li>
          <li className="nav-item"><Link to={`/account`} className="nav-link">My Account</Link></li>
        </ul>);
      elements.push(
        <ul className="navbar-nav" key="nav-right">
          <li className="nav-item"><button className="btn btn-link nav-link" onClick={this.logout}>Sign Out</button></li>
        </ul>);
      return elements;
    } else {
      const elements = [];
      elements.push(
        <ul className="navbar-nav mr-auto" key="nav-left">
        </ul>);
      elements.push(
        <ul className="navbar-nav" key="nav-right">
          <li className="nav-item"><Link to="/login" id="nav-login" className="nav-link">Login &nbsp;</Link></li>
          <li className="nav-item"><Link to="/signup" id="nav-signup" className="nav-link">Sign Up</Link></li>
        </ul>);
      return elements;
    }
  }

  public render() {
    return (
      <div>
        <div id="navbar" style={{ backgroundColor: "#0089CA", marginLeft: -16, marginTop: -10, marginRight: -22 }}>
          <nav className="navbar navbar-expand-lg navbar-dark" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
            <a className="navbar-brand" href="/" style={{ paddingInlineEnd: 114 }}><img src={wagzLogoNew} alt="logo" height="140%" width="140%" /></a>
        
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse" id="navbarSupportedContent">
              {this.links}
            </div>
          </nav>
        </div>
      </div>);
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar) as any);