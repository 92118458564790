import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";
import * as Alert from "../../structure/Alert";
import { UserAPI } from "../../../API";
import { styles } from "../../../styles";
import LoadingButton from "src/components/structure/LoadingButton";
import PasswordRules from "src/components/screens/Login/PasswordRulesBox";

interface ChangeEmailProps {
  userId: number;
  onUpdated: any;
}

interface ChangeEmailState {
  loading: boolean;
  newPassword: string;
  newPasswordConfirm: string;
  inputType: string;
  buttonActive: boolean;
  showModal: boolean;
  showPassword: boolean;
}

export class ChangeEmail extends Component<ChangeEmailProps, ChangeEmailState> {
  constructor(props: any) {
    super(props);
    this.state = {
      newPassword: "",
      newPasswordConfirm: "",
      inputType: "password",
      buttonActive: false,
      showModal: false,
      showPassword: false,
      loading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.handlePasswordToggle = this.handlePasswordToggle.bind(this);
  }

  render() {
    return (
      <div>
        <div className="card-new-no-border">
          <div className="form-group">
            <h6 className="header-6">Update password</h6>
          <PasswordRules className="main-text" style={{}} />
          </div>
          <div className="form-group">
            <input
              id="newPassword"
              type={this.state.inputType}
              className="form-control"
              placeholder="New Password"
              style={styles.inputFieldNew}
              onChange={this.updateField}
              value={this.state.newPassword}
              aria-label="Desired new password"
            />
          </div>
          <div className="form-group">
            <input
              id="newPasswordConfirm"
              type={this.state.inputType}
              className="form-control"
              placeholder="Confirm New Password"
              style={styles.inputFieldNew}
              onChange={this.updateField}
              value={this.state.newPasswordConfirm}
              aria-label="Re-enter new password"
            />
          </div>
          <div className="row" style={{ padding: 5, marginBottom: 10 }}>
            <div>
              <input
                id="showPassword"
                type="checkbox"
                aria-label="Show Password"
                checked={this.state.showPassword}
                onChange={this.handlePasswordToggle}
              />
            </div>
            <div style={{ marginTop: 1, marginLeft: -2 }}>
              <label className="radio-text" htmlFor="showPassword">
                Show password
              </label>
            </div>
          </div>
          <div className="form-group">
            <LoadingButton
              aria-label="Update email address"
              loading={this.state.loading}
              className="btn btn-block btn-primary"
              style={styles.buttonNew}
              onClick={this.showConfirmation}
              disabled={this.comparePasswords()}
            >
              UPDATE PASSWORD
            </LoadingButton>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          onEntered={(e: any) => {
            window.location.hash = "warning-modal";
            window.onhashchange = () => {
              if (!window.location.hash) {
                this.setState({ showModal: false });
              }
            };
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm password change</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>
                Are you sure you wish to make this change? After confirming, you
                may be logged out of all sessions and will need to log in with
                the new password before being allowed to continue.
              </p>
              <div style={styles.emailComponent}>
                <button
                  className="btn btn-block btn-primary"
                  style={styles.button}
                  aria-label="Confirm email change"
                  onClick={() => {
                    this.setState({ showModal: false }, () => {
                      this.submitChanges();
                    });
                  }}
                >
                  Confirm password change
                </button>
              </div>
              <div style={styles.emailComponent}>
                <button
                  className="btn btn-block btn-secondary"
                  style={styles.button}
                  aria-label="Take me back"
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  Take me back
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  submitChanges() {
    this.setState({ loading: true }, async () => {
      try {
        const body = {
          password: this.state.newPassword,
        };
        await UserAPI.updateProfile(this.props.userId, body);
        this.setState({ loading: false });
        Alert.success(
          "Password updated, you may now use it to log in to your account!"
        );
        this.props.onUpdated();
      } catch (err) {
        this.setState({ loading: false });
        Alert.error(
          "Requested password changes could not be saved. Please contact support."
        );
      }
    });
  }

  private showConfirmation() {
    this.setState({ showModal: !this.state.showModal });
  }

  private handlePasswordToggle() {
    this.setState({
      showPassword: !this.state.showPassword,
      inputType: this.state.inputType === "text" ? "password" : "text",
    });
  }

  private comparePasswords() {
    if (
      this.state.newPassword === this.state.newPasswordConfirm &&
      this.state.newPassword !== ""
    ) {
      return false;
    }
    return true;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
