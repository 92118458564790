import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";
import { styles } from "../../../styles";

interface PrivacyScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface PrivacyScreenState {
  exit: boolean;
}

export class Privacy extends Component<PrivacyScreenProps, PrivacyScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      exit: false,
    };
    this.handleExit = this.handleExit.bind(this);
  }

  handleExit() {
    this.setState({ exit: true });
  }

  render() {
    if (!this.state.exit) {
      return (
        <div style={{ marginBottom: 50 }}>
          <Card title="Wagz Privacy Policy">
            <div className="text">
              <div style={{ textAlign: "center" }}>
                <strong>
                  The Wagz Privacy Policy explains how we treat your personal
                  data and protect your privacy when you use the Site. By using
                  the Site, you agree that Wagz can use your personal data in
                  accordance with our Privacy Policy.
                </strong>
              </div>
              <br />

              <strong>Buying and Selling</strong>
              <p>
                Wagz’s Buying and Selling Policy explains how Members can buy
                and return items on Get Wagz. By using the Site, you agree to
                buy and return items in accordance with our Buying and Selling
                Policy.
              </p>
              <strong>YOUR Representations and Warranties</strong>
              <p>
                You represent and warrant that your use of the Site will be in
                strict accordance with these Terms, and with all applicable
                laws, rules and regulations, including without limitation all
                tax, federal, state, local, provincial and international laws,
                rules and regulations applicable to your use of the Site and
                your Content, including all copyright, patent and trademark
                laws.
              </p>
              <p>By using the Site, you hereby represent and warrant that:</p>
              <ul>
                <li>You are at least 18 years old.</li>
                <li>You will use messaging to facilitate business.</li>
                <li>You will not to spam or harass others.</li>
                <li>
                  You will respect other Member’s privacy and as such you will
                  not share private or personally identifiable information in
                  public areas of the Site.
                </li>
                <li>
                  You will not use any of the Wagz tools or features to
                  purposefully harm or disrupt another Member’s business or
                  reputation; targeted bullying will not be tolerated.
                </li>
                <li>
                  You will be respectful, posted Content must not promote,
                  support or glorify hatred.
                </li>
              </ul>
              <strong>Mobile Applications</strong>
              <p>
                We do our best to ensure that the Site is accessible through as
                many mobile device platforms as possible. However, Wagz cannot
                and does not guarantee that our Site will be accessible through
                your mobile device.
              </p>
              <strong>Intellectual Property</strong>
              <p>
                Intellectual Property means all patent rights, copyright rights,
                moral rights, trademark rights, trade secret rights, and all
                other intellectual property and proprietary rights related to
                the Site as may now exist or hereafter come into existence, and
                all applications for any of these rights and registrations,
                renewals and extensions of any of these rights, in each case
                under the laws of any state, province, country, territory or
                other jurisdiction. All such rights, title, and interest in and
                to the Site, excluding Content provided and owned by Wagz
                Members and except as otherwise expressly provided for herein,
                are and will remain the property of Wagz.
              </p>
              <p>
                Subject to these Term of Service, you retain any applicable
                Intellectual Property rights to your Content, so in all cases it
                remains your property. By posting Content on our Site, you
                represent and warrant that you own all rights in and to such
                Content or, alternatively, you have acquired all necessary
                rights in such Content to enable you to grant the above
                licenses. Except as prohibited by law, you hereby waive any
                moral rights you may have in your Content under the laws of any
                jurisdiction. You further authorize us to publish your Content
                in a searchable format that may be accessed by Wagz Members and
                other Internet users.
              </p>
              <strong>Claims of Copyright Infringement</strong>
              <p>
                Address of Designated Agent to Which Notification should be
                sent:
              </p>
              <p>
                Ascentage Law,
                <br />
                PLLC 155 Fleet St.
                <br />
                Portsmouth, NH 03801
              </p>
              <p>
                To be effective, the notification must be a written
                communication that includes the following:
              </p>
              <ul>
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works at a single
                  online Site are covered by a single notification, a
                  representative list of such works at that Site;
                </li>
                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit us to locate
                  the material;
                </li>
                <li>
                  Information reasonably sufficient to permit us to contact the
                  complaining party, such as an address, telephone number and,
                  if available, an electronic mail address at which the
                  complaining party may be contacted;
                </li>
                <li>
                  A statement that the complaining party has a good-faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent or the law; and
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ul>
              <p>
                We may give notice of a claim of copyright infringement to our
                Members by means of a general notice on our Site, electronic
                mail to a Member’s email address in our records, or by written
                communication sent by first-class mail to a Member’s address in
                our records. In accordance with the Digital Millennium Copyright
                Act (“DMCA”) and other applicable law, Wagz has adopted a policy
                of terminating, in appropriate circumstances, and at the sole
                discretion of Wagz, the right to access and use the Get Wagz
                Site for those Members who are deemed to be repeat infringers.
                Wagz may also, at its sole discretion, limit access to the Site
                and/or terminate the right of any Members to access and use the
                Get Wagz Site for the infringement of any intellectual property
                rights of others, whether or not there is any repeat
                infringement.
              </p>
              <strong>Changes & Modifications</strong>
              <p>
                Wagz reserves the right, at its sole and absolute discretion, to
                temporarily or in perpetuity, change, modify, update, alter
                and/or cancel the Site or any portion thereof, these Terms
                and/or all other Wagz Policies, without notice to you. Wagz will
                not be liable for any kind of damage or loss to you or to any
                third party, stemming from any such changes, modifications,
                updates, alterations and/or cancelation of any of the Site or
                portion thereof, these Terms and/or all other Wagz Policies. Any
                Changes to these Terms and/or other Wagz Policies will be
                reflected on the Site and continued use of the Site thereafter
                will constitute acceptance of any such changes, modifications,
                updates, alterations and/or cancellation.
              </p>
              <p>
                <em>
                  Translation: We can make changes without notice and they will
                  control your use of the Site; check our Terms and Policies for
                  updates.
                </em>
              </p>
              <strong>Termination</strong>
              <p>
                Notwithstanding anything to the contrary in these Terms and/or
                any of our Policies, we reserve the right, without notice,
                without liability to you and in our sole discretion, to
                terminate any of your rights under these Terms (including,
                without limitation your right to use our Site and to block or
                prevent your access to and use of our Site) for any or no
                reason.
              </p>
              <strong>MEMBER DISPUTES / RELEASE</strong>
              <p>
                If you have a dispute with one or more Members, you release us
                (and our affiliates and subsidiaries, and our and their
                respective officers, directors, employees and agents) from all
                claims, demands and/or damages (actual and consequential) of
                every kind and nature, known and unknown, arising out of or in
                any way connected with such disputes. In entering into this
                release you expressly waive any protections (whether statutory
                or otherwise) that would otherwise limit the coverage of this
                release to include only those claims, which you may know or
                suspect to exist in your favor at the time of agreeing to this
                release.
              </p>
              <p>
                If you have a problem with a transaction conducted on the Wagz
                Site and are unable to reach a resolution with the applicable
                Member, you can contact customer service and they will handle
                all disputes on an individual basis. YOU AGREE TO BE BOUND BY
                ANY DECISION(S) THAT THE WAGZ SUPPORT TEAM COMES TO WITH REGARD
                TO SUCH DISPUTES.
              </p>
              <p>
                In order to have the best experience on Wagz, be sure to read
                other Member’s transaction policies and listings carefully. Keep
                in mind that we will not assist in resolving any problems
                arising from transactions that are completed outside of the Wagz
                community.
              </p>
              <strong>Indemnification</strong>
              <p>
                You agree, at your own expense, to indemnify, defend and hold us
                harmless from and against any actual and/or threatened claim,
                demand and/or any resulting losses, whether direct or indirect,
                incurred arising out of or relating to the use of the Wagz Site.
              </p>
              <strong>Limitations on Liability</strong>
              <p>
                IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR LOSS
                OF REVENUE, LOSS OF PROFITS OR ANY SPECIAL, INDIRECT,
                INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND
                ARISING OUT OF OR IN CONNECTION WITH THE WAGZ SITE, YOUR CONDUCT
                OR THE CONDUCT OF ANY OTHER MEMBERS, INCLUDING, BUT NOT LIMITED
                TO, BODILY INJURY, EMOTIONAL DISTRESS, ANY OTHER DAMAGES AS A
                RESULT OF CONTACT WITH OTHER MEMBERS AND/OR ANY DAMAGES CAUSED
                BY OR RESULTING FROM RELIANCE BY MEMBER ON ANY INFORMATION
                OBTAINED FROM Wagz, VIA OUR SITE (OR THAT RESULTS FROM MISTAKES,
                OMISSIONS, INTERRUPTIONS, DEFECTS, VIRUSES, DELAYS IN OPERATION
                OR UNAUTHORIZED ACCESS TO OUR SITE), REGARDLESS OF THE FORM OF
                ACTION, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR
                OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES OR ARE AWARE OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <strong>Disclaimer of OUR Representations and Warranties</strong>
              <p>
                UNLESS OTHERWISE EXPRESSLY SET FORTH IN WRITING, OUR SITE,
                MOBILE APPLICATIONS, SITE MATERIALS, CONTENT, THIRD PARTY
                CONTENT, ANY HYPERLINKS AND ANY OTHER SERVICES AND/OR CONTENT
                INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH OUR SITE
                (EACH INDIVIDUALLY OR TOGETHER THE “WAGZ SERVICES”) ARE PROVIDED
                TO YOU“AS IS” WITHOUT ANY REPRESENTATIONS OR WARRANTIES OR
                CONDITIONS OF ANY KIND. WE DO NOT WARRANT THAT ANY WAGZ
                CONTENT/SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS. WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH
                RESPECT TO THE AVAILABILITY, RELIABILITY OR SECURITY OF ANY WAGZ
                SERVICES. UNLESS OTHERWISE EXPRESSLY SET FORTH IN WRITING, WE
                DISCLAIM ANY AND ALL OTHER WARRANTIES AND REPRESENTATIONS
                (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO ALL WAGZ
                SITES AND SERVICES WHETHER ALLEGED TO ARISE BY OPERATION OF LAW,
                BY REASON OF CUSTOM OR USAGE IN THE TRADE, BY COURSE OF DEALING
                OR OTHERWISE INCLUDING: (I) ANY AND ALL WARRANTIES OF
                MERCHANTABILITY; (II) ANY AND ALL WARRANTIES OF FITNESS OR
                SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT KNOWN, HAVE REASON
                TO KNOW, HAVE BEEN ADVISED, OR ARE OTHERWISE AWARE OF ANY SUCH
                PURPOSE); AND (III) ANY AND ALL WARRANTIES OF NONINFRINGEMENT OR
                CONDITION OF TITLE.
              </p>
              <strong>Disputes / Arbitration</strong>
              <p>
                You and Wagz (each individually a “Party” and collectively the
                “Parties”) agree that any dispute relating to these terms or the
                use of the Site will be resolved solely by the means set forth
                in this section. In the event that any such dispute arises, you
                agree to first contact Wagz directly and the Parties agree to
                make a good faith effort to resolve the dispute. If the matter
                remains unresolved for sixty (60) days after your initial
                contact with Wagz regarding the dispute, the matter will be
                submitted to binding arbitration under the rules of the Any such
                arbitration will be conducted in Boston, Massachusetts – USA.
                The arbitrator(s) involved in settling any such dispute will
                have authority to grant specific performance and to allocate the
                costs of arbitration between the Parties as the arbitrator(s)
                deems equitable. Any such judgments rendered by the
                arbitrator(s) may be entered in any court having jurisdiction or
                application may be made to such court for judicial acceptance of
                any award and an order of enforcement, as the case may be.
              </p>
              <strong>Waiver</strong>
              <p>
                Wagz’s failure to strictly enforce any provision of our Terms
                and/or Policies will not constitute a waiver of any such
                provision.
              </p>
              <strong>Governing Law / Forum</strong>
              <p>
                Any dispute arising under or in connection with this Agreement
                or related to any matter that is the subject of this Agreement
                shall be governed by, and construed in accordance with, the
                substantive laws of the State of New Hampshire – USA, without
                regard to its conflict of laws principles. You agree that any
                claim or dispute you may have against Wagz must be resolved by a
                court located in Rockingham County, New Hampshire, except as
                otherwise agreed by the Parties or as described in the
                Arbitration Option paragraph above. You agree to submit to the
                personal jurisdiction of the courts located within Rockingham
                County, New Hampshire for the purpose of litigating all such
                claims or disputes. In addition to any other relief awarded, the
                prevailing Party in any action arising out of this Agreement
                shall be entitled to its reasonable attorneys’ fees and costs.
              </p>
              <strong>Entire Agreement</strong>
              <p>
                These Terms, and any updates thereto, will constitute the entire
                agreement between you and Wagz concerning the Site and
                supersedes all prior understandings, agreements and
                documentation relating to such subject matter.
              </p>
              <strong>Severability</strong>
              <p>
                If a court of competent jurisdiction deems any provision of
                these Terms invalid, the invalidity of such provision will not
                affect the validity of the remaining provisions of the Terms,
                which will remain in full force and effect.
              </p>
              <strong>Contact Information</strong>
              <p>
                If you have any questions about these Terms of Service or
                anything else, please contact Wagz, Inc. at:
              </p>
              <p>Attention: Wagz, Inc.</p>
              <p>Info@Wagz.com</p>
              <p>
                100 Market Street, Suite 401
                <br />
                Portsmouth, NH, 03801, USA
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="text" style={{ width: "25%" }}>
                <button
                  className="btn btn-block btn-primary"
                  style={styles.button}
                  onClick={this.handleExit}
                >
                  Exit
                </button>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    return <Redirect to="/" />;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
