import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "url-search-params-polyfill";
import * as EmailValidator from "email-validator";
import { Link } from "react-router-dom";

import PasswordRules from "src/components/screens/Login/PasswordRulesBox";
import * as Alert from "src/components/structure/Alert";

import { styles } from "src/styles";
import { UserAPI } from "src/API";
import { getMobileOperatingSystem } from "src/utils/utilities";

interface ISignUpProps {
  location: any;
  userState: any;
}

interface ISignUpState {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  state: string;
  inputType: string;
  showPassword: boolean;
  step: number;
  isLoggedIn: boolean;
  isMobile: boolean;
  loading: boolean;
}

class SignUp extends Component<ISignUpProps, ISignUpState> {
  constructor(props: ISignUpProps) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      state: "",
      inputType: "password",
      showPassword: false,
      step: 1,
      isLoggedIn: false,
      isMobile: false,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordToggle = this.handlePasswordToggle.bind(this);
  }

  componentDidMount() {
    const mobileOS = getMobileOperatingSystem();
    if (mobileOS !== "unknown") {
      this.setState({ isMobile: true });
    }
  }

  handleChange(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handlePasswordToggle() {
    this.setState({
      showPassword: !this.state.showPassword,
      inputType: this.state.inputType === "text" ? "password" : "text",
    });
  }

  private handleSubmit() {
    //check validation
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    const firstName = this.state.firstName.trim();
    const lastName = this.state.lastName.trim();
    const marketingSub = true;
    // var marketingSub = 1;
    if (!EmailValidator.validate(email)) {
      return Alert.error(
        "Invalid email address.  Please enter your email address in this format: username@example.com."
      );
    }
    // if (!this.state.marketingSub) {
    //   marketingSub = 0
    // }
    if (
      email === "" ||
      password === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      return Alert.error("All fields are required");
    }

    this.setState({ loading: true }, async () => {
      // first check the password validity
      try {
        await UserAPI.validatePassword(this.state.password);
      } catch (err) {
        Alert.error(
          "That password does not meet our password requirements. Please choose something more secure."
        );
        return this.setState({ loading: false });
      }

      try {
        await UserAPI.register(this.state.email, this.state.password, {
          firstName,
          lastName,
          marketingSub,
        });
        this.setState({ step: 2 });
        this.setState({ loading: false });
      } catch (error) {
        Alert.error("Something went wrong. Please try again later.");
        this.setState({ loading: false });
      }
    });
  }

  /**
   * Provides certain pages based on which step of resetting the password the user is on.
   *
   * Step 1
   * @returns {form} Reset Password Form.
   * Step 2
   * @returns {string} Prompts user to check email to continue.
   *
   */
  get step() {
    if (this.state.step === 1) {
      return (
        <div>
          <div className="row">
            <div style={styles.itemAlign}>
              <div style={{ marginTop: 20, width: 380 }}>
                <p style={styles.signupHeader}>Welcome to Wagz!</p>
                <div className="card-new">
                  <p style={styles.newToWagz}>New to Wagz? Create an account</p>
                  <div className="form-group">
                    <input
                      id="firstName"
                      type="firstName"
                      placeholder="First Name"
                      aria-label="First Name"
                      style={styles.inputFieldNew}
                      className="form-control"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="lastName"
                      type="lastName"
                      placeholder="Last Name"
                      aria-label="Last Name"
                      style={styles.inputFieldNew}
                      className="form-control"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email Address"
                      style={styles.inputFieldNew}
                      className="form-control"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      id="password"
                      data-tip="Passwords must be at least 8 characters long and <br/> avoid commonly used passwords. We recommend long phrases."
                      type={this.state.inputType}
                      placeholder="Create a password"
                      aria-label="Your password. Passwords must be at least 8 characters long."
                      style={styles.inputFieldNew}
                      className="form-control"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <PasswordRules/>
                  </div>
                  <div className="row" style={{ marginTop: 16 }}>
                    <div>
                      <input
                        id="showPassword"
                        type="checkbox"
                        aria-label="Show Password"
                        checked={this.state.showPassword}
                        onChange={this.handlePasswordToggle}
                      />
                    </div>
                    <div style={{ marginTop: 1 }}>
                      <label className="show-password" htmlFor="showPassword">
                        {" "}
                        Show password{" "}
                      </label>
                    </div>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <button
                      style={styles.buttonNew}
                      className="btn btn-block btn-primary"
                      onClick={this.handleSubmit}
                    >
                      CREATE MY ACCOUNT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div style={styles.linkAlign}>
              <p className="nav-item">
                <Link
                  to="/login"
                  id="nav-login"
                  className="nav-link-new"
                >
                Already a customer? Sign In.
                </Link>
              </p>
            </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            marginTop: 50,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={require("src/img/check_mail.png")} alt="..." />
        </div>
      );
    }
  }

  render() {
    if (!window.localStorage.jwt || window.localStorage.jwt === "") {
      return <div>{this.step}</div>;
    }
    return <Redirect to="/devices" />;
  }
}

export default SignUp;
