import * as React from "react";
import Lottie from "react-lottie";
import animationData from "../../../img/success-fireworks.json";

import wagz_logo_blue from "src/img/wagz_logo_blue.svg";
import Card from "src/components/structure/Card";
import webapp_success_check from "../../../img/webapp_success_check.svg";
import wagz_mobile from "../../../img/wagz_mobile.svg";
import googlePlay_badge from "../../../img/googlePlay_badge.svg";
import appStore_badge from "../../../img/appStore_badge.svg";
import { styles } from "src/styles";
import { getLink } from "src/utils/utilities";

interface DeviceActivatedOnSetupMessageProps {
  deviceType?: string;
  os: string;
  mobile: boolean;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const DeviceActivatedOnSetupMessage: React.SFC<
  DeviceActivatedOnSetupMessageProps
> = (props) => {
  return (
    <div>
      {!props.mobile && (
        <div>
          <Card>
            <div className="col-sm-12" style={styles.bottomBorder}>
              <img src={webapp_success_check} alt="..." />
              <p style={{ fontSize: 18 }}>
                <strong style={{ fontSize: 36 }}>Success!</strong> <br />
                Your {props.deviceType ? props.deviceType : "product"} is now
                ready to be activated using the Wagz Mobile App.
              </p>
            </div>
            <div style={{ flexDirection: "row" }}>
              <div
                style={{
                  display: "inline-block",
                  width: "35%",
                  verticalAlign: "top",
                  marginTop: 25,
                }}
              >
                <img src={wagz_mobile} alt="..." />
              </div>
              <div
                style={{ display: "inline-block", width: "65%", marginTop: 20 }}
              >
                <p style={{ fontSize: 16 }}>
                  <strong style={{ fontSize: 20 }}>Get the Wagz App</strong>{" "}
                  <br />
                  Please download the Wagz App, activate your collar and you are
                  ready to use real-time GPS, temperature alerts, activity
                  tracking and more!
                </p>
                <p style={{ fontSize: 16 }}>
                  <strong style={{ fontSize: 20 }}>
                    Download the Setup Guide
                  </strong>{" "}
                  <br />
                  <a
                    href={
                      "https://cdn.wagz.com/pdf/Wagz_Onboarding_and_Setup_Guide.pdf"
                    }
                  >
                    Tap here
                  </a>
                  to view or download the Setup Guide for the Wagz Freedom
                  Collar (PDF).
                </p>
              </div>
            </div>
            <div style={{ textAlign: "center", flexDirection: "row" }}>
              <div style={{ display: "inline-block" }}>
                <a
                  href={"https://apps.apple.com/us/app/wagz/id1372859800"}
                  target="_new"
                >
                  <img src={appStore_badge} alt="..." />
                </a>
              </div>
              <div style={{ display: "inline-block" }}>
                <a
                  href={
                    "https://play.google.com/store/apps/details?id=com.getwagz.wagz&hl=en_US&gl=US"
                  }
                  target="_new"
                >
                  <img src={googlePlay_badge} alt="..." />
                </a>
              </div>
            </div>
          </Card>
        </div>
      )}
      {props.mobile && (
        <div className="row" style={{ marginBottom: 100 }}>
          <div style={{ marginTop: 100 }}>
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
              isStopped={false}
              isPaused={false}
            />
          </div>
          <div style={{ marginTop: -500 }}>
            <div style={styles.itemAlign}>
              <div style={{ marginTop: 20, width: 380 }}>
                <div
                  style={{ marginLeft: 20 }}
                >
                <p style={styles.signupHeader}>Success!</p>
                </div>
                <div
                  className="card-new-success"
                  style={{ textAlign: "center", marginLeft: 20 }}
                >
                  <img src={wagz_logo_blue} alt="..." />
                  <p style={styles.completedRegistration}>
                    Wagz registration completed!
                  </p>
                  <p style={styles.completedRegistrationSubheader}>
                    You may complete your product setup from the Wagz App.
                  </p>
                  <div style={{ marginTop: 200 }}>
                    <a href={getLink(props.os)} target="_new">
                      <button
                        style={styles.buttonNew}
                        className="btn btn-block btn-primary"
                      >
                        OPEN THE WAGZ APP
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceActivatedOnSetupMessage;
