import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Card from "src/components/structure/Card";
import { styles } from "src/styles";
import * as Alert from "src/components/structure/Alert";
import { UserAPI } from "src/API";

interface IUnsubscribeScreenProps {
  appActions: any;
  location: any;
  userState: any;
}

interface IUnsubscribeScreenState {
  loading: boolean;
  userId: number;
  email: string;
  unsubscribed: boolean;
}

class UnsubscribeScreen extends React.Component<IUnsubscribeScreenProps, IUnsubscribeScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      userId: 0,
      email: "",
      unsubscribed: false
    };

    this.updateField = this.updateField.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);

  }

  componentDidMount(){
    let email = "";
    let userId = this.state.userId;
    if (window.URLSearchParams) {
      const query = new URLSearchParams(this.props.location.search);
      let emailQuery = query.get("email");
      if (emailQuery) {
        emailQuery = emailQuery.trim();
        //if the email has a space, it should be a +
        if (emailQuery.indexOf(" ") > -1) {
          emailQuery = emailQuery.replace(" ", "+");
        }
        email = emailQuery;
      }
    }
    // if the user is logged in, we grab their email
    console.log(this.props);
    if(this.props.userState && this.props.userState.user && this.props.userState.user.email){
      email = this.props.userState.user.email;
      userId = this.props.userState.user.id;
    }
    this.setState({loading: false, email, userId});
  }

  public render() {
    if(this.state.unsubscribed) {
      return (
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <Card title="Unsubscribe" loading={this.state.loading} help="">
              <p>You are unsubscribed.</p>
            </Card>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <Card title="Unsubscribe" loading={this.state.loading} help="">
            <p>We're sorry to see you go! Please verify your email address and click 'Unsubscribe' to no longer receive emails from Wagz.</p>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} style={styles.inputFieldAdj} />
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-block" style={styles.button} onClick={this.unsubscribe}>Unsubscribe</button>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private unsubscribe(){
    if(this.state.email === "" && this.state.userId === 0){
      return Alert.error("An email address is required");
    }
    this.setState({loading: true}, async ()=> {
      const data: any = {};
      if(this.state.email !== ""){
        data.email = this.state.email;
      }
      if(this.state.userId !== 0){
        data.userId = this.state.userId;
      }
      try{
        await UserAPI.unsubscribeEmail(data);
        this.setState({loading: false, unsubscribed: true});
      }catch(err){
        Alert.error("Could not unsubscribe. Please contact support.")
        this.setState({loading: false, unsubscribed: false});
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnsubscribeScreen) as any);