import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import * as Alert from "src/components/structure/Alert";
import { handlePhone, verifySetup, jwtCheck } from "src/utils/utilities";
import { UserAPI } from "src/API";
import { styles } from "src/styles";
import LoadingButton from "src/components/structure/LoadingButton";
import EmailPreferences from "./EmailPreferences";
import SecuritySettings from "./Security/SecuritySettings";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";

interface AccountScreenProps {
  location: any;
  userActions: any;
  userState: any;
  history: any;
}

interface AccountScreenState {
  firstName: string;
  lastName: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  status: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  loading: boolean;
  setupCompleted: boolean | undefined;
}

export class Account extends Component<AccountScreenProps, AccountScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      homePhone: "",
      mobilePhone: "",
      status: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      loading: false,
      setupCompleted: true,
    };

    this.getProfile = this.getProfile.bind(this);
    this.formatStatus = this.formatStatus.bind(this);
    this.updateField = this.updateField.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true }, async () => {
      try {
        const check = await verifySetup(this.props.userState.user.id);
        this.setState({ setupCompleted: check, loading: false });
        this.getProfile();
      } catch (error) {
        Alert.error("Could not load your profile.");
        this.setState({ loading: false });
      }
    });
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  public logout() {
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
  }

  /**
   * Called through ComponentDidMount, displaying the profile of the logged in user.
   */
  getProfile() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.getProfile(this.props.userState.user.id);
        this.setState({
          email: result.body.data.email,
          firstName: result.body.data.firstName,
          lastName: result.body.data.lastName,
          homePhone: result.body.data.homePhone.substr(2),
          mobilePhone: result.body.data.mobilePhone.substr(2),
          status: result.body.data.status,
        });
        const address = await UserAPI.getAddress(this.props.userState.user.id);
        var v: any;
        for (v of address.body.data) {
          if (v.addressType === "mailing") {
            this.setState({
              street1: v.street1,
              street2: v.street2,
              city: v.city,
              state: v.state,
              zip: v.zip,
            });
          }
        }
        this.setState({ loading: false });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  submitChanges() {
    if (
      this.state.street1 === "" ||
      this.state.city === "" ||
      this.state.state === "" ||
      this.state.zip === "" ||
      this.state.mobilePhone === ""
    ) {
      return Alert.error("You must provide valid values for all fields!");
    } else {
      this.setState({ loading: true }, async () => {
        const address = {
          street1: this.state.street1,
          street2: this.state.street2,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          addressType: "mailing",
        };
        const phone = {
          homePhone: this.state.homePhone,
          mobilePhone: this.state.mobilePhone,
        };
        try {
          await UserAPI.createAddress(this.props.userState.user.id, address);
          await UserAPI.updateProfile(this.props.userState.user.id, phone);
          Alert.success("Your information has been updated!");
          this.setState({ loading: false });
        } catch (error) {
          this.setState({ loading: false });
          return Alert.error("You must provide valid values for all fields!");
        }
      });
    }
  }

  render() {
    if (jwtCheck() && this.state.setupCompleted) {
      return (
        <div>
          {this.state.loading && (
            <div style={{ textAlign: "center", marginTop: 100 }}>
              <div className="spinner-border" role="status" />
            </div>
          )}
          {!this.state.loading && (
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <h5 className="header-5" style={{ marginTop: 20 }}>
                    Your Account Profile
                  </h5>
                  <div className="card-new">
                    <h6 className="header-6" style={{ marginTop: 2 }}>
                      Account Information
                    </h6>
                    <p className="main-text">
                      {this.state.firstName} {this.state.lastName}
                    </p>
                    <div className="row" style={{ marginLeft: 1 }}>
                      <span className="main-text">Status: </span>
                      <p
                        style={{
                          color: this.formatStatus(this.state.status),
                          marginLeft: 5, marginTop: -2
                        }}
                      >
                        {this.state.status.charAt(0).toUpperCase() +
                          this.state.status.slice(1)}
                      </p>
                    </div>
                    <div className="form-group" style={{ marginTop: 10 }}>
                      <h6 className="header-6">Contact Information</h6>
                      <div className="row" style={{ marginTop: 10 }}>
                        <div className="col-md-6">
                          <input
                            id="mobilePhone"
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            style={styles.inputFieldNew}
                            onChange={this.updateField}
                            value={handlePhone(this.state.mobilePhone)}
                            aria-label="Your mobile phone number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        id="street1"
                        type="text"
                        className="form-control"
                        placeholder="Street Address"
                        style={styles.inputFieldNew}
                        onChange={this.updateField}
                        value={this.state.street1}
                        aria-label="Line 1 of your address"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        id="street2"
                        type="text"
                        className="form-control"
                        placeholder="Street Address (cont.)"
                        style={styles.inputFieldNew}
                        onChange={this.updateField}
                        value={this.state.street2}
                        aria-label="An optional line 2 of your address"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            id="city"
                            type="text"
                            className="form-control"
                            style={styles.inputFieldNew}
                            onChange={this.updateField}
                            placeholder="City"
                            value={this.state.city}
                            aria-label="Your city"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control"
                            style={styles.inputFieldNew}
                            onChange={this.updateField}
                            id="state"
                            type="text"
                            maxLength={2}
                            autoCapitalize="yes"
                            placeholder="State"
                            value={this.state.state}
                            aria-label="Your state"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            id="zip"
                            type="text"
                            className="form-control"
                            style={styles.inputFieldNew}
                            placeholder="Zip Code"
                            onChange={this.updateField}
                            value={this.state.zip}
                            aria-label="Your zipcode"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <LoadingButton
                        aria-label="Begin the set up process"
                        loading={this.state.loading}
                        className="btn btn-block btn-primary"
                        style={styles.buttonNew}
                        onClick={this.submitChanges}
                      >
                        UPDATE CONTACT INFO
                      </LoadingButton>
                    </div>
                    <div>
                      <ChangeEmail
                        userId={this.props.userState.user.id}
                        email={this.state.email}
                        onUpdated={this.logout}
                      />
                    </div>
                    <div>
                      <ChangePassword
                        userId={this.props.userState.user.id}
                        onUpdated={this.logout}
                      />
                    </div>
                  <div>
                    <SecuritySettings userId={this.props.userState.user.id}/>
                  </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div>
                    <EmailPreferences userId={this.props.userState.user.id} />
                  </div>
                </div>
              </div>
          )}
        </div>
      );
    } else if (jwtCheck() && !this.state.setupCompleted) {
      return <Redirect to="/setup" />;
    } else {
      return <Redirect to="/login" />;
    }
  }

  formatStatus(status: string) {
    switch (status) {
      case "pending":
        return "#F3FC26";
      case "locked":
        return "#E91A14";
      case "active":
        return "#237C00";
      case "delete_requested":
        return "#e17034";
      default:
        return "";
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
