import { LogglyTracker } from "loggly-jslogger";

// the supported logLevels; we do not currently enforce these in the call
export const logLevels = {
  info: "info",
  warning: "warning",
  error: "error",
  fatal: "fatal",
}

// logs a chunk of data
export const log = (level: string, key: string, data: any) => {
  // right now we are trying to send to Loggly, but it doesn't seem
  // to be going through. Still, we will keep this here and replace the implementation
  // in here with a direct call to our server when that is built out
  const logger = new LogglyTracker();
  const logData = {
    ...data,
    key,
    level,
    source: "wagz-spa",
    "logglyKey": process.env.REACT_APP_LOGGLY_KEY,
    logType: "log",
  }
  logger.push(logData);
};

// holds all of the valid keys. We allow the app to override these in the call to log, but really we should
// try to use these
export const logKeys = {
  // login
  login_failed: "login_failed",
};