import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as UserActions from "../../../reducers/userReducer";
import { styles } from "../../../styles";

interface TermsScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface TermsScreenState {
  exit: boolean;
}

export class Terms extends Component<TermsScreenProps, TermsScreenState> {
  constructor(props: any) {
    super(props);
    this.state = {
      exit: false,
    };
    this.handleExit = this.handleExit.bind(this);
  }

  handleExit() {
    this.setState({ exit: true });
  }

  render() {
    if (!this.state.exit) {
      return (
        <div style={{ marginBottom: 50 }}>
          <Card title="WAGZ TERMS OF SERVICE">
            <div className="text">
              <div style={{ textAlign: "center" }}>
                <strong>WELCOME TO WAGZ!</strong>
              </div>
              <br />

              <p>
                Wagz, Inc. (“Wagz”, “we,” ”us,” “our”) is company focused on
                your dogs health, safety, and connection to YOU! Our policies
                are designed to create a safe and fair environment for all Wagz
                members (“Member(s),” “you,” “your(s)”). You can use the Site to
                educate yourself on your dog and further information about the
                Wagz product. Learning what is allowed can help you avoid
                unintentionally breaking the rules and helps everyone in
                encouraging reliable and trustworthy Members.
              </p>
              <p>
                PLEASE READ THESE TERMS OF SERVICE (“TERMS”), OUR PRIVACY
                POLICY, BUYING AND RETURNING POLICY AND ALL OTHER WAGZ POLICIES
                CAREFULLY. THESE ARE LEGALLY BINDING AGREEMENTS THAT CONTROL
                YOUR ACCESS TO AND USE OF THE WAGZ WEBSITE, MOBILE
                APPLICATION(S) AND RELATED CONTENT (EACH INDIVIDUALLY OR
                TOGETHER THE “SITE”) AND MAY AFFECT YOUR RIGHTS AND OBLIGATIONS.
              </p>
              <p>
                <em>
                  Warning: These Terms, the Privacy Policy and the Buying and
                  Selling Policy are legal contracts that affect your rights and
                  govern your use of the Wagz site, please read them carefully!
                </em>
              </p>
              <strong>YOUR Acceptance</strong>
              <p>
                By using the Site, you agree to be bound by these Terms and all
                other Wagz Policies governing the use of the Site. By using the
                Site you represent that you have read and understand these Terms
                of Service and other applicable polices and agree to be bound by
                the terms therein.
              </p>
              <strong>Headings/Translations</strong>
              <p>
                The paragraph headings and Translations contained in these Terms
                are for convenience of reference only and will not affect the
                meaning or interpretation hereof.
              </p>
              <p>
                <em>
                  Translation: The translations and headings have no effect on
                  the meaning of the Wagz Policies.
                </em>
              </p>
              <strong>Limited Licenses</strong>
              <p>
                You hereby grant to Wagz a non-exclusive, royalty-free and fully
                sublicensable right to use, reproduce, modify, adapt, publish,
                translate, create derivative works from, distribute, perform and
                display any text, images, Crowdscore entires, Favs and/or any
                other information that you post on or in connection with the
                Site (each, individually or together, the “Content”). You may
                cancel this license at any time by removing such Content from
                the Site. However, such cancellation will not apply to any such
                deleted Content that is already in use by Wagz in any
                promotional or marketing materials at the time of deletion.
              </p>
              <p>
                Subject to your compliance with these Terms, you are granted a
                limited, non-sublicensable, non-transferrable/assignable,
                revocable, license to access and use the Site. This license does
                not include; (i) any resale or commercial use of the Site and
                all content and other materials on our Site (excluding Content
                that you post), including, but not limited to, our logo and all
                designs, text, graphics, pictures, information, data, software
                and the selection and arrangement thereof (each individually or
                together the “Site Materials”) therein; (ii) the distribution,
                public performance or public display of any Site Materials;
                (iii) modifying or otherwise making any derivative uses of our
                Site and/or the Site Materials (or any portion thereof); (iv)
                use of any data mining, robots or similar data gathering or
                extraction methods; (v) downloading (other than the page
                caching) of any portion the Site or any information contained
                therein, except as expressly permitted by these Terms; or (vi)
                any use of the Site other than for its intended purpose.
              </p>
              <strong>ADDITIONAL TERMS</strong>
              <p>
                You and Wagz may enter into certain written agreement(s)
                concerning elements of the Site, the Wagz services, and/or your
                use thereof (the “Additional Terms”). Provided that any such
                agreement(s) is in writing, expressly refers to being additional
                terms to these Terms and is signed by both you and an authorized
                representative of Wagz, the terms and conditions of such an
                agreement will be incorporated into these Terms. If there is any
                contradiction between any Additional Terms and these Terms: (i)
                the Additional Terms shall control only in relation to the
                elements of the Site, the Wagz services and/or your use thereof
                that are expressly specified in any such Additional Terms; and
                (ii) these Terms shall control in relation to all other elements
                of the Site, the Wagz services and/or your use thereof.
              </p>
              <strong>Content Policy</strong>
              <p>
                You are free to post and delete your Content at any time. Upon
                such deletion, Wagz typically will remove your deleted Content
                from its servers within a commercially reasonable time. You may
                not post any Content that violates any applicable laws, rules,
                policies or regulations. You hereby agree not to post anything
                on our Prohibited Item list as may be updated from time to time,
                on the Site and/or any content that, in our sole judgment, is
                objectionable or which restricts or inhibits any other person
                from using or enjoying our Site.
              </p>
              <p>
                You agree to use our Site at your own risk and that you are
                solely responsible for your use of the Site and for the Content
                that you post. It is your responsibility to ensure that any
                Content that you post on the Site does not hurt or offend anyone
                or violate any applicable laws, rules, policies or regulations.
                In no case will Wagz be liable for without limitation any
                statements, representations, Wagz Member or third party content
                on the Site, or in any public forum. Although we have no
                obligation to screen, edit or monitor any Wagz Member or third
                party content posted on the Site, we reserve the right, and have
                absolute discretion, to remove, screen or edit any such content
                posted or altered on the Site at any time and for any reason
                without notice. You are solely responsible for creating backup
                copies of and replacing any Content you post on our Site.
              </p>
              <strong>Contact Information</strong>
              <p>
                If you have any questions about these Terms of Service or
                anything else, please contact Wagz, Inc. at:
              </p>
              <p>Attention: Wagz, Inc.</p>
              <p>Info@Wagz.com</p>
              <p>
                100 Market Street, Suite 401
                <br />
                Portsmouth, NH, 03801, USA
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="text" style={{ width: "25%" }}>
                <button
                  className="btn btn-block btn-primary"
                  style={styles.button}
                  onClick={this.handleExit}
                >
                  Exit
                </button>
              </div>
            </div>
          </Card>
        </div>
      );
    }
    return <Redirect to="/" />;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
