import { makeCall } from './client';

export class BillingAPI {

  public changePaymentMethod(userId: number, token: string, otherData: any = {}){
    const data = {
      ...otherData,
      token
    };
    return makeCall('put', `users/${userId}/billing`, data);
  }
  public getBillingUser(userId: number, otherData: any = {}){
    const data = {
      ...otherData
    };
    return makeCall('get', `users/${userId}/billing`, data);
  }

  public getPaymentHistory(userId: number, otherData: any = {}){
    const data = {
      ...otherData,
    };
    return makeCall('get', `users/${userId}/billing`, data);
  }

  public getPaymentMethod(userId: number, otherData: any = {}){
    const data = {
      ...otherData,
    };
    return makeCall('get', `users/${userId}/billing`, data);
  }

  public getPlans(){
    return makeCall('get', 'plans', {deviceType: "collar"});
  }

  public getUserCoupons(userId: number, otherData: any = {}){
    const data = {
      ...otherData,
    };
    return makeCall('get', `users/${userId}/coupons`, data);
  }

  public validateCoupon(couponCode: string, planId: number, otherData: any = {}) {
    const data = {
      ...otherData,
      couponCode,
      planId,
    };
    return makeCall("post", `coupons/validate`, data);
  }

  public getUserTransactions(userId: number, otherData: any = {}){
    const data = {
      ...otherData
    };
    return makeCall('get', `users/${userId}/subscriptions`, data);
  }
  
}