import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Modal } from "react-bootstrap";

import * as AppActions from "../../../../reducers/appReducer";
import * as UserActions from "../../../../reducers/userReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as Alert from "src/components/structure/Alert";
import { UserAPI } from "src/API";
import { styles } from "src/styles";

interface IEmailOTPProps {
  userId: number;
  userState: any;
  location: any;
  history: any;
}

interface IEmailOTPState {
  loading: boolean;
  enabled: boolean;
  verified: boolean;
  emailAddress: string;
  showDeleteModal: boolean;
}

export class EmailOTP extends Component<IEmailOTPProps, IEmailOTPState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      enabled: this.props.location.state.enabled,
      verified: this.props.location.state.verified,
      emailAddress: this.props.location.state.emailAddress,
      showDeleteModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.continue = this.continue.bind(this);
    this.delete = this.delete.bind(this);
    this.cancel = this.cancel.bind(this);
    this.submitDeletion = this.submitDeletion.bind(this);
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-4 col-sm-12"></div>
        <div className="col-lg-4 col-sm-12">
          <div className="card-new-no-border">
            <div className="row" style={{ marginTop: 21 }}>
              <div className="col-1">
                <Link to="/account" id="nav-account" className="nav-link-new">
                  <FontAwesomeIcon icon={faArrowLeft} className="text" />
                </Link>
              </div>
              <div className="col-9">
                <label className="codes">Email verification</label>
              </div>
            </div>
            {!this.state.enabled && (
              <div>
                <Container>
                  <div>
                    <p className="otp-sub">
                      Confirm the email address you’d like to use to verify your
                      account. You will receive a verification code at this
                      email address.
                    </p>
                  </div>
                  <Row>
                    <div style={{ width: "100%" }}>
                      <input
                        id="emailAddress"
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        style={styles.inputFieldDisabled}
                        disabled
                        value={this.state.emailAddress}
                        aria-label="Email address"
                      />
                    </div>
                  </Row>
                <div className="row" style={{ marginTop: 10 }}>
                  <div style={{ width: "45%" }}>
                      <button
                        style={styles.buttonNewSecondary}
                        aria-label="Cancel Deletion"
                        className="btn btn-block btn-secondary"
                        onClick={this.cancel}
                      >
                        CANCEL
                      </button>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ width: "45%" }}>
                      <button
                        style={styles.buttonNew}
                        aria-label="Process Deletion"
                        className="btn btn-block btn-primary"
                        onClick={this.continue}
                      >
                        CONTINUE
                      </button>
                  </div>
                </div>
                </Container>
              </div>
            )}
            {this.state.enabled && (
              <div>
                <div>
                  <p className="otp-sub">
                    This email address is used to verify your account. You will
                    receive a verification code to this email address.
                  </p>
                </div>
                <div className="row">
                  <div className="col-6">
                    <strong>{this.state.emailAddress}</strong>
                  </div>
                  <div className="col-1 offset-2">
                    <Link to="/account/security/email" onClick={this.delete}>
                      REMOVE
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-4 col-sm-12"></div>

        <Modal
          show={this.state.showDeleteModal}
          onHide={() => {
            this.setState({ showDeleteModal: false });
          }}
          onEntered={(e: any) => {
            window.location.hash = "warning-modal";
            window.onhashchange = () => {
              if (!window.location.hash) {
                this.setState({ showDeleteModal: false });
              }
            };
          }}
        >
          <Modal.Header>
            <Modal.Title>Remove email address?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Container>
                <div style={{ width: "100%" }}>
                  <strong>{this.state.emailAddress}</strong>
                  <p className="otp-modal-sub">
                    Are you sure you want to remove this email address as a
                    verification option?
                  </p>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNewSecondary}
                      aria-label="Cancel Deletion"
                      className="btn btn-block btn-secondary"
                      onClick={this.cancel}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNew}
                      aria-label="Process Deletion"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState({ showDeleteModal: false }, () => {
                          this.submitDeletion();
                        });
                      }}
                    >
                      REMOVE
                    </button>
                  </div>
                </div>
              </Container>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }

  submitDeletion() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.removeOTP(this.props.userState.user.id, "email");
        this.setState({ enabled: false, verified: false, loading: false });
        Alert.success("Email authentication removed from your account.");
      } catch (err) {
        Alert.error("Could not remove that method.");
        this.setState({ loading: false });
      }
    });
  }

  private continue() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.startOTPEnrollment(this.props.userState.user.id, {
          method: "email",
        });
        this.setState({ enabled: true, verified: true, loading: false });
        Alert.success("Email authentication added to your account.");
      } catch (err) {
        Alert.error(
          "Error adding that method. Please try again or contact support."
        );
        this.setState({ loading: false });
      }
    });
  }
  private delete() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  private cancel() {
    this.props.history.goBack();
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailOTP);
