import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "url-search-params-polyfill";
import * as EmailValidator from "email-validator";
import { Container, Row, Col, Modal } from "react-bootstrap";

import * as Alert from "../../structure/Alert";
import * as UserActions from "../../../reducers/userReducer";

import { AnalyticsAPI, UserAPI } from "../../../API";
import { bindActionCreators } from "redux";
import { styles } from "../../../styles";

import { log, logLevels, logKeys } from "src/utils/logger";
import { logDeviceInfo, verifySetup } from "src/utils/utilities";

interface ILoginProps {
  location: any;
  userActions: any;
  userState: any;
  history: any;
  header?: any;
}

interface ILoginState {
  email: string;
  password: string;
  inputType: string;
  showPassword: boolean;
  loading: boolean;
  nextPath: string;
  needsOTP: boolean;
  method: string;
  code: string;
  title: string;
  header: string;
  showMultipleChoiceModal: boolean;
}

// so, there are three flows here:
// 1) Not logged in -> show the form, execute the login
// 2) Is logged in, no devices -> go to /setup
// 3) Is logged in, has devices -> go to /devices

class Login extends Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      inputType: "password",
      showPassword: false,
      loading: false,
      nextPath: "",
      needsOTP: false,
      method: "",
      code: "",
      title: "",
      header: "",
      showMultipleChoiceModal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handlePasswordToggle = this.handlePasswordToggle.bind(this);
    this.checkUserSetup = this.checkUserSetup.bind(this);
    this.getHeader = this.getHeader.bind(this);
  }

  componentDidMount() {
    if (this.props.userState.loggedIn) {
      const nextPath = this.checkForPath();
      this.setState({ nextPath });
      this.checkUserSetup(); // update
    }
  }

  handleChange(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any) {
    if (e.keyCode === 13) {
      this.handleLogin();
    }
  }

  private handlePasswordToggle() {
    this.setState({
      showPassword: !this.state.showPassword,
      inputType: this.state.inputType === "text" ? "password" : "text",
    });
  }

  private handleLogin() {
    //check validation
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    if (!EmailValidator.validate(email)) {
      return Alert.error(
        "Invalid email address.  Please enter your email address in this format: username@example.com"
      );
    }
    if (email === "" || password === "") {
      return Alert.error("The email and password fields are required");
    }

    this.setState({ loading: true }, async () => {
      // first, try to login and catch
      let user: any = {};
      try {
        let result = await UserAPI.login(email, password, {
          otpMethod: this.state.method,
          otpToken: this.state.code,
        });

        if (result.body.data.otpRequired) {
          if (result.body.data.otpMethod === "multiple") {
            this.setState({
              loading: false,
              showMultipleChoiceModal: true,
            });
          } else if (result.body.data.otpMethod === "authenticator") {
            this.setState({
              loading: false,
              method: "authenticator",
              needsOTP: true,
              title: "Open your authentication app",
              header:
                "Copy the verification code from your authentication app and enter it below.",
            });
          } else if (result.body.data.otpMethod === "email") {
            this.setState({
              loading: false,
              method: result.body.data.otpMethod,
              needsOTP: true,
              title: "Check your email",
              header:
                "We sent a verification code to the email address you added as a security option.",
            });
          }
        } else {
          user = result.body.data;
          window.localStorage.jwt = user.jwt;
          window.localStorage.user = JSON.stringify(user);
          document.cookie = "jwt=" + user.jwt;
        }
      } catch (error) {
        Alert.error("Your email and/or password are not valid");
        this.setState({ loading: false });
        log(logLevels.error, logKeys.login_failed, {
          error: error,
          email: email,
        });
      }

      // if they logged in, send the analytics in the background
      if (user.id && user.id !== 0) {
        // try an analytic
        try {
          await AnalyticsAPI.logEvent("user_login_web", {
            userId: user.id,
            params: {
              source: "setup.wagz.com",
            },
          });
          logDeviceInfo(user.id);
        } catch (err) {}
        const nextPath = this.checkForPath();
        this.props.userActions.loginUser({ loggedIn: true, user });
        this.setState({ nextPath, loading: false }, () => {
          if (nextPath === "") {
            this.checkUserSetup();
          }
        });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: "center", marginTop: 100 }}>
          <div className="spinner-border" role="status" />
        </div>
      );
    }

    if (this.state.nextPath !== "") {
      return <Redirect to={this.state.nextPath} />;
    }

    return (
      <div>
        <div className="row">
          <div style={styles.itemAlign}>
            <div style={{ marginTop: 20, width: 380 }}>
              <p style={styles.signupHeader}>{this.getHeader()}</p>
              <div className="card-new">
                <p style={styles.newToWagz}>Already a customer? Sign In.</p>
                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    aria-label="Email Address"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyUp={this.checkEnter}
                  />
                </div>

                <div className="form-group">
                  <input
                    id="password"
                    type={this.state.inputType}
                    placeholder="Password"
                    aria-label="Password"
                    aria-required={true}
                    style={styles.inputFieldNew}
                    className="form-control"
                    value={this.state.password}
                    onChange={this.handleChange}
                    onKeyUp={this.checkEnter}
                  />
                </div>
                <div className="row" style={{ marginTop: 16 }}>
                  <div>
                    <input
                      id="showPassword"
                      type="checkbox"
                      aria-label="Show Password"
                      checked={this.state.showPassword}
                      onChange={this.handlePasswordToggle}
                    />
                  </div>
                  <div style={{ marginTop: 1 }}>
                    <label className="show-password" htmlFor="showPassword">
                      {" "}
                      Show password{" "}
                    </label>
                  </div>
                </div>

                <div style={{ marginTop: 30 }}>
                  <button
                    style={styles.buttonNew}
                    aria-label="Log in to the app"
                    className="btn btn-block btn-primary"
                    onClick={this.handleLogin}
                  >
                    SIGN IN
                  </button>
                </div>
                <div style={{ marginTop: 10 }}>
                  <div style={styles.centerText}>
                    <Link
                      to="/forgot"
                      id="nav-login"
                      className="nav-link-new"
                      style={styles.forgot}
                    >
                      FORGOT PASSWORD
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.linkAlign}>
          <p className="nav-item">
            <Link to="/signup" id="nav-login" className="nav-link-new">
              New to Wagz? Create an account
            </Link>
          </p>
        </div>

        <Modal
          show={this.state.needsOTP}
          onHide={() => {
            this.setState({ needsOTP: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Container>
                <p className="otp-modal-sub">{this.state.header}</p>
                <Row>
                  <div style={{ width: "100%" }}>
                    <input
                      id="code"
                      type="text"
                      className="form-control"
                      aria-label="The code from the auth app"
                      aria-labelledby="codeLabel"
                      placeholder="Enter Code"
                      style={styles.inputFieldNew}
                      value={this.state.code}
                      onChange={this.handleChange}
                    />
                  </div>
                </Row>
                <div className="row" style={{ marginTop: 10 }}>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNewSecondary}
                      aria-label="Cancel Deletion"
                      className="btn btn-block btn-secondary"
                      onClick={() => {
                        this.setState({ needsOTP: false });
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div style={{ width: "45%" }}>
                    <button
                      style={styles.buttonNew}
                      aria-label="Process Deletion"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState({ needsOTP: false }, () => {
                          this.handleLogin();
                        });
                      }}
                    >
                      VERIFY & SIGN IN
                    </button>
                  </div>
                </div>
              </Container>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showMultipleChoiceModal}
          onHide={() => {
            this.setState({ showMultipleChoiceModal: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Verification Method</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Container>
                <Row style={{ marginTop: 10, marginBottom: 20 }}>
                  <Col>
                    <button
                      style={styles.buttonNew}
                      aria-label="Choose Auth Method"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState(
                          {
                            showMultipleChoiceModal: false,
                            method: "authenticator",
                          },
                          () => {
                            this.handleLogin();
                          }
                        );
                      }}
                    >
                      AUTHENTICATOR
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button
                      style={styles.buttonNew}
                      aria-label="Choose Email Method"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        this.setState(
                          { showMultipleChoiceModal: false, method: "email" },
                          () => {
                            this.handleLogin();
                          }
                        );
                      }}
                    >
                      EMAIL
                    </button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    );
  }

  private checkUserSetup() {
    this.setState({ loading: true }, async () => {
      let nextPath = "";
      try {
        const check = await verifySetup(this.props.userState.user.id);
        if (check) {
          nextPath = "/devices";
        } else {
          nextPath = "/setup";
        }
      } catch (error) {
        nextPath = "/setup";
      }
      this.setState({
        nextPath,
        loading: false,
      });
    });
  }

  getHeader() {
    if (this.props.location.state !== undefined) {
      return "Verified! Please sign in to get started.";
    } else {
      return "Welcome to Wagz!";
    }
  }

  private checkForPath(): string {
    let nextPath = "";
    if (window.URLSearchParams) {
      let params = new URLSearchParams(window.location.search);
      const path = params.get("path");
      if (path && path !== "") {
        nextPath = path;
      }
    }
    return nextPath;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login) as any
);
