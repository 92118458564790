import { AnalyticsAPI, BillingAPI, DevicesAPI } from "../API/index";
import { browserName, browserVersion, osName, osVersion, getUA, isDesktop, isTablet, isMobile } from "react-device-detect";
import { IAnalyticDevice } from "src/API/analytics";
import moment from "moment";

export const handlePhone = (value: string) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return value.replace(phoneRegex, "($1) $2-$3");
};

export const getDeviceName = (p: string) => {
  const parsedId = p.substr(4, 2);
  switch (parsedId) {
    case "PZ":
      return "Petzi TreatCam";
    case "01":
      return "BD Smart Dog Collar";
    case "11":
      return "Wagz V1 Collar";
    case "13":
      return "Wagz Go Smart Door";
    case "14":
      return "Wagz Serve Smart Feeder";
    case "17":
      return "Wagz Pendant";
    case "18":
      return "Wagz Shield";
    case "19":
      return "Wagz Explore Collar";
    case "23":
      return "Wagz Freedom Collar";
    case "24":
      return "Smart Pet Collar Pre-Launch";
    default:
      return "";
  }
};

export function capitalizeFirstLetter(word: string) {
  if(!word || word === ""){
    return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

// verifySetup is used to redirect users back to the setup flow if not yet completed
export const verifySetup = async (userId: number) => {
  try {
    let billing = await BillingAPI.getBillingUser(userId);
    if (billing.body.data.chargifyCustomerId === 0) {
      return false;
    }
    return true;
  } catch (error) {
    let devices = await DevicesAPI.getDevices(
    userId,
    { deviceType: "healthBox", status: "all" }
  );
  if (devices.body.data.length >0) {
    return true;
  } else {
    return false;
  }
  }
};

// jwtCheck is used to verify if the user is logged in, thus is allowed to see the page they're trying to access
export const jwtCheck = () => {
  return window.localStorage.jwt && window.localStorage.jwt !== "";
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
 export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "windows";
  }

  if (/android/i.test(userAgent)) {
      return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
  }

  return "unknown";
}


export function getLink(input: string) {
  return (input === "iOS" ? "https://apps.apple.com/us/app/wagz/id1372859800" : "https://play.google.com/store/apps/details?id=com.getwagz.wagz&hl=en_US&gl=US");
}

export const logDeviceInfo = async (userId: number) => {
  if(userId !== 0){
    // see if it's been sent recently
    const lastSent = window.localStorage.getItem("deviceInfoLastSent");
    if(lastSent && lastSent !== ""){
      if(moment(lastSent).isAfter(moment().subtract(1, "day"))){
        return;
      }
    }
    try{
      const ua = getUA;
      const height = window && window.outerHeight ? window.outerHeight : 0;
      const width = window && window.outerWidth ? window.outerWidth : 0;
      let deviceInfo: IAnalyticDevice = {
        browser: browserName.toLowerCase(),
        browserVersion: "v"+ browserVersion,
        os: osToEnum(osName),
        osVersion: "v"+ osVersion,
        screenHeight: height,
        screenWidth: width,
        params: {
          source: "setup.wagz.com",
          ua,
        }
      };
      if(isDesktop){
        deviceInfo.deviceType = "pc";
      } else if(isTablet){
        deviceInfo.deviceType = "tablet";
      } else if(isMobile){
        deviceInfo.deviceType = "phone";
      } else {
        deviceInfo.deviceType = "unknown";
      }
      await AnalyticsAPI.logDevice(userId, deviceInfo);
      window.localStorage.setItem("deviceInfoLastSent", moment().format("YYYY-MM-DD HH:mm:ss"))
    }catch(err){
    }
    
  }
}

export const osToEnum = (input: string): string => {
  switch(input.toLowerCase()){
    case "linux":
      return "linux";
    case "android":
      return "android";
    case "ios":
      return "ios";
    case "mac os":
    case "mac":
    case "macos":
    case "osx":
      return "macos";
    case "windows":
      return "windows";
    case "other":
      return "other";
    default:
      return "unknown";
  }
}
// enum('android','ios','windows','macos','linux','chromeos','fire','unknown','other')

