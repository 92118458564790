/// <reference path="../types.d.ts" />
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./css/app.css";


import "jquery/dist/jquery.slim";
import "bootstrap/dist/js/bootstrap.bundle";

ReactDOM.render(<App />, document.getElementById("root"));
