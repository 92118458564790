import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { UserAPI } from "../../../API";
import * as Alert from "../../structure/Alert";

// import Card from "../../structure/Card";
import { styles } from "../../../styles";

interface IVerifyScreenProps {
  location: any;
}

interface IVerifyScreenState {
  email: string;
  token: string;
  step: number;
  loading: boolean;
  agreement: boolean;
}

export class VerifyScreen extends Component<
  IVerifyScreenProps,
  IVerifyScreenState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      token: "",
      step: 1,
      loading: false,
      agreement: false,
    };

    this.updateField = this.updateField.bind(this);
    this.verify = this.verify.bind(this);
    this.handleAgreement = this.handleAgreement.bind(this);
  }

  /**
   * Sends user to the verify screen upon mounting based on the URL matching certain parameters.
   */
  componentDidMount() {
    if (window.URLSearchParams) {
      const query = new URLSearchParams(this.props.location.search);
      let email = query.get("email");
      let token = query.get("token");
      let st: IVerifyScreenState = this.state;
      if (email) {
        email = email.trim();
        if (email.indexOf(" ") > -1) {
          email = email.replace(" ", "+");
        }
        st.email = email ? email : "";
      }
      if (token) {
        token = token.trim();
        st.token = token ? token : "";
      }
      this.setState({
        email: email ? email : "",
        token: token ? token : "",
      });
    }
  }

  private handleAgreement() {
    this.setState({ agreement: !this.state.agreement });
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  /**
   * Called upon user clicking the "Verify" button.  Contacts UserAPI to confirm email and token match what is expected.
   */
  async verify() {
    if (this.state.email === "" || this.state.token === "") {
      return Alert.error("You must provide an email and a verification code!");
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.verifyToken(this.state.email, this.state.token, {});
        Alert.success(
          "We verified that email! Please login with your email and password!"
        );
        return this.setState({ step: 2 });
      } catch (err) {
        return Alert.error("That verification code was not valid!");
      }
    });
  }

  get step() {
    if (this.state.step === 1) {
      return (
        <div>
          <div className="card-new" style={{ marginBottom: 20 }}>
            <p style={styles.newToWagz}>
              Confirm your email and verification code
            </p>
            <div className="form-group">
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="Email"
                aria-label="Email Address"
                style={styles.inputFieldNew}
                autoComplete="off"
                onChange={this.updateField}
                value={this.state.email}
              />
            </div>
            <div className="form-group">
              <input
                id="token"
                type="text"
                className="form-control"
                placeholder="Verification Code"
                aria-label="Verification Code"
                style={styles.inputFieldNew}
                autoComplete="off"
                onChange={this.updateField}
                value={this.state.token}
              />
            </div>
            <div className="row" style={{ marginTop: 16 }}>
              <div>
                <input
                  id="agree"
                  type="checkbox"
                  aria-label="I have read and agree to the EULA"
                  checked={this.state.agreement}
                  onChange={this.handleAgreement}
                />
              </div>
              <div style={{ marginTop: 1 }}>
                <label htmlFor="agree">
                  I have read and agree to the{" "}
                  <a
                    style={{ color: "#0e9ed7", textDecoration: "underline" }}
                    href="http://www.wagz.com/pages/end-user-license-agreement"
                    target="_new"
                  >
                    {" "}
                    EULA
                  </a>
                </label>
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <button
                style={styles.buttonNew}
                className="btn btn-block btn-primary"
                onClick={this.verify}
                disabled={!this.state.agreement}
                aria-label="Verify Your Account"
                aria-disabled={!this.state.agreement}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step === 2) {
      return <Redirect to={{
        pathname: "/login",
        state: { header: "verified" }
      }} />;
    }
    return null;
  }

  render() {
    return (
      <div className="row">
        <div style={styles.itemAlign}>
          <div style={{ marginTop: 20, width: 380, marginBottom: 220 }}>
            <p style={styles.signupHeader}>Account confirmation</p>
            {this.step}
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyScreen;
